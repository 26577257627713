import { TableCell, TableRow, Typography } from '@material-ui/core';
import { memo } from 'react';

interface IParams {
  colSpan: number;
}

const EmptyTable = ({ colSpan }: IParams) => (
  <TableRow
    hover
  >
    <TableCell
      colSpan={colSpan}
      align="center"
    >
      <Typography
        color="textPrimary"
        variant="subtitle2"
      >
        No data
      </Typography>
    </TableCell>
  </TableRow>
);

export default memo(EmptyTable);
