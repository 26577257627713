import React, { ChangeEvent } from 'react';
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';

import { insertIcon } from '../insertIcon';
import { formatterLabel } from '../../FormFieldUtils';
import MaxLengthNotification from './MaxLengthNotification';
import { IControllerName, IInternalFieldProps } from '../formFieldInterface';

export const MaskTextFieldVariant = ({
  label,
  errors,
  maxLength,
  isRequired,
  valueField,
  lengthField,
  placeholder,
  messageFromExtraError,

  fieldMask,
  isDisabled,

  endAdornment,
  startAdornment,
  customInputProps,
  classNameForField,

  extraProps,

  eventBlurField,
  eventInputField,
  eventFocusField,
  eventChangeField
}:IInternalFieldProps) => {
  if (fieldMask) {
    if (extraProps) {
      return (
         <Controller
            name={extraProps.name as IControllerName}
            control={extraProps.control}
            defaultValue=""
            render={({ field }) => (
               <InputMask
                  disabled={isDisabled}
                  mask={fieldMask}
                  ref={field.ref}
                  value={field.value}
                  placeholder={placeholder}
                  onChange={(e) => {
                    field.onChange(e);
                    eventChangeField(e as ChangeEvent<HTMLInputElement>);
                  }}
                  onBlur={(value) => {
                    field.onBlur();
                    eventBlurField(value.target.value);
                  }}
                  onFocus={(e) => {
                    eventFocusField(e as React.FocusEvent<HTMLInputElement>);
                  }}
                  onInput={eventInputField}
               >
                  {() => (
                     <TextField
                        disabled={isDisabled}
                        className={classNameForField}
                        error={(extraProps && !!errors[extraProps.name]) || (!!messageFromExtraError)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={formatterLabel({ label, isRequired })}
                        fullWidth
                        helperText={extraProps && errors[extraProps.name] && errors[extraProps.name].message}
                        InputProps={{
                          startAdornment: insertIcon(startAdornment),
                          endAdornment: insertIcon(
                             <>
                                {endAdornment}
                                {maxLength && (
                                <MaxLengthNotification
                                   maxLength={maxLength}
                                   currentLength={field.value?.length || valueField?.length}
                                />
                                )}
                             </>
                          ),
                          inputProps: {
                            ...(!maxLength && (customInputProps || {})) || { maxLength },
                            'data-cy': extraProps.name
                          },
                        }}
                     />
                  )}
               </InputMask>
            )}
         />
      );
    }
    return (
       <InputMask
          required
          mask={fieldMask}
          placeholder={placeholder}
          onChange={(e) => {
            eventChangeField(e as ChangeEvent<HTMLInputElement>);
          }}
          onBlur={(value) => {
            eventBlurField(value.target.value);
          }}
          onFocus={(e) => {
            eventFocusField(e as React.FocusEvent<HTMLInputElement>);
          }}
          onInput={eventInputField}
       >
          {() => (
             <TextField
                value={valueField}
                error={!!messageFromExtraError}
                InputLabelProps={{
                  shrink: true,
                }}
                label={formatterLabel({ label, isRequired })}
                fullWidth
                helperText={messageFromExtraError}
                InputProps={{
                  startAdornment: insertIcon(startAdornment),
                  endAdornment: insertIcon(
                     <>
                        {endAdornment}
                        {maxLength && (
                        <MaxLengthNotification
                           maxLength={maxLength}
                           currentLength={lengthField}
                        />
                        )}
                     </>
                  ),
                  inputProps: (!maxLength && (customInputProps || {})) || { maxLength },
                }}
             />
          )}
       </InputMask>
    );
  }
  return null;
};
