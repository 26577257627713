import { makeStyles } from '@material-ui/core';
// import { mixin } from '../../utils/mixin';

export const useStyles = makeStyles((theme) => ({
  wrapper_editor: {
    position: 'relative',
    marginBottom: '2rem'
  },
  editor: {
    marginBottom: '.3rem',
    '& .ql-container .ql-editor': {
      minHeight: 400
    },
    '& .ql-container.ql-snow': {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderColor: '#E6E8F0'
    },
    '& .ql-toolbar.ql-snow': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      borderColor: '#E6E8F0'
    },
  },
  fieldCommentTop: {
    marginBottom: '.8rem !important'
  },
  fieldCommentBottom: {
    marginTop: '.8rem !important'
  },
  error_text: {},
  error: {
    '& .ql-container.ql-snow, & .ql-toolbar.ql-snow': {
      borderColor: theme.palette.error.main,
      borderWidth: 1
    }
  }
}));
