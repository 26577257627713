import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

export enum ContactOptions {
  EMAIL = 'email',
  URL = 'url'
}

interface IProps {
  value: ContactOptions,
  setValue: any
}

const ContactOptionsSelector = ({ value, setValue }: IProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <RadioGroup
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={value}
      onChange={handleChange}
    >
      <FormControlLabel
        value={ContactOptions.EMAIL}
        control={<Radio />}
        label="Option 1: Email Addresses "
      />
      <FormControlLabel
        value={ContactOptions.URL}
        control={<Radio />}
        label="Option 2: Contact Form URL"
      />
    </RadioGroup>
  );
};
export default ContactOptionsSelector;
