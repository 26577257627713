import React from 'react';
import { useStyles } from '../styles';
import { ICloseModalProps, IModalWindowStylesProps } from '../modalWindowInterface';

interface IModalWindowOverlayProps extends ICloseModalProps, IModalWindowStylesProps {}
const ModalWindowOverlay = ({ closeModal, styleProps }:IModalWindowOverlayProps) => {
  const classes = useStyles();
  return (
     <div
        className={classes.overlay}
        onClick={closeModal}
        role="presentation"
        style={{
          ...(styleProps?.overlay) && { ...styleProps.overlay }
        }}
     />
  );
};

export default ModalWindowOverlay;
