import { makeStyles } from '@material-ui/core';
// import { mixin } from '../../utils/mixin';

// '@media(max-width: 768px)': {}
export const useStyles = makeStyles(() => ({
  fon: {
    background: '#4A4A4A',
    borderRadius: 2,
    maxWidth: '17rem',
    fontSize: '.8rem',
    padding: '.5rem',
  },
  arrow: { color: '#4A4A4A' },
}));
