import React, { memo } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import clsx from 'clsx';
import { Search as SearchIcon } from '../../Icons/search';
import { useStyles } from '../styles';

interface ITableSearchProps {
  change?: (v: string) => void,
  className?: string,
  placeholder?: string | null,
}
export const TableSearch = memo(({ change, className, placeholder }:ITableSearchProps) => {
  const classes = useStyles();
  return (
     <div className={clsx(classes.table_search, className)}>
        <TextField
           fullWidth
           onChange={(a) => {
             if (change) {
               change(a.target.value);
             }
           }}
           InputProps={{
             startAdornment: (
                <InputAdornment position="start">
                   <SearchIcon fontSize="small" />
                </InputAdornment>
             )
           }}
           placeholder={placeholder as string || ''}
        />
     </div>
  );
});
