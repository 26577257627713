import * as React from 'react';

const LogoBevNetNosh = ({ className }: { className?: string }) => (
  <img
    src="https://d2btcxja5g5zgs.cloudfront.net/core/BN-NOSH_V1-Black.svg"
    width={210}
    alt="bevnet-nosh-logo"
    className={className}
  />
);

export default LogoBevNetNosh;
