import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Btn,
  FormField,
  useHookForm,
  useIsProvince,
  GlobalInterfaces,
  CountryAutocomplete,
  UsaStatesAutocomplete,
  useCustomGoogleAutocomplete,
  CardWrapper
} from '@monorepo/common';

import {
  useGetPersonalInfoQuery,
  useUpdatePersonalInfoMutation,
} from '../../services/userProfile/personal';
import { IUserProfileData } from '../../features/sliceUserProfile/UserProfileInitialState';
import { useActionAfterUpdatedData } from '../../hook/useActionAfterUpdatedData';
import { fillTemporaryUserProfile } from '../../features/sliceUserProfile';
import { customButtonStyles } from '../../constants/customButtonStyles';
import { useCheckEmailMutation } from '../../services/userProfile/email';
import { statusEmail } from '../../features/sliceUserEmail';
import { useAppSelector } from '../../store/hooks';
import { book } from '../../routers/books';
import { schema } from './schema';
import { useStyles } from './styles';

// interface UserUpdateContactInfoProps {}

const Update = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const { dataWasUpdated } = useActionAfterUpdatedData();

  const { data: userData, isLoading: isLoadingData } = useGetPersonalInfoQuery('');
  const [
    updatePersonalInfo,
    { isLoading: isSendingData, isSuccess: userDataWasSaved },
  ] = useUpdatePersonalInfoMutation();
  const [checkEmail, { isSuccess: resultCheckedEmail }] = useCheckEmailMutation();

  const { isEmailExist, temporaryUserData } = useAppSelector(
    ({ checkEmail: checkEmailStatus, userProfile }) => ({
      isEmailExist: checkEmailStatus.isEmailExist,
      temporaryUserData: userProfile.temporaryUserData,
    })
  );

  const {
    reset,
    errors,
    register,
    getValues,
    setValue,
    handleSubmit,
  } = useHookForm<IUserProfileData>({
    schema,
    data: userData,
  });
  const { getData, isProvince } = useIsProvince({
    extraOptions: {
      setValue,
      stateFieldName: 'state',
      provinceFieldName: 'province'
    }
  });

  const { changeInput, renderedList, clearSuggestions } = useCustomGoogleAutocomplete({
    country: countryCode || '',
    mode: 'custom',
    setValue,
    autoFillFields: {
      state: 'state',
      province: 'province',
      address1: 'address1',
      city: 'city',
      zip: 'zip',
    },
  });

  // UPDATE DATA
  const onSubmit = (data: IUserProfileData) => {
    if (userData) {
      // if email was not changed
      if (data.email && data.email === userData.email) {
        updatePersonalInfo({
          // @ts-ignore
          uguid: userData.uguid,
          ...data,
        });
        // if email was changed
      } else if (data.email && data.email !== userData.email) {
        checkEmail({
          email: data.email,
        });
        dispatch(fillTemporaryUserProfile(data));
      }
    }
  };

  // if choose country
  const changeCountries = useCallback(
    (val: GlobalInterfaces.IAutocompleteOption | null) => {
      clearSuggestions();
      getData({
        selectOption: val,
      });
      setCountryCode(val?.alpha2 || null);
    },
    [getData]
  );

  // update form data
  useEffect(() => {
    reset({ ...userData });

    if (userData && userData.country) {
      setCountryCode(userData.country);
      getData({
        countryName: userData.country,
      });
    }
  }, [userData]);

  // if email was changed,page will be redirected to page "Confirm email"
  useEffect(() => {
    if (temporaryUserData && temporaryUserData.uguid && isEmailExist) {
      history.push(book.confirmEmail);
    }
  }, [isEmailExist, temporaryUserData]);

  // will show notification and event PageUp if data was saved
  useEffect(() => {
    if (userDataWasSaved) {
      dataWasUpdated({
        text: 'Contact Info Updated',
      });
    }
  }, [userDataWasSaved]);

  // check email
  useEffect(() => {
    if (resultCheckedEmail) {
      dispatch(statusEmail(true));
      const dateId = setTimeout(() => {
        dispatch(statusEmail(false));
        clearTimeout(dateId);
      }, 1000);
    }
  }, [resultCheckedEmail]);

  return (
    <CardWrapper
      isLoading={isLoadingData || isSendingData}
      titleHelmet="Update Contact Info"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          extraProps={{ ...register('email') }}
          errors={errors}
          label="Email *"
        />
        <FormField
          extraProps={{ ...register('firstName') }}
          errors={errors}
          label="First Name *"
        />
        <FormField
          extraProps={{ ...register('lastName') }}
          errors={errors}
          label="Last Name *"
        />
        <FormField
          extraProps={{ ...register('company') }}
          errors={errors}
          label="Company *"
        />
        <FormField
          extraProps={{ ...register('title') }}
          errors={errors}
          label="Job Title *"
        />
        <FormField
          extraProps={{ ...register('phone') }}
          errors={errors}
          label="Phone *"
        />
        <FormField
          extraProps={{ ...register('mobilePhone') }}
          errors={errors}
          label="Mobile Phone *"
        />

        <CountryAutocomplete
          getValues={getValues}
          label="Country"
          extraProps={{ ...register('country') }}
          errors={errors}
          handleChange={changeCountries}
          setValue={setValue}
        />

        <FormField
          extraProps={{ ...register('address1') }}
          errors={errors}
          label="Address 1"
          eventInput={(inputValue, event) => {
            changeInput(event as ChangeEvent<HTMLInputElement>);
          }}
        >
          {renderedList}
        </FormField>

        <FormField
          extraProps={{ ...register('address2') }}
          errors={errors}
          label="Address 2"
        />

        <div className={classes.line}>
          <FormField
            extraProps={{ ...register('city') }}
            errors={errors}
            label="City"
          />
          <FormField
            visible={isProvince}
            extraProps={{ ...register('province') }}
            errors={errors}
            label="Province"
          />
        </div>
        <UsaStatesAutocomplete
          visible={!isProvince}
          getValues={getValues}
          label="State"
          errors={errors}
          setValue={setValue}
          extraProps={{ ...register('state') }}
        />

        <FormField
          extraProps={{ ...register('zip') }}
          errors={errors}
          label="ZIP/Postal"
        />

        <Btn
          type="submit"
          customStyles={customButtonStyles}
        >
          Save data
        </Btn>
      </form>
    </CardWrapper>
  );
};

export default memo(Update);
