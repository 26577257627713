import { memo, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card, Typography } from '@mui/material';
import { Loader } from '@monorepo/common';
import { showNotification } from '../../../features/sliceNotification';
import { useUpgradeSuccessMutation } from '../../../services/pressReleases';
import { useStyles } from './styles';

const UpgradeSuccess = () => {
  const { guid } = useParams<{ guid: string }>();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [success, setSuccess] = useState<boolean>(false);
  const query = new URLSearchParams(search);
  const paymentIntentId = query.get('payment_intent');
  const redirectStatus = query.get('redirect_status');
  const company = query.get('company');

  const [upgrade, { isLoading }] = useUpgradeSuccessMutation();

  useEffect(() => {
    const postUpgrade = async () => {
      const upgradeResponse = await upgrade({
        paymentIntentId,
        company,
        pressReleaseGuid: guid
      });

      if (!upgradeResponse) {
        dispatch(showNotification({
          show: true,
          type: 'error',
          text: "Error: Payment Intent doesn't work"
        }));
        return;
      }

      const { data: { success: upgradeSuccess, message } } = upgradeResponse as { data: { success: boolean, message: string } };

      if (!upgradeSuccess) {
        dispatch(showNotification({
          show: true,
          type: 'error',
          text: message
        }));
        return;
      }

      setSuccess(true);
    };

    postUpgrade();
  }, []);

  if (redirectStatus !== 'succeeded') {
    return (
      <Card className={classes.wrapper_card}>
        <Loader isShow={isLoading} />
        <Typography
          variant="h6"
          gutterBottom
        >
          An Error Occured
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
        >
          Something went wrong with processing payment. Please try again later.
        </Typography>
      </Card>
    );
  }

  return (
    <Card className={classes.wrapper_card}>
      <Loader isShow={isLoading} />
      {success && (
        <>
          <Typography
            variant="h6"
            gutterBottom
          >
            Thank you.
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
          >
            Your Press Release was upgraded to promoted.
          </Typography>
        </>
      )}
    </Card>
  );
};

export default memo(UpgradeSuccess);
