import React from 'react';
import { IconEmptyImage } from '@monorepo/common';
import { useStyles } from '../styles';

// interface IEmptyImageProps {}
const EmptyImage = () => {
  const classes = useStyles();

  return (
    <div className={classes.empty}>
      <IconEmptyImage />
    </div>
  );
};

export default EmptyImage;
