import React, { memo } from 'react';
import { Typography } from '@mui/material';

import { currencyFormatter, BasicLayout } from '@monorepo/common';

import { useGetInvoiceDetailsQuery } from '../../../services/serviceInvoice';

import { useStyles } from '../styles';

// interface IDescriptionJobsProps {}
const DescriptionInvoices = () => {
  const classes = useStyles();
  const { data, isLoading } = useGetInvoiceDetailsQuery(null);

  return (
    <BasicLayout isLoading={isLoading}>
      <div className={classes.description_page}>
        <Typography
          sx={{ mb: 3 }}
          variant="h1"
          fontWeight={800}
        >
          Pay an Invoice
        </Typography>

        <p>
          Use this form to pay an existing invoice using ACH/E-Check or credit card (Visa, Mastercard, and American Express accepted).
        </p>
        {data && data.isCreditCardFeeEnabled && (
        <p>
          <b>
            Please note that we charge a
            {' '}
            {data.percentFee}
            % fee when using a credit card to pay an invoice of
            {' '}
            {currencyFormatter.format(data.threshhold)}
            {' '}
            or more.
          </b>
        </p>
        )}

        <p>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          If you have questions, please contact BevNET's Accounting Team at
          {' '}
          <a
            href="mailto: accounting@bevnet.com"
            className={classes.link}
          >
            accounting@bevnet.com
          </a>
          {' '}
          or 617-231-8810.
        </p>
      </div>
    </BasicLayout>
  );
};

export default memo(DescriptionInvoices);
