import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
  LinkAuthenticationElement,
} from '@stripe/react-stripe-js';

import {
  Loader,
  useHookForm,
  FormField
} from '@monorepo/common';

import { useSaveTempDataMutation } from '../../../../services/serviceInvoice';
import { IDataForStep1Props, IEventsStepProps } from '../../interfaceSteps';
import { InvoiceStep2FormProps } from '../../../../services/serviceInvoice/responseInterfaces';
import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { showNotification } from '../../../../features/sliceNotification';
import { schema } from './schema';

import { useStyles } from '../../styles';

export interface IStep2Props extends IEventsStepProps{
  billingAmount: number,
  Nav: React.ElementType;
  setDataForStep2: (val:InvoiceStep2FormProps) => void,
  dataForStep1: IDataForStep1Props;
  dataForStep2: InvoiceStep2FormProps | null;
}

const Step2 = ({ onBack, Nav, dataForStep1, billingAmount, setDataForStep2, dataForStep2 }:IStep2Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [saveTempData, { isLoading: isSavingData, isError, error }] = useSaveTempDataMutation();

  const elements = useElements();
  const stripe = useStripe();
  const {
    watch,
    register,
    handleSubmit,
    errors
  } = useHookForm<InvoiceStep2FormProps>({
    schema,
    ...(dataForStep2) && { defaultValues: { ...dataForStep2 } }
  });

  const onSubmit = useCallback(async (data: InvoiceStep2FormProps) => {
    const tempDataResponse = await saveTempData({
      ...dataForStep1,
      ...data
    });
    if (tempDataResponse) {
      const { data: { guid } } = tempDataResponse as { data: { guid: string } };
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `https://account.bevnet.com/invoice/completed?guid=${guid}`,
        }
      });

      if (result.error) {
        console.log(result.error.message);
        dispatch(showNotification({
          show: true,
          type: 'error',
          text: result.error.message
        }));
      }
    }
  }, [stripe, elements, billingAmount, dispatch]);

  useEffect(() => {
    if (isError) {
      setIsLoading(false);

      let errorText = 'Invoice confirmation error';

      if ('data' in error) {
        errorText = error.data as string;
      }

      dispatch(showNotification({
        show: true,
        type: 'error',
        text: errorText
      }));
    }
  }, [dispatch, error, isError]);

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === 'change') {
        setDataForStep2(value as InvoiceStep2FormProps);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setDataForStep2]);

  return (
    <Card className={classes.wrapper_card}>
      <Loader isShow={isLoading || isSavingData} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <LinkAuthenticationElement />
        <FormField
          errors={errors}
          inputLabelProps={{ shrink: true }}
          placeholder="Billing company"
          label="Billing company"
          extraProps={{ ...register('billingCompany') }}
          classNameForWrapper={classes.form_input}
        />
        <AddressElement options={{ mode: 'billing', fields: { phone: 'always' } }} />
        <br />
        <PaymentElement />
        <Nav
          nextDisabled={false}
          onBack={onBack}
          textPreviousStep="Previous step"
          nextButton={(
            <Button
              endIcon={<ArrowRightIcon fontSize="small" />}
              variant="contained"
              disabled={!stripe}
              type="submit"
            >
              Submit
            </Button>
            )}
        />
      </form>
    </Card>
  );
};

export default memo(Step2);
