import { makeStyles } from '@material-ui/core';

import {
  mixin
} from '../../utils/mixin';

export const useStyles = makeStyles((theme) => ({
  modal_window: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    padding: 10,
    zIndex: 1202,
    ...mixin.flex('center', 'center'),
    '&$active': {
      '& $overlay': {
        opacity: 1,
      },
      '& $modal': {
        opacity: 1,
        pointerEvents: 'auto',
        transform: 'translateY(0px)',
      }
    }
  },
  overlay: {
    ...mixin.overlayBlock(),
    opacity: 0,
    transition: '.4s',
    cursor: 'pointer'
  },
  modal: {
    position: 'relative',
    zIndex: 1,
    transform: 'translateY(50px)',
    opacity: 0,
    pointerEvents: 'none',
    width: '100%',
    maxWidth: '50rem',
    minHeight: 100,
    background: 'rgb(255, 255, 255)',
    color: 'rgb(18, 24, 40)',
    transition: '.4s transform, .4s opacity',
    borderRadius: 8,
    boxShadow: 'rgb(100 116 139 / 12%) 0px 6px 15px',
    backgroundImage: 'none',
    padding: '30px 20px 30px',
    textAlign: 'center',
    overflow: 'auto',
    maxHeight: '90vh',
  },
  close: {
    position: 'absolute',
    top: 5,
    right: 5,
    fill: '#000',
    cursor: 'pointer'
  },
  message: {
    marginBottom: '1rem',
    fontWeight: 600,
    fontSize: '1.3rem',
    '@media(max-width: 768px)': {
      fontSize: '1.0rem'
    }
  },
  active: {},
  detail_error: {
    fontWeight: 400,
    color: theme.palette.error.main,
    fontSize: '1.7rem',
    '@media(max-width: 768px)': {
      fontSize: '1.4rem'
    }
  },
  error: {
    '& $title,& $message,': {
      color: theme.palette.error.main
    }
  },
  scrollDisabled: {
    overflow: 'hidden !important'
  }
}));
