import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import UploadBrand from '@mui/icons-material/CloudUpload';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AddProduct from '@mui/icons-material/Add';
import AddSub from '@mui/icons-material/AddBox';
import LinkIcon from '@mui/icons-material/Link';
import { book } from '../../../routers/books';
import { IBrandOptionsList } from './interface';

export const brandOptionList = (brandGuid: string, brandSlug: string):IBrandOptionsList[] => ([
  {
    link: book.brands.editBrand(brandGuid),
    title: 'Update Brand Information',
    subText: 'Including brand overview, website, social media, phone number, and email addresses.',
    icon: (<EditIcon />)
  },
  {
    link: book.brands.uploadLogo(brandGuid),
    title: 'Upload Brand Logo',
    subText: 'Upload a new logo for your brand.',
    icon: (<UploadBrand />)
  },
  {
    link: book.brands.editProducts(brandGuid),
    title: 'Existing Products',
    subText: 'Edit information at the level of a specific product under this brand.',
    icon: (<AddTaskIcon />)
  },
  {
    link: book.brands.createProduct(brandGuid),
    title: 'Add Product',
    subText: 'Add a new product to go under this brand.',
    icon: (<AddProduct />)
  },
  {
    link: book.brands.createSubBrand(brandGuid),
    title: 'Add Sub Brand',
    subText: 'Add a new sub brand to go under this brand.',
    icon: (<AddSub />)
  },
  {
    isHref: true,
    link: `https://www.bevnet.com/brands/${brandSlug}`,
    title: 'View Public Brand Page',
    subText: 'Go to the public brand page.',
    icon: (<LinkIcon />)
  }
]);
