import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { FormField, CardWrapper, useHookForm } from '@monorepo/common';

import { schema } from './schema';
import { book } from '../../routers/books';
import { useAppSelector } from '../../store/hooks';
import { loadingNames } from '../../constants/loadingNames';
import { removeLoading } from '../../features/sliceLoading';
import { statusConfirmEmail } from '../../features/sliceUserEmail';
import { fillTemporaryUserProfile } from '../../features/sliceUserProfile';
import { useConfirmEmailMutation } from '../../services/userProfile/email';
import { useUpdatePersonalInfoMutation } from '../../services/userProfile/personal';

interface IConfirmEmailFormProps {
  code: string;
  email: string;
}

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [
    confirmEmail,
    { isLoading: isProcessConfirm, data: emailIsConfirmed },
  ] = useConfirmEmailMutation();
  const [
    updatePersonalInfo,
    { isLoading: isSendingData, isSuccess: userDataWasSaved },
  ] = useUpdatePersonalInfoMutation();

  const { newUserEmail, temporaryUserData } = useAppSelector(
    ({ userProfile }) => ({
      newUserEmail: userProfile.temporaryUserData?.email,
      temporaryUserData: userProfile.temporaryUserData,
    })
  );
  const {
    reset,
    register,
    handleSubmit,
    errors
  } = useHookForm<IConfirmEmailFormProps>({
    schema
  });
  const onSubmit = (data: IConfirmEmailFormProps) => {
    confirmEmail(data);
  };

  // init data
  useEffect(() => {
    dispatch(removeLoading(loadingNames.userPersonalInfo));
    if (!newUserEmail) {
      history.push(book.userUpdate);
      return;
    }
    reset({
      email: newUserEmail,
    });
  }, [newUserEmail]);

  // if email was confirm, will save new email  in the user profile
  useEffect(() => {
    if (emailIsConfirmed && temporaryUserData) {
      updatePersonalInfo(temporaryUserData);
    }
  }, [emailIsConfirmed]);

  // email in user profile was updated and will redirect back to contact info
  useEffect(() => {
    if (userDataWasSaved) {
      dispatch(statusConfirmEmail(false));
      dispatch(fillTemporaryUserProfile(null));
      history.push(book.userUpdate);
    }
  }, [userDataWasSaved]);

  return (
    <CardWrapper
      titleHelmet="Confirm your new email address"
      isLoading={isProcessConfirm || isSendingData}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ padding: 20 }}
      >
        <FormField
          type="hidden"
          extraProps={{ ...register('email') }}
          errors={errors}
        />
        <FormField
          extraProps={{ ...register('code') }}
          errors={errors}
          label="Code *"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          style={{ width: '100%' }}
        >
          Send code
        </Button>
      </form>
    </CardWrapper>
  );
};

export default ConfirmEmail;
