import { createApi } from '@reduxjs/toolkit/dist/query/react';
import endpoints from '../../api/endpoints';

import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { ISendHelpFormRequest } from './interfacesForHelpForm';

export const helpApi = createApi({
  reducerPath: 'help',
  tagTypes: [],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    sendHelpForm: build.mutation<void, ISendHelpFormRequest>({
      query: ({ body }) => ({
        url: endpoints.help,
        method: 'POST',
        body
      })
    }),
  })
});

export const {
  useSendHelpFormMutation
} = helpApi;
