import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  wrapper_for_table: {
    width: '100%',
    overflowX: 'auto'
  },
  empty_block: {
    textAlign: 'center'
  },
  subtitle: {
    marginTop: '1rem !important',
    marginBottom: '2rem !important'
  }
}));
