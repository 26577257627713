import React, { memo } from 'react';
import { Card, Typography } from '@mui/material';
import { useStyles } from './styles';

interface IPlugNoDataProps {
  text?: string,
  color?: string,
  card?: boolean,
}
const PlugNoData = ({ text = 'No data', color = 'silver', card = false }:IPlugNoDataProps) => {
  const classes = useStyles();
  if (card) {
    return (
       <Card className={classes.root}>
          <Typography
             fontWeight="bold"
             variant="h4"
             color={color}
          >
             {text}
          </Typography>
       </Card>
    );
  }

  return (
     <div className={classes.root}>
        <Typography
           fontWeight="bold"
           variant="h4"
           color={color}
        >
           {text}
        </Typography>
     </div>
  );
};

export default memo(PlugNoData);
