import React, { useEffect } from 'react';

import { FormField, Btn, useHookForm } from '@monorepo/common';

import { schema } from './schema';

import { useStyles } from '../../styles';
import { IStep1 } from './step1Interfaces';
import { usePostCreateDetailMutation, usePutUpdateDetailMutation } from '../../../../services/serviceInvoice';

interface IStep1CreateInvoiceProps {
  defaultValues?: IStep1,
  quoteId: number,
  id?: string,
  closeModal:() => void,
  setIsSavingData:(val:boolean) => void,
  isEditType: boolean
}

const Step1CreateInvoice = ({ defaultValues, isEditType = false, closeModal, setIsSavingData, quoteId, id }:IStep1CreateInvoiceProps) => {
  const classes = useStyles();
  const [postCreateInvoice, { isLoading: isCreating, isSuccess: isCreated }] = usePostCreateDetailMutation();
  const [putUpdateDetail, { isLoading: isEditing, isSuccess: isEdited }] = usePutUpdateDetailMutation();

  const onSubmit = (data: IStep1) => {
    if (data) {
      if (!isEditType && !id) {
        postCreateInvoice({
          quoteId,
          invoice: data.invoice,
          amount: +data.amount
        });
        return;
      }
      putUpdateDetail({
        id: id as string,
        body: {
          invoice: data.invoice,
          amount: +data.amount
        }
      });
    }
  };
  const {
    register,
    handleSubmit,
    errors
  } = useHookForm<IStep1>({
    schema,
    defaultValues
  });

  useEffect(() => {
    if (isCreating || isEditing) {
      setIsSavingData(true);
    }
  }, [setIsSavingData, isCreating, isEditing]);

  useEffect(() => {
    if (isCreated || isEdited) {
      setIsSavingData(false);
      closeModal();
    }
  }, [setIsSavingData, closeModal, isCreated, isEdited]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classes.step1_modal_create_invoice_wrapper}
    >
      <FormField
        isRequired
        extraProps={{ ...register('invoice') }}
        errors={errors}
        label="Invoice #"
      />
      <FormField
        isRequired
        extraProps={{ ...register('amount') }}
        errors={errors}
        label="Amount to pay (USD)"
      />
      <Btn
        fullWidth
        type="submit"
      >
        Create
      </Btn>
    </form>
  );
};

export default Step1CreateInvoice;
