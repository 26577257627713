import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dropdown: {
    left: 0,
    right: 0,
    zIndex: 2,
    top: '100%',
    width: '98%',
    margin: 'auto',
    background: '#fff',
    borderRadius: '4px',
    position: 'absolute',
    boxShadow: ' 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  }
}));
