import React, { memo } from 'react';
import { Grid } from '@material-ui/core';

import { ErrorBoundary, BasicLayout } from '@monorepo/common';

import PublicProfile from './components/ProfilePicBio';
import WrapperCardContent from './components/PersonalInformation';

import { useStyles } from './styles';

// interface UserProfileProps {}
const Account = () => {
  const classes = useStyles();
  return (
    <BasicLayout helmet="User Portal">
      <Grid
        container
        spacing={3}
        className={classes.my_grid}
      >
        <ErrorBoundary>
          <PublicProfile />
        </ErrorBoundary>

        <ErrorBoundary>
          <WrapperCardContent />
        </ErrorBoundary>
      </Grid>
    </BasicLayout>
  );
};

export default memo(Account);
