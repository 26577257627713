import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  padding_for_left_side: {
    paddingLeft: 80
  },
  header: {
    top: 0,
    right: 0,
    transition: '.4s padding-left',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff'
  },
  logo: {
    width: 120,
    fill: '#000',
    display: 'block',
    margin: '0 1rem 0 0',
    '@media(max-width: 992px)': {
      width: 95,
      margin: '0 .5rem 0 0',
    }
  },
  logoDouble: {
    width: 210,
    fill: '#000',
    display: 'block',
    margin: '0 1rem 0 0',
    '@media(max-width: 768px)': {
      width: 170,
      margin: '0 .5rem 0 0',
    }
  },
  title_portal: {
    fontSize: '1.0625rem',
    fontWeight: 600,
    lineHeight: 1.6,
    color: '#000',
  },
  avatar: {
    position: 'relative',
    background: '#f4511e',
    marginLeft: 'auto'
  },
  userName: {
    color: '#000',
    marginLeft: 15,
    '@media(max-width: 992px)': {
      color: '#000',
      marginLeft: 0,
      wordBreak: 'break-word',
      whiteSpace: 'break-spaces'
    }
  },
  text_item: {
    color: '#000',
    margin: '0 0 0 8px',
    lineHeight: 1.4
  },

  dropdown_styles: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  }
}));
