import { makeStyles } from '@material-ui/core';
// import { mixin } from '../../utils/mixin';

// '@media(max-width: 768px)': {}
export const useStyles = makeStyles(() => ({
  root: {},
  tab_head_mobile: {
    '@media(max-width: 768px)': {
      '& .MuiTabs-flexContainer': {
        alignItems: 'flex-start',
        padding: '0 20px'
      }
    }
  },
  tab_wrapper: {
    '& button': {
      'margin-left': '2rem'
    },
    '@media(max-width: 768px)': {
      '& button': {
        marginLeft: '24px'
      }
    }
  }
}));
