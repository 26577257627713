import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import endpoints from '../../api/endpoints';

export const orderHistoryApi = createApi({
  reducerPath: 'orderHistory',
  tagTypes: ['OrderHistory'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    get: build.query({
      query: () => ({
        url: endpoints.userOrders,
      }),
      providesTags: ['OrderHistory']
    }),
  }),
});
