import React, { memo, ReactNode } from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useStyles } from './styles';

interface IBreadcrumbsPanelProps {
  list: {
    title: string;
    link?: string;
  }[];
  separator?: ReactNode;
  maxWidth?: string | number;
  className?: string;
}
const BreadcrumbsPanel = ({ list, separator, maxWidth, className }:IBreadcrumbsPanelProps) => {
  const classes = useStyles();

  return (
     <Breadcrumbs
        style={{ maxWidth: maxWidth || 'inherit' }}
        separator={separator}
        className={clsx(classes.wrapper, className)}
        aria-label="breadcrumb"
     >
        {list.map((item, i) => {
          if (item.link) {
            return (
               <Link
                  key={`${i.toString()}${item.title}`}
                  color="inherit"
                  to={item.link}
               >
                  {item.title}
               </Link>
            );
          }
          return (
             <Typography
                key={item.title}
                color="text.primary"
             >
                {item.title}
             </Typography>
          );
        })}

     </Breadcrumbs>
  );
};

export default memo(BreadcrumbsPanel);
