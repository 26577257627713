interface ILoadingName {
  userPersonalInfo: string,
  userProfilePicBio: string,
  userOrders: string,
  sendNewPassword: string
  countries: string,
  usaStates: string,
  confirmEmail: string,
}
export const loadingNames: ILoadingName = {
  userPersonalInfo: 'userPersonalInfo',
  userProfilePicBio: 'userProfilePicBio',
  userOrders: 'userOrders',
  sendNewPassword: 'sendNewPassword',
  countries: 'countries',
  usaStates: 'usaStates',
  confirmEmail: 'confirmEmail'
};
