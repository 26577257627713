import React, { useEffect } from 'react';

import { FormField, Btn, CardWrapper, useHookForm } from '@monorepo/common';

import { schema } from './schema';
import { customButtonStyles } from '../../constants/customButtonStyles';
import { useChangePasswordMutation } from '../../services/userProfile/password';
import { useActionAfterUpdatedData } from '../../hook/useActionAfterUpdatedData';

import { useStyles } from './styles';

interface IFormField2s {
  Password: string | null,
  ConfirmPassword:string | null,
}

// interface ChangePasswordProps {}
const ChangePassword = () => {
  const classes = useStyles();
  const [changePassword, { isSuccess, isLoading }] = useChangePasswordMutation();
  const { dataWasUpdated } = useActionAfterUpdatedData();

  const {
    register,
    handleSubmit,
    reset,
    errors
  } = useHookForm<IFormField2s>({
    schema
  });

  // SEND PASSWORD
  const onSubmit = (data: any) => {
    changePassword(data);
  };

  // if pass was changed => show notification and cleared fields
  useEffect(() => {
    if (isSuccess) {
      dataWasUpdated({
        text: 'Password changed successfully',
        scrollUpPage: false
      });
      reset({
        ConfirmPassword: null,
        Password: null
      });
    }
  }, [isSuccess]);

  return (
    <>
      <CardWrapper
        titleHelmet="Change Your Password"
        isLoading={isLoading}
      >
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >

          <FormField
            type="password"
            extraProps={{ ...register('Password') }}
            errors={errors}
            label="Password"
          />
          <FormField
            type="password"
            extraProps={{ ...register('ConfirmPassword') }}
            errors={errors}
            label="Confirm Password"
          />
          <Btn
            type="submit"
            customStyles={customButtonStyles}
          >
            Change Password
          </Btn>
        </form>
      </CardWrapper>
    </>
  );
};

export default ChangePassword;
