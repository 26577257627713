import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  menu: {
    width: 30,
    position: 'relative',
    cursor: 'pointer',
    transition: '.4s transform',
    border: 0,
    background: 'none',
    marginRight: 20,
    '@media(min-width: 960px)': {
      display: 'none'
    },
    '& span': {
      width: '100%',
      display: 'block',
      background: '#000',
      height: 3,
      margin: '5px auto',
      transition: '.4s',
      position: 'relative',
    },
    '&$active': {
      transform: 'rotate(-45deg)',
      '& span:nth-child(1)': {
        transform: 'translate(0px, 8px)'
      },
      '& span:nth-child(2)': {
        width: 0,
      },
      '& span:nth-child(3)': {
        transform: 'rotate(90deg) translate(-7px, 0px)',
      },
      '& span': {
        // margin: 0
      }
    }
  },
  active: {}
}));
