import cookies from 'js-cookie';
import { formattedRedirectLink } from './formattedRedirectLink';
import { baseUrls } from '../constants/baseUrls';

let redirectUrl: string = '';
const redirect = (redirectTo: string) => {
  if (redirectUrl.length === 0) {
    redirectUrl = redirectTo;
    window.location.href = formattedRedirectLink({ to: redirectTo });
  }
};

export const pageRedirect = ({ token, delay = 2000 }:{ delay?: number, token?: string }) => {
  setTimeout(() => {
    if (token) cookies.remove(token);
    redirect(baseUrls.login);
  }, delay);
};
