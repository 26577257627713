import React, { memo, ReactNode, useCallback, useEffect, useState } from 'react';

import { currencyFormatter, Grid, BasicLayout } from '@monorepo/common';

import { useDispatch } from 'react-redux';
import Step1Alert from './Step1Alert';
import Step1Toolbar from './Step1Toolbar';
import { IStep1 } from './step1Interfaces';
import Step1RowOptions from './Step1RowOptions';
import Step1CreateInvoice from './Step1CreateInvoice';
import { IEventsStepProps } from '../../interfaceSteps';
import { getSumForBillingAmount } from './getSumForBillingAmount';
import { IQuotesDetail } from '../../../../services/serviceInvoice/responseInterfaces';
import { invoiceApi, useGetInvoiceDetailsQuery } from '../../../../services/serviceInvoice';
import { showNotification } from '../../../../features/sliceNotification';

interface IStep1Props extends IEventsStepProps{
  Nav: React.ElementType,
  setDataForStep1: (val:{ guid:string | null }) => void,
  setBillingAmount: (amount:number) => void,
}
interface ITable extends IQuotesDetail{
  empty: string
}

const Step1 = ({ onNext, setDataForStep1, Nav, setBillingAmount }:IStep1Props) => {
  const dispatch = useDispatch();
  const [gridData, setGridData] = useState<ITable[]>([]);
  const [isSavingData, setIsSavingData] = useState<boolean>(false);
  const [tableIsEmpty, setTableIsEmpty] = useState<boolean>(true);
  const [modalContent, setModalContent] = useState<{ title: string, modalBody: ReactNode } | null>(null);

  const { data, isLoading } = useGetInvoiceDetailsQuery(null);
  const { data: invoiceDetail, isLoading: gettingInvoiceDetail } = useGetInvoiceDetailsQuery(null);

  const closeModal = () => {
    setModalContent(null);
  };

  const create = () => {
    if (invoiceDetail?.quoteId) {
      setModalContent({
        title: 'Add an Invoice',
        modalBody: (
          <Step1CreateInvoice
            isEditType={false}
            closeModal={closeModal}
            quoteId={invoiceDetail.quoteId}
            setIsSavingData={setIsSavingData}
          />
        )
      });
      return;
    }
    dispatch(showNotification({
      show: true,
      type: 'error',
      text: "Invoice Detail didn't loaded"
    }));
  };

  const edit = useCallback((id: number) => {
    // find data in data from table
    const result: ITable | undefined = gridData.find((col) => col.quoteDetailId === id);

    // open modal with result from table
    if (result && invoiceDetail?.quoteId) {
      setModalContent({
        title: 'Edit',
        modalBody: (
          <Step1CreateInvoice
            isEditType
            id={id.toString()}
            closeModal={closeModal}
            quoteId={invoiceDetail.quoteId}
            setIsSavingData={setIsSavingData}
            defaultValues={{
              invoice: result.details.replace('Payment for Invoice #', ''),
              amount: result.price
            } as IStep1}
          />
        )
      });
    }
  }, [gridData, closeModal, invoiceDetail]);

  const remove = useCallback((id: number) => {
    setModalContent({
      title: 'Delete',
      modalBody: (
        <Step1Alert
          closeModal={closeModal}
          id={id}
        />
      )
    });
  }, [closeModal]);

  useEffect(() => {
    if (invoiceDetail?.quoteGuid) {
      setDataForStep1({
        guid: invoiceDetail.quoteGuid
      });
    }
  }, [invoiceDetail, setDataForStep1]);

  useEffect(() => {
    if (invoiceDetail) {
      setDataForStep1({
        guid: invoiceDetail?.quoteGuid
      });
    }
  }, [data, invoiceDetail]);

  return (
    <BasicLayout
      isLoading={isLoading || gettingInvoiceDetail || isSavingData}
      modalWindowOptions={{
        closeModal,
        show: !!modalContent,
        title: modalContent?.title,
        modalBody: modalContent?.modalBody
      }}
    >
      <Grid<ITable>
        pageable={false}
        toolBarComponent={(
          <Step1Toolbar handlerClick={() => create()} />
        )}
        searchableProps={{
          showSearchField: false
        }}
        keyExtractor={(col) => `col-id-${col.quoteDetailId}`}
        apiProps={{
          api: invoiceApi,
          apiMethodName: 'useLazyGetQuotesDetailsQuery',
          apiParams: {
            id: data?.quoteId
          },
          isSkip: !data,
          responseData: (tableData) => {
            setGridData(tableData);
            setTableIsEmpty(!(tableData.length > 0));
            setBillingAmount(getSumForBillingAmount(tableData));
          }
        }}
        columns={[
          {
            field: 'partNumber',
            title: 'Part #',
            template: (col) => col.partNumber
          },
          {
            field: 'quoteDetailId',
            title: 'Description',
            template: (col) => (
              <>
                <p>_INVOICE PAYMENT</p>
                <p>
                  {col.details}
                </p>
              </>
            )
          },
          {
            field: 'price',
            title: 'Price',
            template: (col) => currencyFormatter.format(col.price)
          },
          {
            field: 'extPrice',
            title: 'Ext Price',
            template: (col) => currencyFormatter.format(col.extPrice),
            footerProps: {
              showTotalAmount: true
            }
          },
          {
            field: 'empty',
            template: (col) => (
              <Step1RowOptions
                edit={() => edit(col.quoteDetailId)}
                remove={() => remove(col.quoteDetailId)}
              />
            )
          },

        ]}
      />

      <Nav
        onNext={onNext}
        nextDisabled={tableIsEmpty}
      />
    </BasicLayout>
  );
};
export default memo(Step1);
