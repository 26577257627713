import { NewsSites } from '../../../constants/newsSites';

const getSite = (siteId: number) => ((!siteId || siteId === NewsSites.Bevnet) ? '' : `/${NewsSites[siteId]?.toLowerCase()}`);

export const onEditAndCopyJobListingHandler = (
  siteId: number,
  eguid: string
) => {
  const site = getSite(siteId);

  window.open(
    `https://jobs.bevnet.com/jobs/editjob${site}?path=${eguid}`,
    '_blank'
  );
};

export const onPostJobPrepaidListingHandler = (
  siteId: number,
  eguid?: string
) => {
  const site = getSite(siteId);

  window.open(
    `https://jobs.bevnet.com/jobs/postprepaidlisting${site}/${
      eguid || ''
    }`,
    '_blank'
  );
};

export const onReactivateJobListingHandler = (
  siteId: number,
  eguid?: string
) => {
  const site = getSite(siteId);

  window.open(
    `https://jobs.bevnet.com/jobs/reactivatelisting${site}?path=${eguid}`,
    '_blank'
  );
};
