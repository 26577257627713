import React, { ReactElement, ReactNode } from 'react';
import { TableCell, TableRow } from '@mui/material';

import { useStyles } from '../styles';

interface ITableCollapseRowProps{
  colspan: number,
  renderChild: ReactNode | ReactElement
}
const TableCollapseRow = ({ colspan, renderChild }:ITableCollapseRowProps) => {
  const classes = useStyles();
  return (
     <TableRow className={classes.row_collapse}>
        <TableCell
           colSpan={colspan}
           className={classes.internal_wrapper_cell}
        >
           {renderChild}
        </TableCell>
     </TableRow>
  );
};

export default TableCollapseRow;
