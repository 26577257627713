import React, { ReactNode } from 'react';
import { InputAdornment } from '@mui/material';

export const insertIcon = (icon?: ReactNode | string | number) => {
  if (icon) {
    if (typeof icon === 'object') {
      return <InputAdornment position="start">{icon}</InputAdornment>;
    }
    return icon;
  }
  return null;
};
