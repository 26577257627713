import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { FormField, Btn, CardWrapper, FormFieldUpload, scrollUp, useHookForm } from '@monorepo/common';

import { schema } from './schema';
import { customButtonStyles } from '../../constants/customButtonStyles';
import { useActionAfterUpdatedData } from '../../hook/useActionAfterUpdatedData';
import {
  publicProfileApi,
  useUploadHeadShotMutation,
  useUpdateUserPublicInfoMutation
} from '../../services/userProfile/public';
import { showNotification } from '../../features/sliceNotification';

import { useStyles } from './styles';

// interface UserUpdateContactInfoProps { }
interface IFormFields {
  publicBio: string | null,
  linkedIn:string | null,
  headshot:string | null,
  uguid:string,
}
interface IError {
  data: string,
  error: string,
  originalStatus: number,
  status: string
}

interface ISuccessError {
  error: IError
}

const PublicProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const { dataWasUpdated } = useActionAfterUpdatedData();
  const { data: userData, isSuccess, isLoading, refetch } = publicProfileApi.useGetQuery('');
  const [updateUserPublicInfo, { isLoading: isDataSaved, isSuccess: dataPublicInfoWasSaved }] = useUpdateUserPublicInfoMutation();
  const [uploadHeadShot, { isLoading: isHeadshotSending }] = useUploadHeadShotMutation();

  const {
    reset,
    register,
    handleSubmit,
    errors
  } = useHookForm<IFormFields>({
    schema
  });

  // UPDATE PUBLIC DATA
  const onSubmit = useCallback(async (data: IFormFields) => {
    if (userData) {
      if (file) {
        const formData = new FormData();
        formData.append('image', file);
        // sent file to server  and waiting applicationURL image from server
        await uploadHeadShot(formData)
          .then((res) => {
            if ('error' in res) {
              const response = res as ISuccessError;
              if (response.error.originalStatus === 200) {
                // if server returned headshot applicationURL, all will be send to server for save
                updateUserPublicInfo({
                  ...data,
                  headshot: response.error.data,
                  uguid: userData.uguid
                });
              }
              if (response.error.originalStatus > 204) {
                dispatch(showNotification({
                  type: 'error',
                  show: true,
                  text: response.error.data
                }));
              }
            }
          });
        return;
      }
      await updateUserPublicInfo({
        ...data,
        uguid: userData.uguid
      });
    }
  }, [userData, file]);

  const change = (dataFile:File | File[]) => {
    setFile(dataFile as File);
    scrollUp();
  };

  useEffect(() => {
    if (userData && isSuccess) {
      reset({
        publicBio: userData.publicBio,
        linkedIn: userData.linkedIn,
        headshot: userData.headshot,
      });
    }
  }, [userData, isSuccess]);

  useEffect(() => {
    if (dataPublicInfoWasSaved) {
      refetch();
      dataWasUpdated({
        text: 'Public Info was updated'
      });
    }
  }, [dataPublicInfoWasSaved]);

  return (
    <>
      <CardWrapper
        titleHelmet="Update Profile Pic/Bio"
        isLoading={isLoading || isDataSaved || isHeadshotSending}
      >

        <form onSubmit={handleSubmit(onSubmit)}>
          {!isHeadshotSending && (
            <FormFieldUpload
              onChange={change}
              defaultValue={userData?.headshot}
              classNameForWrap={classes.upload_area}
            />
          )}
          <FormField
            type="hidden"
            extraProps={{ ...register('headshot') }}
            errors={errors}
            label="headshot"
          />
          <FormField
            extraProps={{ ...register('publicBio') }}
            errors={errors}
            label="Public Bio"
            type="textarea"
          />
          <FormField
            extraProps={{ ...register('linkedIn') }}
            errors={errors}
            label="Linked In"
            fieldComment="The URL to your LinkedIn profile"
          />

          <Btn
            type="submit"
            customStyles={customButtonStyles}
          >
            Save data
          </Btn>

        </form>

      </CardWrapper>
    </>
  );
};

export default PublicProfile;
