import { IMenuItem } from './interface';

export function filterMenu(v: string, menu:IMenuItem[]):IMenuItem[] | undefined {
  const items:IMenuItem[] = [];
  menu.forEach((el) => {
    const newEl = { ...el };
    if (newEl.subMenus) {
      const a:IMenuItem[] | undefined = filterMenu(v, newEl.subMenus);
      if (a?.length) {
        newEl.subMenus = a;
        newEl.isOpen = true;
        items.push(newEl);
      }
    } else
    if (newEl?.title?.trim().toLowerCase().includes(v?.trim().toLowerCase())) {
      items.push(newEl);
    }
  });
  return items;
}
