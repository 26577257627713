import React from 'react';
import { Typography } from '@mui/material';
import { useStyles } from '../styles';

export const EmptyBrand = () => {
  const classes = useStyles();
  return (
    <div className={classes.empty_wrapper}>
      <Typography
        variant="h5"
        color="gray"
      >
        No products
      </Typography>
    </div>
  );
};
