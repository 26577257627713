import React, { memo, ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { IChildrenProps } from '../../interfaces';
import ErrorBoundary from '../../containers/ErrorBoundary';

import { useStyles } from './styles';

interface ICustomStylesHover {
  backgroundColor: string,
  color: string,
  border?: string | null,
}

export interface ICustomStyles {
  backgroundColor: string,
  color: string,
  border?: string | null,
  hover: ICustomStylesHover
}

interface IBtnProps extends IChildrenProps {
  type?: 'button' | 'submit' | 'reset',
  fullWidth?: boolean
  variant?: 'contained' | 'outlined',
  customStyles?: ICustomStyles | null,
  link?: string,
  click?: () => void,
  className?: string,
  disabled?: boolean,
  external?: boolean,
  target?: '_blank' | '_parent' | '_self' | '_top',
  iconProps?: {
    beforeIconElement?: ReactNode,
    afterIconElement?: ReactNode,
  }
}

const Btn = ({
  type,
  link,
  children,
  external,
  iconProps,
  fullWidth = false,
  variant = 'contained',
  customStyles = null,
  click,
  className,
  disabled = false,
  target
}: IBtnProps) => {
  const classes = useStyles();
  const [typeButton, setTypeButton] = useState<'button' | 'submit' | 'reset'>('button');

  useEffect(() => {
    if (type) {
      setTypeButton(type);
      return;
    }
    setTypeButton('button');
  }, [type]);

  const createNewClass = !customStyles ? null : makeStyles(() => ({
    newStyles: {
      color: customStyles.color,
      backgroundColor: customStyles.backgroundColor,
      border: `1px solid ${customStyles.border}`,
      '&:hover': {
        backgroundColor: customStyles.hover.backgroundColor,
        color: customStyles.hover.color,
        border: `${customStyles.hover.border ? 1 : 0}px solid ${customStyles.hover.border}`,
      }
    }
  }));
  const initNewStyles = customStyles && createNewClass && createNewClass();
  if (link) {
    if (external) {
      return (
         <a
            target={target && target}
            href={`${link}`}
            className={clsx(classes.btnRoot, {
              [classes.fullWidth]: fullWidth,
              [classes.disabled]: disabled,
              [classes.contained]: !customStyles && variant === 'contained',
              [classes.outlined]: !customStyles && variant === 'outlined',
            }, initNewStyles && initNewStyles.newStyles, className)}
            rel="noreferrer"
         >
            {children && (
            <>
               {iconProps && iconProps.beforeIconElement && iconProps.beforeIconElement}
               <span className={classes.text}>
                  {children}
               </span>
               {iconProps && iconProps.afterIconElement && iconProps.afterIconElement}
            </>
            )}
         </a>
      );
    }
    return (
       <ErrorBoundary>
          <Link
             to={`${link}`}
             className={clsx(classes.btnRoot, {
               [classes.fullWidth]: fullWidth,
               [classes.disabled]: disabled,
               [classes.contained]: !customStyles && variant === 'contained',
               [classes.outlined]: !customStyles && variant === 'outlined',
             }, initNewStyles && initNewStyles.newStyles, className)}
          >
             {children && (
             <>
                {iconProps && iconProps.beforeIconElement && iconProps.beforeIconElement}
                <span className={classes.text}>
                   {children}
                </span>
                {iconProps && iconProps.afterIconElement && iconProps.afterIconElement}
             </>
             )}
          </Link>
       </ErrorBoundary>
    );
  }

  return (
     <ErrorBoundary>
        <button
                // eslint-disable-next-line react/button-has-type
           type={typeButton}
           disabled={disabled}
           onClick={() => click && click()}
           className={clsx(classes.btnRoot, {
             [classes.fullWidth]: fullWidth,
             [classes.disabled]: disabled,
             [classes.contained]: !customStyles && variant === 'contained',
             [classes.outlined]: !customStyles && variant === 'outlined',
           }, initNewStyles && initNewStyles.newStyles, className)}
        >
           {children && (
           <>
              {iconProps && iconProps.beforeIconElement && iconProps.beforeIconElement}
              <span className={classes.text}>
                 {children}
              </span>
              {iconProps && iconProps.afterIconElement && iconProps.afterIconElement}
           </>
           )}
        </button>
     </ErrorBoundary>
  );
};

export default memo(Btn);
