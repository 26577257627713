import React, { memo, ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import { IChildrenProps } from '../../interfaces';
import ErrorBoundary from '../../containers/ErrorBoundary';

import { useStyles } from './styles';

interface ICustomStylesHover {
  backgroundColor: string,
  color: string,
  border?: string | null,
}

interface IMenuItem {
  menuText: string,
  action: () => void,
}

export interface ICustomStyles {
  backgroundColor: string,
  color: string,
  border?: string | null,
  hover: ICustomStylesHover
}

interface IBtnProps extends IChildrenProps {
  type?: 'button' | 'submit' | 'reset',
  fullWidth?: boolean
  variant?: 'contained' | 'outlined',
  customStyles?: ICustomStyles | null,
  className?: string,
  items: IMenuItem[],
  disabled?: boolean,
  iconProps?: {
    beforeIconElement?: ReactNode,
    afterIconElement?: ReactNode,
  }
}

const BtnMenu = ({
  type,
  children,
  iconProps,
  fullWidth = false,
  variant = 'contained',
  customStyles = null,
  className,
  items,
  disabled = false
}: IBtnProps) => {
  const classes = useStyles();
  const [typeButton, setTypeButton] = useState<'button' | 'submit' | 'reset'>('button');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (type) {
      setTypeButton(type);
      return;
    }
    setTypeButton('button');
  }, [type]);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createNewClass = !customStyles ? null : makeStyles(() => ({
    newStyles: {
      color: customStyles.color,
      backgroundColor: customStyles.backgroundColor,
      border: `1px solid ${customStyles.border}`,
      '&:hover': {
        backgroundColor: customStyles.hover.backgroundColor,
        color: customStyles.hover.color,
        border: `${customStyles.hover.border ? 1 : 0}px solid ${customStyles.hover.border}`,
      }
    }
  }));
  const initNewStyles = customStyles && createNewClass && createNewClass();

  return (
     <ErrorBoundary>
        <button
            // eslint-disable-next-line react/button-has-type
           type={typeButton}
           id="button-menu"
           disabled={disabled}
           aria-controls={open ? 'basic-menu' : undefined}
           aria-haspopup="true"
           aria-expanded={open ? 'true' : undefined}
           onClick={handleClick}
           className={clsx(classes.btnRoot, {
             [classes.fullWidth]: fullWidth,
             [classes.disabled]: disabled,
             [classes.contained]: !customStyles && variant === 'contained',
             [classes.outlined]: !customStyles && variant === 'outlined',
           }, initNewStyles && initNewStyles.newStyles, className)}
        >
           {children && (
           <>
              {iconProps && iconProps.beforeIconElement && iconProps.beforeIconElement}
              <span className={classes.text}>
                 {children}
              </span>
              {iconProps && iconProps.afterIconElement && iconProps.afterIconElement}
           </>
           )}
        </button>

        <Menu
           id="basic-menu"
           anchorEl={anchorEl}
           open={open}
           onClose={handleClose}
           MenuListProps={{
             'aria-labelledby': 'button-menu',
           }}
        >
           {items.map((item, i) => (
              <MenuItem
                 key={`${i.toString()}${item.menuText}`}
                 onClick={() => {
                   handleClose();
                   item.action();
                 }}
              >
                 {item.menuText}
              </MenuItem>
           ))}
        </Menu>
     </ErrorBoundary>
  );
};

export default memo(BtnMenu);
