import React from 'react';

import ModalWindow from '../../../components/ModalWindow';
import { IModalWindowProps } from '../../../components/ModalWindow/modalWindowInterface';

interface ICardWrapperModalWindowProps {
  modalWindowOptions?: IModalWindowProps
}
const CardWrapperModalWindow = ({ modalWindowOptions }:ICardWrapperModalWindowProps) => (
   <ModalWindow
      text={modalWindowOptions?.text}
      errorMode={modalWindowOptions?.errorMode}
      show={!!modalWindowOptions?.show}
      title={modalWindowOptions?.title}
      closeModal={() => modalWindowOptions?.closeModal()}
      styleProps={modalWindowOptions?.styleProps}
   >
      {modalWindowOptions?.modalBody}
   </ModalWindow>
);

export default CardWrapperModalWindow;
