import { ChangeEvent, useCallback, useEffect, useState, useRef } from 'react';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import RenderList from '../../components/RenderList';
import { PossibleValueType, PossibleKeysType } from '../../interfaces';
import { useStyles } from './styles';
import { generateAddress } from './generateAddress';

interface IUseCustomGoogleAutocomplete {
  country: string;
  mode?: 'default' | 'custom' | 'native';
  setValue?: any;
  autoFillFields?: {
    [key in PossibleValueType]?: PossibleValueType;
  };
}

interface ISuggestion {
  place_id: string;
  description: string;
  matched_substrings: any[];
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: any[];
  };
  terms: any[];
  types: string[];
}

interface ISelectedPlace {
  addressComponents: {
    [key in PossibleValueType]?: string | null | number;
  };
  formattedAddress: string;
}

const useCustomGoogleAutocomplete = ({
  country = '',
  mode = 'default',
  autoFillFields,
  setValue,
}: IUseCustomGoogleAutocomplete) => {
  const classes = useStyles();
  const [inputElement, setInputElement] = useState<ChangeEvent<HTMLInputElement> | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedPlace, setSelectedPlace] = useState<ISelectedPlace | null>(
    null
  );
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);
  // for internal  processes
  const [statusResults, setStatusResults] = useState<string>('');
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const renderListRef = useRef<any>(null);

  useEffect(() => {
    if (inputElement) {
      document.addEventListener('click', (event) => {
        if (event.target !== inputElement.target) {
          if (
            renderListRef.current === null
            || (renderListRef?.current?.target
              && event.target !== renderListRef?.current.target
              && !renderListRef.current?.target.contains(event.target))
          ) {
            setIsShowDropDown(false);
          }
        }
      });

      inputElement.target.onkeydown = (e) => {
        // if tab key was pressed, losing focus from an inputElement
        if ((e.which || e.keyCode) === 9 || e.key === 'Tab') {
          setIsShowDropDown(false);
        }
      };
    }

    return () => {
      document.removeEventListener('click', () => undefined);
    };
  }, [inputElement]);

  const changeInput = useCallback(
    (el: ChangeEvent<HTMLInputElement>) => {
      setInputValue(el.target.value);
      setInputElement(el);
      setSelectedPlace(null);
      if (mode === 'default') {
        const autocomplete = new google.maps.places.Autocomplete(el.target, {
          componentRestrictions: { country },
        });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { address_components, formatted_address } = autocomplete.getPlace();
          if (address_components && formatted_address) {
            setSelectedPlace({
              addressComponents: generateAddress({
                addressComponents: address_components,
                formattedAddress: formatted_address,
              }),
              formattedAddress: formatted_address,
            });
          }
        });
      }

      const service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        { input: el.target.value, componentRestrictions: { country } },
        (res, status) => {
          if (res && status) {
            setStatusResults(status);
            setSuggestions(res);
            setIsShowDropDown(true);
          }
        }
      );
    },
    [country, mode]
  );

  const selectItem = (address: ISuggestion) => {
    if (address) {
      const service = new google.maps.Geocoder();
      service.geocode({ address: address.description }, (res) => {
        if (res) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { address_components, formatted_address } = res[0];
          if (address_components && formatted_address) {
            setSelectedPlace({
              addressComponents: generateAddress({
                addressComponents: address_components,
                formattedAddress: formatted_address,
              }),
              formattedAddress: formatted_address,
            });
          }
        }
      });
    }
  };

  const renderSuggestions = useCallback(() => {
    if (statusResults === 'OK' && isShowDropDown && mode === 'custom') {
      return (
         <RenderList
            // adding ts-ignore to avoid adding extra settings
            // @ts-ignore
            innerRef={renderListRef}
            classNameForList={classes.dropdown}
         >
            {suggestions
            && suggestions.map((suggestion) => {
              const itemId: string = suggestion.place_id;
              const mainText: string = suggestion.structured_formatting.main_text;
              const secondaryText: string = suggestion.structured_formatting.secondary_text;
              return (
                 <li
                    role="presentation"
                    key={itemId}
                    onClick={() => selectItem(suggestion)}
                 >
                    <LocationOnIcon />
                    <strong>
                       {mainText}
                       {' '}
                    </strong>
                    {' '}
                    <small>{secondaryText}</small>
                 </li>
              );
            })}
         </RenderList>
      );
    }
    return [];
  }, [statusResults, suggestions, isShowDropDown, mode]);

  const clearSuggestions = useCallback(() => {
    setSelectedPlace(null);
    setInputValue('');
    setSuggestions([]);
    setIsShowDropDown(false);
    setInputElement(null);

    if (autoFillFields && Object.keys(autoFillFields).length) {
      Object.keys(autoFillFields).forEach((item: PossibleKeysType) => {
        setValue(autoFillFields[item], null, {
          shouldDirty: true,
          shouldTouch: true,
          // shouldValidate: true
        });
      });
    }
  }, [autoFillFields]);

  useEffect(() => {
    if (selectedPlace) {
      setInputValue((selectedPlace.addressComponents.address1 as string) || '');
      setIsShowDropDown(false);
      setSuggestions([]);
    }
  }, [selectedPlace]);

  useEffect(() => {
    if (inputElement && selectedPlace && Object.keys(selectedPlace).length) {
      if (autoFillFields && Object.keys(autoFillFields).length && setValue) {
        Object.keys(autoFillFields).forEach((item: PossibleValueType) => {
          setValue(autoFillFields[item], selectedPlace.addressComponents[item], {
            shouldDirty: true,
            shouldTouch: true,
            // shouldValidate: true
          });
        });
      }
      setInputElement(null);
    }
  }, [inputElement, selectedPlace, autoFillFields, setValue, inputValue]);

  return {
    // mandatory parameters
    changeInput,

    // methods
    clearSuggestions,
    selectItem,

    // for custom template list
    status: statusResults,
    //   clear dates
    suggestionList: suggestions,

    // returned data
    valueForField: (selectedPlace && selectedPlace.formattedAddress) || '',
    renderedList: renderSuggestions(),
    value: inputValue,
    selectedPlace,
  };
};

export default useCustomGoogleAutocomplete;
