import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper_card: {
    padding: '40px 20px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    maxWidth: '720px',
    margin: '0 auto'
  }
}));
