import React from 'react';
import { Link } from 'react-router-dom';

import { book } from '../../../../routers/books';
import EmptyImage from './EmptyImage';

interface IProductImageProps {
  bguid: string;
  imageUrl: string;
  isActive: boolean;
  productGuid: string;
}
export const ProductImage = ({ imageUrl, bguid, isActive, productGuid }:IProductImageProps) => {
  if (!bguid && !productGuid) {
    return null;
  }
  if (isActive) {
    return (
      <Link to={book.brands.uploadProductPhotos(bguid, productGuid)}>
        {imageUrl ? (
          <img
            src={imageUrl}
            alt=""
          />
        ) : (
          <EmptyImage />
        )}
      </Link>
    );
  }
  return (
    <>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt=""
        />
      ) : (
        <EmptyImage />
      )}
    </>
  );
};
