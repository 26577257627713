import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    margin: '2rem',

    '& h6': {
      marginBottom: '1rem'
    }
  }
}));
