import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Typography, IconButton } from '@mui/material';

import { ChevronRight as ChevronRightIcon } from '../../../../components/Icons/chevron-right';
import { ProductCardStatus } from './ProductCardStatus';
import { ProductCardBody } from './ProductCardBody';
import { ProductImage } from './ProductImage';
import { useStyles } from '../styles';

interface IProductCardProps {
  bguid: string;
  index: number;
  imageUrl: string;
  isActive: boolean;
  productGuid: string;
  productName: string;
  productSubBrand: string;
  openProduct: boolean;
  changeStatus: (pguid: string) => void;
  handleOpenProduct: (pguid: string) => void;
  handleSubBrandClick: (subBrand: string) => void;
}

const ProductCard = ({ imageUrl, productName, productSubBrand, index, bguid, productGuid, changeStatus, openProduct, handleOpenProduct, isActive, handleSubBrandClick }:IProductCardProps) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const classes = useStyles();

  const handlerChangeStatus = () => {
    changeStatus(productGuid);
  };

  const onSubBrandClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    handleSubBrandClick(productSubBrand);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsShow(true);
      clearTimeout(timeout);
    }, 1);
  }, []);

  return (
    <div
      className={clsx(classes.card, {
        [classes.active]: isShow,
        [classes.open]: openProduct,
      })}
      style={{ transitionDelay: `${index / 6}s` }}
    >

      <div className={classes.card_head}>
        <IconButton
          onClick={() => handleOpenProduct(productGuid)}
          className={clsx(classes.arrow_button, { [classes.active]: openProduct })}
        >
          <ChevronRightIcon fontSize="small" />
        </IconButton>

        <ProductImage
          bguid={bguid}
          isActive={isActive}
          imageUrl={imageUrl}
          productGuid={productGuid}
        />

        <div className={classes.product_info}>
          <Typography
            variant="subtitle2"
          >
            {productName}
          </Typography>

          <Typography
            className={classes.sub_brand}
            variant="subtitle2"
            onClick={onSubBrandClick}
          >
            {productSubBrand}
          </Typography>

          <ProductCardStatus isActive={isActive} />
        </div>
      </div>

      <ProductCardBody
        bguid={bguid}
        isActive={isActive}
        isShow={!!openProduct}
        productGuid={productGuid}
        productName={productName}
        handleOpenProduct={handleOpenProduct}
        handlerChangeStatus={handlerChangeStatus}
      />
    </div>
  );
};

export default ProductCard;
