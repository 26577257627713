import React, { memo } from 'react';
import { StepLabel, Typography } from '@mui/material';
import StepIcon from './StepIcon';
import { useStyles } from '../styles';

interface IStepperHeadLabelProps {
  label: string;
  stepIconColor?: string;
  setActiveStep?: () => void;
}
const StepperHeadLabel = ({ label, setActiveStep, stepIconColor }:IStepperHeadLabelProps) => {
  const classes = useStyles();
  return (
     <StepLabel
        className={classes.step_icon_label}
        StepIconComponent={StepIcon}
        onClick={setActiveStep}
        {...(stepIconColor) && {
          sx: {
            '& .MuiAvatar-root': {
              backgroundColor: stepIconColor
            }
          }
        }}

     >
        <Typography
           sx={{ ml: 2 }}
           variant="h6"
           fontWeight={800}
        >
           {label}
        </Typography>
     </StepLabel>
  );
};
export default memo(StepperHeadLabel);
