interface IInternalRequest {
  api?: any,
  apiMethodName?: string
}
export const internalRequest = ({ api, apiMethodName }:IInternalRequest) => {
  if (api && apiMethodName) {
    const requestBody = api[apiMethodName]();
    return {
      data: requestBody[1].data,
      isFetching: requestBody[1].isFetching,
      // eslint-disable-next-line prefer-destructuring
      getDataForGrid: requestBody[0]
    };
  }
  return {
    data: null,
    isFetching: false,
    getDataForGrid: null
  };
};
