import React, { useEffect, useState } from 'react';

import ModalWindowWrapper from './components/ModalWindowWrapper';
import ModalWindowOverlay from './components/ModalWindowOverlay';
import ModalWindowBody from './components/ModalWindowBody';
import { IModalWindowProps } from './modalWindowInterface';

const ModalWindow = ({ children, show, title, text, errorMode, closeModal, styleProps, modalBody }:IModalWindowProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const closeModalWindow = () => {
    setIsVisible(false);

    const myTimeout = setTimeout(() => {
      setShowModal(false);
      closeModal();
      clearTimeout(myTimeout);
    }, 10);
  };

  useEffect(() => {
    if (show) {
      setShowModal(true);
      return;
    }
    setShowModal(false);
  }, [show]);

  // for hide scroll in body
  useEffect(() => {
    if (showModal) {
      const myTimeout = setTimeout(() => {
        setIsVisible(true);
        clearTimeout(myTimeout);
      }, 100);
    }
  }, [showModal]);

  if (!showModal) {
    return null;
  }
  return (
     <ModalWindowWrapper isVisible={isVisible}>
        <ModalWindowOverlay
           closeModal={closeModalWindow}
           styleProps={styleProps}
        />
        <ModalWindowBody
           text={text}
           title={title}
           show={showModal}
           errorMode={errorMode}
           styleProps={styleProps}
           closeModal={closeModalWindow}
        >
           {children || modalBody}
        </ModalWindowBody>
     </ModalWindowWrapper>
  );
};

export default ModalWindow;
