import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  itemCard: {
    boxShadow: 'none !important',
    backgroundColor: 'none !important',
  },
  itemCardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  itemCardImageWrapper: {
    width: '150px',
    marginRight: '10px',
  },
  itemCardImage: {
    height: 'auto !important',
    maxWidth: '100% !important',
  },
  inactiveCardContent: {
    padding: '0 !important',
  },
}));
