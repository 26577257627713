import React, { memo } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';

interface ErrorTextProps {
  text: string | number;
}
const ErrorText = ({ text }: ErrorTextProps) => {
  const classes = useStyles();
  return (
     <Box className={classes.error_text}>
        <CancelIcon />
        <Typography component="span">{text}</Typography>
     </Box>
  );
};

export default memo(ErrorText);
