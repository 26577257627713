import {
  Btn,
  CardWrapper,
} from '@monorepo/common';
import {
  Box,
  Table, TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import moment from 'moment';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import EmptyTable from '../../../components/EmptyTable';
import { book } from '../../../routers/books';
import { useFetchJobsPrepaidQuery } from '../../../services/jobs/index';
import { onPostJobPrepaidListingHandler } from '../Common/common';
import { useStyles } from './styles';

export const JobsPrepaid = () => {
  const classes = useStyles();
  const { data: jobsPrepaidResponse, isLoading } = useFetchJobsPrepaidQuery('');

  const getToUrl = (prepaidGuid: string) => book.jobs.jobsPrepaidDetail(prepaidGuid);

  return (
    <>
      <CardWrapper
        outsideHead={{
          title: 'Prepaid Job Packages'
        }}
        helmet="Prepaid Job Packages"
        isLoading={isLoading}
      >
        <div className={classes.wrapper_for_table}>
          <Typography
            color="textPrimary"
            variant="h6"
            className={classes.subtitle}
          >
            Current Prepaid Job Packages
          </Typography>
          <Box sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Prepaid Job Package
                  </TableCell>
                  <TableCell>
                    Created
                  </TableCell>
                  <TableCell>
                    Total Listings
                  </TableCell>
                  <TableCell>
                    Remaining Listings
                  </TableCell>
                  <TableCell>
                    Type
                  </TableCell>
                  <TableCell>
                    Post
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsPrepaidResponse && jobsPrepaidResponse.currentPackages && jobsPrepaidResponse.currentPackages.length ? jobsPrepaidResponse.currentPackages.map((item: any) => (
                  <TableRow key={item.prepaidId}>
                    <TableCell>
                      <Link to={getToUrl(item.prepaidGuid)}>
                        {item.prepaidId}
                        {' '}
                        -
                        {' '}
                        {item.company}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {moment(item.dateCreated).format('MM/DD/YYYY') || '-'}
                    </TableCell>
                    <TableCell>
                      {item.totalCount}
                    </TableCell>
                    <TableCell>
                      {item.remainingCount}
                    </TableCell>
                    <TableCell>
                      {item.type}
                    </TableCell>
                    <TableCell>
                      <Btn
                        type="button"
                        click={() => onPostJobPrepaidListingHandler(item.siteId, item.prepaidGuid)}
                      >
                        Post a Job
                      </Btn>
                    </TableCell>
                  </TableRow>
                )) : <EmptyTable colSpan={6} />}

              </TableBody>
            </Table>
          </Box>
        </div>

        <div className={classes.wrapper_for_table}>
          <Typography
            color="textPrimary"
            variant="h6"
            className={classes.subtitle}
          >
            Completed Prepaid Job Packages
          </Typography>
          <Box sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Prepaid Job Package
                  </TableCell>
                  <TableCell>
                    Created
                  </TableCell>
                  <TableCell>
                    Total Listings
                  </TableCell>
                  <TableCell>
                    Remaining Listings
                  </TableCell>
                  <TableCell>
                    Type
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsPrepaidResponse && jobsPrepaidResponse.completedPackages && jobsPrepaidResponse.completedPackages.length ? jobsPrepaidResponse.completedPackages.map((item: any) => (
                  <TableRow key={item.prepaidId}>
                    <TableCell>
                      <Link to={getToUrl(item.prepaidGuid)}>
                        {item.prepaidId}
                        {' '}
                        -
                        {' '}
                        {item.company}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {moment(item.dateCreated).format('MM/DD/YYYY') || '-'}
                    </TableCell>
                    <TableCell>
                      {item.totalCount}
                    </TableCell>
                    <TableCell>
                      {item.remainingCount}
                    </TableCell>
                    <TableCell>
                      {item.type}
                    </TableCell>
                  </TableRow>
                )) : <EmptyTable colSpan={5} />}

              </TableBody>
            </Table>
          </Box>
        </div>
      </CardWrapper>
    </>
  );
};

export default memo(JobsPrepaid);
