import * as yup from 'yup';
import YupPassword from 'yup-password';

// @ts-ignore
YupPassword(yup);

export const schema:any = yup.object().shape({
  Password: yup.string()
    .min(8, 'Must Contain min 8 Characters')
  // @ts-ignore
    .minSymbols(1, 'Must Contain min 1 special symbol @$!%*#?&')
    .minUppercase(1, 'Must Contain min 1 Upper  Character')
    .minNumbers(1, 'Must Contain min 1 Number  Character')
    .minLowercase(1, 'Must Contain min 1 Lower  Character'),
  ConfirmPassword: yup.string()
    .oneOf([yup.ref('Password'), null], 'Passwords must match'),
});
