import { Btn, CardWrapper } from '@monorepo/common';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { memo, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { book } from '../../../routers/books';
import { useGetJobsApplicantDetailQuery, useMarkAsReadJobsApplicantionMutation } from '../../../services/jobs/index';
import { useStyles } from './styles';

interface IParams {
  eguid: string;
  applicationId: string;
}

export const JobsApplicantDetail = () => {
  const { eguid, applicationId } = useParams<IParams>();
  const classes = useStyles();
  const history = useHistory();
  const { data, isLoading } = useGetJobsApplicantDetailQuery({
    eguid,
    applicationId,
  });
  const [markAsRead, { isLoading: isUpdating, isSuccess }] = useMarkAsReadJobsApplicantionMutation();

  const onDownloadFileHandler = (jobApplicationFileGuid: string) => `https://app.bevnet.com/jobs/getapplicantfile/${eguid}/${jobApplicationFileGuid}`;

  const setApplicationAsRead = () => {
    markAsRead({ applicationId: data.applicationId });
  };

  useEffect(() => {
    if (isSuccess) {
      history.push(book.jobs.jobsApplicants(eguid), { from: 'jobListing' });
    }
  }, [eguid, history, isSuccess]);

  return (
    <CardWrapper
      maxWidth="70rem"
      helmet="View Applicant"
      isLoading={isLoading || isUpdating}
      outsideHead={{
        title: 'View Applicant',
        breadcrumbs: [
          {
            title: 'Manage Applications',
            link: data ? book.jobs.jobsApplicants(data.eguid) : null,
          },
          {
            title: 'View Applicant',
          },
        ],
        childrenTitleRight: data && !data.isRead ? (
          <Btn click={setApplicationAsRead}>Mark as Read</Btn>
        ) : null,
      }}
    >
      <Typography
        color="textPrimary"
        variant="h6"
        className={classes.subtitle}
      >
        Job Information
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell width={200}>
              <Typography variant="body2">Job Title</Typography>
            </TableCell>

            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {data?.jobTitle}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={200}>
              <Typography variant="body2">Company Name</Typography>
            </TableCell>

            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {data?.company}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Typography
        color="textPrimary"
        variant="h6"
        className={classes.subtitle}
      >
        Your Contact Information
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell width={200}>
              <Typography variant="body2">Name</Typography>
            </TableCell>

            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {data?.name}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={200}>
              <Typography variant="body2">Email</Typography>
            </TableCell>

            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {data?.email}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Typography
        color="textPrimary"
        variant="h6"
        className={classes.subtitle}
      >
        Note
      </Typography>
      <Typography
        color="textSecondary"
        variant="body2"
      >
        {data?.noteToEmployer}
      </Typography>

      {data && data.files && data.files.length ? (
        <div>
          <Typography
            color="textPrimary"
            variant="h6"
            className={classes.subtitle}
          >
            Files
          </Typography>
          {data.files.map((item: any) => (
            <Typography
              color="textSecondary"
              variant="body2"
              key={item.jobApplicationFileId}
            >
              <Link
                to={{
                  pathname: onDownloadFileHandler(item.jobApplicationFileGuid),
                }}
                target="_blank"
              >
                {item.displayName}
              </Link>
            </Typography>
          ))}
        </div>
      ) : null}
    </CardWrapper>
  );
};

export default memo(JobsApplicantDetail);
