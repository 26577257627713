import React, { ReactChildren, ReactNode, ReactNodeArray } from 'react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { book } from '../../../../routers/books';

import { useStyles } from '../styles';

interface IEditProductsContentProps {
  title: string;
  brandGuid: string;
  selectedCategories: ReactNode;
  children: ReactNode | ReactNodeArray | ReactChildren,
  selectCategory: ReactNode | ReactNodeArray | ReactChildren,
}
export const EditProductsContent = ({ title, children, brandGuid, selectedCategories, selectCategory }:IEditProductsContentProps) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.wrapper_content}>
        <Typography
          variant="h5"
          marginBottom={2}
          marginTop={4}
        >
          {title}
        </Typography>

        <Typography
          variant="body1"
          marginBottom={4}
        >
          To edit information for a particular product, please choose one of the options below:
        </Typography>
      </div>

      {selectedCategories}
      {selectCategory}

      <div className={classes.captions}>
        <Typography
          variant="button"
        >
          Name
        </Typography>
        <Typography
          variant="button"
        >
          Sub Brand
        </Typography>
        <Typography
          variant="button"
        >
          Status
        </Typography>
        <Typography
          className={classes.head_info_mobile}
          variant="button"
        >
          Product Info
        </Typography>
      </div>
      {children}

      <div className={classes.wrapper_content}>
        <Link
          to={book.brands.createProduct(brandGuid)}
          className={classes.add_new_product}
        >
          <Button
            fullWidth
            variant="outlined"
          >
            Add new product
          </Button>
        </Link>
      </div>
    </>
  );
};
