import React from 'react';
import { Button, Checkbox, FormControlLabel, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FilterTypesInternal } from '../intyerface';

interface IEditProductFilterPanelProps {
  categories: { [key in FilterTypesInternal]: boolean },
  setCategories: (val: { [key in FilterTypesInternal]: boolean }) => void;
}

export const EditProductFilterPanel = ({ setCategories, categories }:IEditProductFilterPanelProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeCurrent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategories({
      ...categories,
      [event.target.name]: event.target.checked
    });
  };
  const handleChangeDiscontinued = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategories({
      ...categories,
      [event.target.name]: event.target.checked
    });
  };

  const { active, discontinued } = categories;

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="text"
        endIcon={<KeyboardArrowDownIcon />}
        style={{
          marginLeft: 10
        }}
      >
        Category
      </Button>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={active}
                onChange={handleChangeCurrent}
                name="active"
              />
                    )}
            label="Active Products"
          />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={discontinued}
                onChange={handleChangeDiscontinued}
                name="discontinued"
              />
                    )}
            label="Discontinued Products "
          />
        </MenuItem>
      </Menu>
    </>
  );
};
