import React from 'react';
import { Box, Typography } from '@mui/material';
import Error from './error.svg';

interface IDefaultTextProps {
  text: string
}
const DefaultText = ({ text }:IDefaultTextProps) => (
   <>
      <Box
         sx={{
           '& img': {
             width: 100
           }
         }}
      >
         <img
            src={Error}
            alt=""
         />
      </Box>
      <Box sx={{ p: 2 }}>
         <Box>
            <Typography variant="h6">
               {text}
            </Typography>
         </Box>
      </Box>
   </>
);

export default DefaultText;
