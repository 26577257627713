export const scrollUp = (val?: number, inside?: boolean) => {
  if (!inside) {
    window.scrollTo({
      top: val || 0,
      behavior: 'smooth'
    });
  }
  // TODO Necessary better solution
  if (document.getElementById('main_wrapper')) {
    // @ts-ignore
    document.getElementById('main_wrapper').scrollTo({ top: 0, behavior: 'smooth' });
  }
};
