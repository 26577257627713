import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { NewsSites } from '../../../../constants/newsSites';
import { useStyles } from './styles';

interface IParams {
  title: string;
  image: string;
  path?: string;
  description: string;
  siteId?: number;
  eguid?: string;
  isActive: boolean;
}

const JobListingItem = ({
  title,
  image,
  description,
  isActive,
  path,
  siteId,
  eguid,
}: IParams) => {
  const classes = useStyles();

  const getToUrl = () => {
    const site = NewsSites[siteId]?.toLowerCase() ?? '';

    if (isActive) {
      if (!site || siteId === NewsSites.Bevnet) return `https://www.bevnet.com/jobs/${path}`;
      return `https://www.${site}.com/jobs/${path}`;
    }

    return `https://jobs.bevnet.com/jobs/editjob${site}?path=${eguid}`;
  };

  return (
    <Card className={classes.itemCard}>
      <Box className={classes.itemCardContent}>
        {image && (
          <div className={classes.itemCardImageWrapper}>
            <CardMedia
              component="img"
              sx={{ height: 140 }}
              image={image}
              className={classes.itemCardImage}
            />
          </div>
        )}
        <CardContent className={!isActive ? classes.inactiveCardContent : ''}>
          <a
            href={`${getToUrl()}`}
            target="_blank"
            rel="noreferrer"
          >
            <Typography
              color="textSecondary"
              variant="subtitle1"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </a>
          {description && (
            <Typography
              variant="body2"
              marginBottom="1rem"
            >
              {description}
            </Typography>
          )}
        </CardContent>
      </Box>
    </Card>
  );
};

export default JobListingItem;
