import React from 'react';
import { Chip, Typography } from '@mui/material';
import { FilterTypesInternal } from '../intyerface';
import { useStyles } from '../styles';

interface IEditProductChipsProps {
  categories: { [key in FilterTypesInternal]: boolean },
  setCategories: (val: { [key in FilterTypesInternal]: boolean }) => void;
  activeSubBrand: string,
  onDeleteActiveSubBrandFilter: () => void
}
export const EditProductChips = ({ categories, setCategories, activeSubBrand, onDeleteActiveSubBrandFilter }:IEditProductChipsProps) => {
  const classes = useStyles();
  const handleDelete = (name: FilterTypesInternal) => {
    setCategories({
      ...categories,
      [name]: false
    });
  };
  const { active, discontinued } = categories;

  return (
    <div className={classes.chips_wrapper}>
      {!active && !discontinued && (
      <Typography
        color="textSecondary"
        variant="subtitle2"
      >
        No filters applied
      </Typography>
      )}

      {Object.keys(categories).map((item) => {
        if (categories[item as FilterTypesInternal]) {
          return (
            <Chip
              key={item}
              variant="outlined"
              label={`Category: ${item}`}
              onDelete={() => handleDelete(item as FilterTypesInternal)}
            />
          );
        }
        return null;
      })}

      {activeSubBrand && (
      <Chip
        variant="outlined"
        label={`Sub Brand: ${activeSubBrand}`}
        onDelete={onDeleteActiveSubBrandFilter}
      />
      )}
    </div>
  );
};
