import { makeStyles, Theme } from '@material-ui/core';
import { mixin } from '../../utils/mixin';

export const useStyles = makeStyles((theme: Theme) => ({
  item: {
    textTransform: 'capitalize',
  },
  my_grid: {
    '@media(max-width: 960px)': {
      display: 'block',
      '& > div': {
        padding: '12px 0 !important'
      }
    },

  },
  userInitials: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    background: '#f4511e',
    ...mixin.flex('center', 'center'),
    marginBottom: '1rem',
    '& h5': {
      ...mixin.fontText(5, 5, 500, '#fff'),
      textTransform: 'uppercase'
    }
  },
  wrapper_for_right_side: {
    position: 'relative'
  },
  wrapper_for_head: {
    width: '100%',
    ...mixin.flex('center', 'space-between')
  },
  avatar: {
    width: '100%',
    height: '100%',
    background: '#f4511e',
    fontSize: '80px'
  },
  plug: {
    color: '#6089e5'
  },
  btn_edit_public_profile: {
    margin: '2.5rem auto 0'
  },
  wrapper_for_avatar: {
    padding: '.4rem',
    width: '9rem',
    height: '9rem',
    borderRadius: '50%',
    margin: '0 auto 1rem',
    border: `1px dashed ${theme.palette.divider}`
  },
  public_side: {
    textAlign: 'center',
    position: 'relative'
  },
  linkStyle: {
    color: '#fff',
    textTransform: 'none',
    textDecoration: 'none'
  },
  loader_for_left_side: {
    '& ~ *': {
      transition: '.8s',
      filter: 'blur(3px)',
      transform: 'scale(0.96)',
      pointerEvents: 'none'
    }
  }
}));
