export interface IUserProfileData {
  uguid: string | null,
  email: string | null,
  firstName?: string | null,
  lastName?: string | null,
  company?: string | null,
  title?: string | null,
  phone?: string | null,
  mobilePhone?: string | null,
  country?: string | null,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  province?: string | null,
  zip?: string | null,
  headshot?: string | null,
  linkedIn?: string | null,
  publicBio?: string | null,
}

export interface IUserOrders{
  date: string | null,
  orderId: string | null,
  guid?: string | null,
  companyName: string | null,
  total: string | null,
}

export interface IUserProfileReducer {
  data:IUserProfileData,
  orders: IUserOrders[],
  error?: any,
  passwordChanged: boolean,
  temporaryUserData: IUserProfileData | null
}
export const cleanUserData:IUserProfileData = {
  uguid: null,
  email: null,
  firstName: null,
  lastName: null,
  company: null,
  title: null,
  phone: null,
  mobilePhone: null,
  country: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  province: null,
  zip: null,
  headshot: null,
  linkedIn: null,
  publicBio: null
};

export const userProfileInitialState:IUserProfileReducer = {
  data: {
    ...cleanUserData
  },
  temporaryUserData: null,
  passwordChanged: false,
  orders: []
};
