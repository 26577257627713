import React from 'react';
import clsx from 'clsx';

import { useStyles } from '../styles';
import { IToggleLeftSideButton } from '../interface';

export const MenuButton = ({ toggleLeftSide, isShowLeftSide, dispatch }:IToggleLeftSideButton) => {
  const classes = useStyles();
  const toggle = () => {
    dispatch(toggleLeftSide(!isShowLeftSide));
  };

  return (
     <button
        type="button"
        className={clsx(classes.menu_button, {
          [classes.active]: isShowLeftSide
        })}
        onClick={toggle}
     >
        <span />
        <span />
        <span />
        <div className={classes.menu_arrow} />
     </button>
  );
};
