import { makeStyles } from '@material-ui/core';
import { mixin } from '../../utils/mixin';

const forTriangle: number = 5;
export const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    maxWidth: '70rem',
    '& th': {
      fontWeight: '600 !important'
    }
  },
  page_head: {
    maxWidth: '70rem',
  },
  pagination_wrapper: {
    marginTop: 40,
    '& ul': {
      // border: '1px solid red',
      padding: '0 1rem 2rem 0',
      ...mixin.flex('center', 'flex-end')
    }
  },
  table_search: {
    padding: '35px 25px',
    '@media(max-width: 768px)': {
      padding: '19px 10px',
    }
  },
  table_head: {
    background: 'rgb(243, 244, 246)',
    '& *': {
      fontWeight: '600 !important'
    },
    '& th': {
      whiteSpace: 'nowrap'
    }
  },
  link: {
    textDecoration: 'underline'
  },
  link_wrapper: {
    border: 0
  },
  even: {
    '& tr:nth-child(even)': {
      background: 'rgba(0, 0, 0, 0.025)',
    }
  },
  odd: {
    '& tr:nth-child(odd)': {
      background: 'rgba(0, 0, 0, 0.04)',
    }
  },
  selectedRow: {
    background: 'rgba(0, 0, 0, 0.09)',
  },
  td_equal: {
    borderBottom: '1px solid #fff',
    '& $td_collapse': {
      padding: 0
    }
  },
  table_empty: {
    padding: 15,
    minHeight: 140,
    ...mixin.flex('center', 'center')
  },
  table_body: {
    overflow: 'hidden',
    position: 'relative'
  },
  pagination_empty: {
    padding: 0
  },
  table_cell: {
    '@media(max-width: 550px)': {
      fontSize: '0.71rem !important'
    }
  },
  table_footer_mobile: {
    background: '#d1d1f0'
  },
  pointer: {
    cursor: 'pointer'
  },
  open_row_collapse: {
    background: 'rgba(0, 0, 0, 0.04) !important',
    '& > td:first-child': {
      '&:before': {
        transform: 'rotate(90deg)'
      }
    },
    '& + $row_collapse': {
      display: 'table-row !important',
    }
  },
  internal_wrapper_cell: {},
  collapse_mode: {
    transition: '.4s',
    '& > td:first-child': {
      position: 'relative',
      paddingLeft: 10,
      '&:before': {
        transition: '.4s',
        content: '""',
        marginRight: 10,
        display: 'inline-block',
        borderTop: `${forTriangle}px solid transparent`,
        borderLeft: '10px solid #ff5a00',
        borderBottom: `${forTriangle}px solid transparent`,
        ...mixin.whSize(0, 0)
      }
    }
  },
  row_collapse: {
    background: 'rgba(0, 0, 0, 0.04) !important',
    display: 'none !important',
    cursor: 'default',
    '& $internal_wrapper_cell': {
      padding: '20px 40px'
    }
  },
  td_collapse: {},
  mobile_collapse_panel: {
    position: 'relative',
    zIndex: 2,
    transition: '.4s',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    transform: 'scale(.3)',
    opacity: 0,
    pointerEvents: 'none',
    display: 'block',
    borderRadius: 5,
    padding: 32,
    height: '100%',
    width: '100%',
    ...mixin.bg('#f8f9fc'),
    ...mixin.br('#292929'),
    '&$active': {
      opacity: 1,
      margin: '0 0 8px',
      pointerEvents: 'all',
      transform: 'scale(1)'
    }
  },
  close: {
    cursor: 'pointer',
    display: 'block !important',
    width: '1.5rem !important',
    height: '1.5rem !important',
    marginLeft: 'auto',
    position: 'absolute',
    top: 15,
    right: 10,
  },
  active: {},
  value_green: {
    color: 'green'
  },
  value_red: {
    color: 'red'
  },

}));
