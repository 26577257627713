import React from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface IStep1RowOptionsProps {
  edit: () => void,
  remove: () => void,
}
const Step1RowOptions = ({ edit, remove }:IStep1RowOptionsProps) => (
  <div>
    <IconButton
      aria-label="edit"
      color="success"
      onClick={() => edit()}
    >
      <EditIcon />
    </IconButton>
    <IconButton
      aria-label="remove"
      color="error"
      onClick={() => remove()}
    >
      <DeleteIcon />
    </IconButton>
  </div>
);

export default Step1RowOptions;
