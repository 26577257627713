import React, { memo, ReactNode } from 'react';
import { Button } from '@mui/material';
import { useStyles } from '../styles';
import { ArrowRight as ArrowRightIcon } from '../../../components/Icons/arrow-right';

interface IEventsStepProps {
  onNext?: () => void;
  onBack?: () => void;
}

interface IJobsLineButtonsProps extends IEventsStepProps {
  nextDisabled: boolean,
  textNextStep?: string,
  textPreviousStep?: string,
  nextButton?: ReactNode,
  prevButton?: ReactNode,
}
const StepsLineButtons = ({
  onNext,
  onBack,
  nextButton,
  prevButton,
  nextDisabled = true,
  textPreviousStep = 'Previous step',
  textNextStep = 'Next step'
}:IJobsLineButtonsProps) => {
  const classes = useStyles();
  return (
     <div className={classes.jobs_line_buttons}>

        {nextButton && nextButton}
        {!nextButton && (
        <Button
           endIcon={(<ArrowRightIcon fontSize="small" />)}
           onClick={onNext}
           variant="contained"
           disabled={nextDisabled}
           data-cy="nextStep"
        >
           {textNextStep}
        </Button>
        )}

        {onBack && !prevButton && (
        <Button
           className={classes.previous_btn}
           onClick={onBack}
           sx={{ ml: 2 }}
           data-cy="backStep"
        >
           {textPreviousStep}
        </Button>
        )}

        {prevButton && prevButton}
     </div>
  );
};

export default memo(StepsLineButtons);
