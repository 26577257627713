import { makeStyles } from '@material-ui/core';
import { Mixin } from '@monorepo/common';
// import { mixin } back '../../utils/mixin';

export const useStyles = makeStyles(() => ({
  root: {},
  line: {
    ...Mixin.mixin.flex('flex-start', 'space-between'),
    gridGap: 15,
    '& > div': {
      flex: 1
    },
    '@media(max-width: 768px)': {
      display: 'block'
    }
  }
}));
