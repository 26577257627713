import React from 'react';
import clsx from 'clsx';

import ErrorBoundary from '../../../containers/ErrorBoundary';
import { IChildrenProps } from '../../../interfaces';
import { useStyles } from '../styles';

interface IFormAutocompleteWrapperProps extends IChildrenProps {
  hideButtonClear: boolean;
  classNameForWrap?: string;
  dataCy?: string;
}
const FormAutocompleteWrapper = ({ children, classNameForWrap, hideButtonClear, dataCy }:IFormAutocompleteWrapperProps) => {
  const classes = useStyles();
  return (
     <ErrorBoundary>
        <div
           {...(dataCy) && { 'data-cy': dataCy }}
           className={clsx(classes.field_wrapper, classNameForWrap, {
             [classes.hideClearButton]: hideButtonClear
           })}
        >
           {children}
        </div>
     </ErrorBoundary>
  );
};

export default FormAutocompleteWrapper;
