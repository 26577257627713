import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

interface ICardWrapperTitleProps {
  title?: string
}
const CardWrapperTitle = ({ title }:ICardWrapperTitleProps) => {
  if (!title) {
    return null;
  }
  return (
     <HelmetProvider>
        {title && (
        <Helmet>
           <title>
              {title}
              {' '}
              | BevNET Admin
           </title>
        </Helmet>
        )}
     </HelmetProvider>
  );
};

export default CardWrapperTitle;
