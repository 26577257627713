interface IBook {
  user: string;
  userAccount: string;
  userUpdate: string;
  userPublicProfile: string;
  userOrders: string;
  needHelp: string;
  userChangePassword: string;
  confirmEmail: string;
  discordVerify: string;
  newsletter: string;
  jobs: {
    jobsListing: string;
    jobsPrepaid: string;
    jobsPrepaidDetail: (id?: string) => string,
    jobsApplicants: (id?: string) => string,
    jobsApplicantDetail: (eguid?: string, applicationId?: string) => string,
  },
  brands: {
    brandOptions: (id?: string) => string,
    editBrand: (brandGuid?: string) => string,
    uploadLogo: (brandGuid?: string) => string,
    createSubBrand: (brandGuid?: string) => string,
    editProducts: (brandGuid?: string) => string,
    createProduct: (brandGuid?: string) => string,
    editProductInfo: (brandGuid?: string, pguid?: string) => string,
    uploadProductPhotos: (brandGuid?: string, pguid?: string) => string,
  },
  magazine: {
    subscribe: string,
    renew: (guid:string) => string
  },
  invoices: {
    invoice: string,
    confirmEmail: string,
    invoiceCompleted: string
  },
  pressReleases: {
    pressReleases: string,
    upgrade: (guid?: string) => string,
    upgradeSuccess: string
  },
  submit: {
    pressReleases: {
      create: string
    },
  }
}
// /UpdateReview/EditBrand/

export const book: IBook = Object.freeze({
  user: '/user',
  userAccount: '/user/account',
  userUpdate: '/user/update',
  userPublicProfile: '/user/publicprofile',
  userOrders: '/user/orders',
  needHelp: '/help',
  userChangePassword: '/user/changepassword',
  confirmEmail: '/user/confirm-email',
  discordVerify: '/discord-verify',
  newsletter: '/user/newsletter',
  jobs: {
    jobsListing: '/user/jobslistings',
    jobsPrepaid: '/user/jobsprepaid',
    jobsPrepaidDetail: (id:string) => (id ? `/user/jobsprepaid/${id}` : '/user/jobsprepaid/:id'),
    jobsApplicants: (id:string) => (id ? `/user/jobsApplicants/${id}` : '/user/jobsApplicants/:id'),
    jobsApplicantDetail: (eguid: string, applicationId: string) => (eguid && applicationId ? `/user/jobsApplicantDetail/${eguid}/${applicationId}` : '/user/jobsApplicantDetail/:eguid/:applicationId'),
  },
  brands: {
    brandOptions: (id:string) => (id ? `/updatereview/${id}` : '/updatereview/:brandGuid'),
    editBrand: (id:string) => (id ? `/updatereview/EditBrand/${id}` : '/updatereview/EditBrand/:brandGuid'),
    uploadLogo: (id:string) => (id ? `/updatereview/UploadLogo/${id}` : '/updatereview/UploadLogo/:brandGuid'),
    editProducts: (id:string) => (id ? `/updatereview/EditProducts/${id}` : '/updatereview/EditProducts/:brandGuid'),
    createProduct: (id:string) => (id ? `/updatereview/CreateProduct/${id}` : '/updatereview/CreateProduct/:brandGuid'),
    createSubBrand: (id:string) => (id ? `/updatereview/CreateSubBrand/${id}` : '/updatereview/CreateSubBrand/:brandGuid'),
    editProductInfo: (bguid:string, pguid:string) => (bguid && pguid ? `/updatereview/editProductInfo/${bguid}/${pguid}` : '/updatereview/editProductInfo/:brandGuid/:productGuid'),
    uploadProductPhotos: (bguid:string, pguid:string) => (bguid && pguid ? `/updatereview/UploadProductPhotos/${bguid}/${pguid}` : '/updatereview/UploadProductPhotos/:brandGuid/:productGuid'),
  },
  magazine: {
    subscribe: 'https://magazine.bevnet.com/subscribe',
    renew: (guid:string) => (`https://magazine.bevnet.com/renew/${guid}`)
  },
  invoices: {
    invoice: '/invoice',
    confirmEmail: '/invoice/confirmEmail',
    invoiceCompleted: '/invoice/completed'
  },
  pressReleases: {
    pressReleases: '/user/press-releases',
    upgrade: (guid: string) => (guid ? `/user/press-releases/upgrade/${guid}` : '/user/press-releases/upgrade/:guid'),
    upgradeSuccess: '/user/press-releases/upgrade/:guid/success'
  },
  submit: {
    pressReleases: {
      create: 'https://submit.bevnet.com/news'
    },
  }
});
