import { Btn, CardWrapper } from '@monorepo/common';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import EmptyTable from '../../../components/EmptyTable';
import { book } from '../../../routers/books';
import { useGetJobsPrepaidDetailQuery } from '../../../services/jobs/index';
import { onEditAndCopyJobListingHandler, onPostJobPrepaidListingHandler, onReactivateJobListingHandler } from '../Common/common';
import JobListingItem from '../Common/components/JobListingItem';
import { useStyles } from './styles';

interface IParams {
  id: string;
}

export const JobsPrepaidDetail = () => {
  const { id } = useParams<IParams>();
  const [title, setTitle] = useState('Prepaid Job Listings Package');
  const history = useHistory();
  const classes = useStyles();

  const { data, isLoading } = useGetJobsPrepaidDetailQuery(id);

  useEffect(() => {
    if (data) {
      setTitle(
        `Prepaid Job Listings Package: #${data.prepaidId} - ${data.company}`
      );
    }
  }, [data]);

  const onViewApplicants = (adEguid: string) => {
    history.push(book.jobs.jobsApplicants(adEguid), { from: 'jobsPrepaidDetail' });
  };

  return (
    <CardWrapper
      helmet="Prepaid Job Detail"
      maxWidth="80rem"
      outsideHead={{
        title,
        breadcrumbs: [
          {
            title: 'Prepaid Job Packages',
            link: book.jobs.jobsPrepaid,
          },
          {
            title,
          },
        ],
        childrenTitleRight: data && data.isCurrent ? (
          <Btn click={() => onPostJobPrepaidListingHandler(data.siteId, data.prepaidGuid)}>Post Job Listing</Btn>
        ) : null,
      }}
      isLoading={isLoading}
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell width={200}>
              <Typography variant="body2">Total Listings Included</Typography>
            </TableCell>

            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {data?.totalCount}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={200}>
              <Typography variant="body2">Remaining Job Listings</Typography>
            </TableCell>

            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {data?.remainingCount}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={200}>
              <Typography variant="body2">Purchase Date</Typography>
            </TableCell>

            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {data
                  && data.purchaseDate
                  && moment(data.purchaseDate).format('MM/DD/YYYY')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell width={200}>
              <Typography variant="body2">Expiration Date</Typography>
            </TableCell>

            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {data
                  && data.expirationDate
                  && moment(data.expirationDate).format('MM/DD/YYYY')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <div className={classes.wrapper_for_table}>
        <Typography
          color="textPrimary"
          variant="h6"
          className={classes.subtitle}
        >
          Current Prepaid Job Packages
        </Typography>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Job Listing</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Expires</TableCell>
                <TableCell>Total Applicants</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.activeJobs && data.activeJobs.length ? (
                data.activeJobs.map((item: any) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <JobListingItem
                        key={item.id}
                        description={item.shortDescription}
                        image={item.image}
                        title={item.title}
                        isActive
                        path={item.path}
                      />
                    </TableCell>
                    <TableCell>{item.location}</TableCell>
                    <TableCell>
                      {moment(item.dated).format('MM/DD/YYYY') || '-'}
                    </TableCell>
                    <TableCell>{item.applicantCount}</TableCell>
                    <TableCell>
                      <div className={classes.actions}>
                        <Btn
                          type="button"
                          click={() => onViewApplicants(item.eguid)}
                        >
                          Applicants
                        </Btn>
                        <Btn
                          type="button"
                          click={() => onEditAndCopyJobListingHandler(data.siteId, item.eguid)}
                        >
                          Edit
                        </Btn>
                        <Btn
                          type="button"
                          click={() => onEditAndCopyJobListingHandler(data.siteId, item.eguid)}
                        >
                          Copy and Create New Listing
                        </Btn>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <EmptyTable colSpan={5} />
              )}
            </TableBody>
          </Table>
        </Box>
      </div>

      <div className={classes.wrapper_for_table}>
        <Typography
          color="textPrimary"
          variant="h6"
          className={classes.subtitle}
        >
          Inactive Listings from this Package
        </Typography>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Job Listing</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Expires</TableCell>
                <TableCell>Total Applicants</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.inactiveJobs && data.inactiveJobs.length ? (
                data.inactiveJobs.map((item: any) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <JobListingItem
                        key={item.id}
                        description={item.shortDescription}
                        image={item.image}
                        title={item.title}
                        eguid={item.eguid}
                        isActive={false}
                      />
                    </TableCell>
                    <TableCell>{item.location}</TableCell>
                    <TableCell>
                      {moment(item.dated).format('MM/DD/YYYY') || '-'}
                    </TableCell>
                    <TableCell>{item.applicantCount}</TableCell>
                    <TableCell>
                      <div className={classes.actions}>
                        <Btn
                          type="button"
                          click={() => onViewApplicants(item.eguid)}
                        >
                          Applicants
                        </Btn>
                        <Btn
                          type="button"
                          click={() => onReactivateJobListingHandler(data.siteId, item.eguid)}
                        >
                          Reactivate
                        </Btn>
                        <Btn
                          type="button"
                          click={() => onEditAndCopyJobListingHandler(data.siteId, item.eguid)}
                        >
                          Copy and Create New Listing
                        </Btn>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <EmptyTable colSpan={5} />
              )}
            </TableBody>
          </Table>
        </Box>
      </div>
    </CardWrapper>
  );
};

export default memo(JobsPrepaidDetail);
