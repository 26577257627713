import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface IProps{
  open: boolean,
  setOpen: any,
  setConfirm: any,
  confirmText?: string,
  confirmTitle?: string,
  yesButtonText?: string,
  noButtonText?: string
}

const ConfirmDialog = ({ open, setOpen, setConfirm, confirmText, confirmTitle, yesButtonText = 'Yes', noButtonText = 'No' }: IProps) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleAgreeClose = () => {
    setOpen(false);
    setConfirm(true);
  };

  return (
     <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
     >
        <DialogTitle id="alert-dialog-title">
           {confirmTitle}
        </DialogTitle>
        <DialogContent>
           <DialogContentText id="alert-dialog-description">
              {confirmText}
           </DialogContentText>
        </DialogContent>
        <DialogActions>
           <Button onClick={handleClose}>{noButtonText}</Button>
           <Button
              onClick={handleAgreeClose}
              autoFocus
           >
              {yesButtonText}
           </Button>
        </DialogActions>
     </Dialog>
  );
};

export default ConfirmDialog;
