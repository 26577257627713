export interface INotificationReducer {
  type: 'success' | 'error' | 'warning' | 'info',
  text: string,
  show?: boolean
}
export const notificationInitialState:INotificationReducer = {
  type: 'info',
  text: '',
  show: false
};
