import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { Btn, CardWrapper, FormField, GlobalInterfaces, scrollUp, useHookForm } from '@monorepo/common';

import { IBrandInfo } from '../../../services/serviceBrands/serviceBrandsInterface';
import { useEditBrandInfoMutation, useGetBrandInfoQuery } from '../../../services/serviceBrands';
import { customButtonStyles } from '../../../constants/customButtonStyles';
import { showNotification } from '../../../features/sliceNotification';
import { useAccessStatus } from '../../../hook/useAccessStatus';
import { book } from '../../../routers/books';
import { schema } from './schema';

import { useStyles } from './styles';
import ContactOptionsSelector, { ContactOptions } from './components/ContactOptionsSelector';

// interface IEditBrandProps {}
const EditBrand = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoadingAccessStatus, noAccessComponent, brandGuid, hideContent, isRequest } = useAccessStatus();
  const { data: brandInfo, isLoading } = useGetBrandInfoQuery({ brandGuid }, {
    skip: !isRequest
  });

  const [contactOption, setContactOption] = useState<ContactOptions>(ContactOptions.EMAIL);

  const {
    errors,
    setError,
    register,
    handleSubmit,
    setFormData
  } = useHookForm<IBrandInfo>({
    schema
  });

  const [editBrandInfo, { isLoading: isEditing, isSuccess, error }] = useEditBrandInfoMutation();

  const onSubmit = (dataForm:IBrandInfo) => {
    delete dataForm.brandImageUrl;
    delete dataForm.selfService;
    delete dataForm.authLevel;
    delete dataForm.brandTitle;
    delete dataForm.brandName;

    editBrandInfo({
      brandGuid,
      body: dataForm
    });
  };

  useEffect(() => {
    if (brandInfo) {
      setFormData(brandInfo);
      setContactOption(ContactOptions.URL);
    }
    if (isSuccess && brandInfo) {
      if (brandInfo.authLevel === 2) {
        scrollUp();
        dispatch(showNotification({
          show: true,
          type: 'success',
          text: `Brand "${brandInfo?.brandName}" was edited`
        }));
        return;
      }
      scrollUp();
      dispatch(showNotification({
        show: true,
        type: 'info',
        text: 'Thank you for your update.  The BevNET brand listing has been updated.'
      }));
    }
  }, [isSuccess, scrollUp, brandInfo]);

  useEffect(() => {
    if (error) {
      const errContent = error as GlobalInterfaces.IErrorProps;
      const field = Object.keys(errContent.data.errors);
      // @ts-ignore
      setError(field[0].toLowerCase() as keyof IBrandInfo, errContent.data.errors[field[0]]);
      console.log(errContent.data.errors);
    }
  }, [error]);

  return (
    <CardWrapper
      isHideChildren={hideContent}
      noAccessComponent={noAccessComponent}
      isLoading={isLoading || isEditing || isLoadingAccessStatus}
      titleHelmet={noAccessComponent ? 'No Access' : `Edit Brand ${brandInfo?.brandName}`}
      outsideHead={{
        breadcrumbs: [
          {
            title: `${brandInfo?.brandName}`,
            link: book.brands.brandOptions(brandGuid)
          },
          {
            title: 'Edit Brand'
          }
        ]
      }}
    >
      <Typography
        variant="body1"
        marginBottom={2}
      >
        Below you can certain information that is displayed on the brand page for the above product.
      </Typography>

      <Typography
        variant="h6"
        marginTop={5}
      >
        Brand Name
      </Typography>

      <Typography
        variant="body1"
        marginBottom={3}
      >
        Your brand will be listed using the name below.  Please contact us at hello@bevnet.com if you would like to change this name.
        {'\n'}
        <b>{brandInfo?.brandName}</b>
      </Typography>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.root}
      >
        <>
          <FormField
            isRequired
            errors={errors}
            label="Company name"
            extraProps={{ ...register('company') }}
            fieldComment="The company name listed on the review page."
          />
          <FormField
            errors={errors}
            label="Phone number"
            extraProps={{ ...register('phone') }}
            fieldComment="Phone number listed on the review page."
          />
        </>

        <>
          <Typography
            variant="h6"
            marginTop={6}
            marginBottom={2}
          >
            Email contact info
          </Typography>

          <ContactOptionsSelector
            value={contactOption}
            setValue={setContactOption}
          />

          {contactOption === ContactOptions.EMAIL && (
          <FormField
            type="textarea"
            errors={errors}
            label="Email Addresses "
            extraProps={{ ...register('email') }}
            fieldComment="All correspondence from site visitors will be sent to these addresses. Addresses will NOT be publicly displayed. NOTE: Please separate email addresses with a comma."
          />
          )}

          {contactOption === ContactOptions.URL && (
          <FormField
            errors={errors}
            label="Contact Form URL"
            extraProps={{ ...register('contactUrl') }}
            fieldComment="Enter the URL of a contact form on your website which our site visitors can be directed to."
          />
          )}

        </>

        <>
          <Typography
            variant="h6"
            marginTop={6}
            marginBottom={2}
          >
            Brand overview
          </Typography>

          <FormField
            type="textarea"
            errors={errors}
            extraProps={{ ...register('overview') }}
            fieldComment="Displayed at the top of the review page. This can be used to describe your line of products as well as your company in your own words."
          />
        </>

        <>
          <Typography
            variant="h6"
            marginTop={6}
            marginBottom={2}
          >
            Social media
          </Typography>
          <FormField
            errors={errors}
            label="Website"
            extraProps={{ ...register('website') }}
            fieldComment="Displayed at the top of the review page."
          />
          <FormField
            errors={errors}
            label="Facebook page"
            extraProps={{ ...register('facebook') }}
            fieldComment="If your brand has a page on Facebook.com, enter the URL below. A link will be displayed on the brand page."
            placeholder="https://www.facebook.com/bevnetcom"
          />

          <Typography
            color="textSecondary"
            variant="body1"
            marginBottom={2}
          >
            If your brand has a Twitter or Instagram account, enter the account name below.
          </Typography>

          <div className={classes.line}>
            <FormField
              errors={errors}
              label="Twitter"
              extraProps={{ ...register('twitter') }}
              placeholder="@BevNET"
            />
            <FormField
              errors={errors}
              label="Instagram"
              extraProps={{ ...register('instagram') }}
              placeholder="BevNET"
            />
          </div>
        </>

        <Btn
          type="submit"
          customStyles={customButtonStyles}
        >
          Update brand
        </Btn>
      </form>
    </CardWrapper>

  );
};

export default EditBrand;
