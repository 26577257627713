import React from 'react';
import { Box, Typography } from '@mui/material';
import { IUploadAreaSettingsProps } from '../interface';

interface IDefaultTextProps extends IUploadAreaSettingsProps {}
const DefaultText = ({ uploadAreaSettings }:IDefaultTextProps) => {
  if (uploadAreaSettings) {
    return (
       <>
          <Box
             sx={{
               '& img': {
                 width: 100
               }
             }}
          >
             {typeof uploadAreaSettings?.image === 'string' ? (
                <img
                   src={uploadAreaSettings?.image}
                   alt=""
                />
             ) : uploadAreaSettings?.image}

          </Box>
          <Box sx={{ p: 2 }}>
             {uploadAreaSettings?.mainTitle ? (
                <Typography variant="h6">
                   {uploadAreaSettings?.mainTitle}
                </Typography>
             ) : null}

             {uploadAreaSettings.mainSubtitleTitle && (
             <Box sx={{ mt: uploadAreaSettings?.mainTitle ? 2 : 0 }}>
                {uploadAreaSettings.mainSubtitleTitle}
             </Box>
             )}
          </Box>
       </>
    );
  }
  return null;
};

export default DefaultText;
