import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Card } from '@material-ui/core';

import { ICardWrapperInternalWrapperProps } from '../interfaceCardWrapper';

import { scrollUp } from '../../../utils/scrollUp';

import { useStyles } from '../styles';

const CardWrapperInternalWrapper = ({
  noAccessComponent,
  align,
  isHideChildren = false,
  internalStyles,
  isPaddingTop = false,
  isNoPadding = false,
  classNameForInternalBlock,
  classnameForWrapper,
  lateralPadding,
  centerText,
  noOverflow,
  fullWidth,
  outsideHead,
  scrollOptions,
  children,
  dataCy,
  onClick
}:ICardWrapperInternalWrapperProps) => {
  const classes = useStyles();
  const boxRef = useRef<HTMLDivElement>();
  const [isScrollUp, setIsScrollUp] = useState<boolean>(false);

  useEffect(() => {
    if (boxRef.current && scrollOptions) {
      if (scrollOptions?.isErrorsAutoScrollPage && Object.keys(scrollOptions.isErrorsAutoScrollPage).length) {
        scrollUp(+boxRef.current.offsetTop - 70);
      }
      if (scrollOptions.getWrapperOffsetTop) {
        scrollOptions.getWrapperOffsetTop(boxRef.current.offsetTop);
      }
      if (scrollOptions.isScrollToStart && !isScrollUp) {
        setIsScrollUp(true);
        scrollUp(+boxRef.current.offsetTop - 70);
        const timeout = setTimeout(() => {
          setIsScrollUp(false);
          clearTimeout(timeout);
        }, 0);
      }
    }
  }, [scrollOptions, boxRef, scrollUp]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (boxRef.current) {
        scrollUp(+boxRef.current.offsetTop);
      }
      clearTimeout(timeout);
    }, 100);
  }, []);

  if (isHideChildren) {
    // -------------!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!------------------------ For component NO ACCESS!!!!
    if (noAccessComponent) {
      return (
         <div className={clsx(classes.wrapper, classnameForWrapper)}>
            {outsideHead}
            <Card
               onClick={onClick}
               className={clsx(
                 classes.internal_block,
                 classes.internal_block_padding_top,
                 classNameForInternalBlock,
                 {
                   [classes.center]: centerText,
                   [classes.noOverflow]: noOverflow,
                   [classes.fullWidth]: fullWidth,
                   [classes.lateral_padding]: lateralPadding,
                   [classes.no_padding]: isNoPadding,
                   [classes.left]: align === 'left',
                   [classes.right]: align === 'right',
                 }
               )}
               {...internalStyles}
            >
               {noAccessComponent}
            </Card>
         </div>
      );
    }
    return null;
  }
  return (
     <div
        {...(dataCy) && { 'data-cy': dataCy }}
         // @ts-ignore
        ref={boxRef}
        className={clsx(classes.wrapper, classnameForWrapper)}
     >
        {outsideHead}
        <Card
           onClick={onClick}
           className={clsx(
             classes.internal_block,
             classNameForInternalBlock,
             {
               [classes.center]: centerText,
               [classes.noOverflow]: noOverflow,
               [classes.fullWidth]: fullWidth,
               [classes.lateral_padding]: lateralPadding,
               [classes.internal_block_padding_top]: isPaddingTop,
               [classes.left]: align === 'left',
               [classes.right]: align === 'right',
               [classes.no_padding]: isNoPadding
             }
           )}
           {...internalStyles}
        >
           {children}
        </Card>
     </div>
  );
};

export default CardWrapperInternalWrapper;
