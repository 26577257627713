import React, { memo } from 'react';

import ErrorBoundary from '../ErrorBoundary';
import Loader from '../../components/Loader/Loader';
import { IBasicLayoutProps } from './interfaceBasicLayout';
import { BasicLayoutPageTitle } from './components/BasicLayoutPageTitle';
import { BasicLayoutModalWindow } from './components/BasicLayoutModalWindow';
import BreadcrumbsPanel from '../../components/BreadcrumbsPanel/BreadcrumbsPanel';
import { useStyles } from './styles';

const BasicLayout = ({
  isLoading,
  helmet,
  modalWindowOptions,
  children,
  isHideChildren,
  noAccessComponent,
  hiddenContentWhenIsLoading,
  breadcrumbs = [],
  dataCy
}:IBasicLayoutProps) => {
  const classes = useStyles();
  if (isHideChildren || hiddenContentWhenIsLoading) {
    return <Loader isShow={hiddenContentWhenIsLoading} />;
  }
  if (noAccessComponent) {
    return (
       <ErrorBoundary>
          {noAccessComponent}
       </ErrorBoundary>
    );
  }

  return (
     <>
        <BasicLayoutPageTitle helmet={helmet} />

        <BasicLayoutModalWindow modalWindowOptions={modalWindowOptions} />

        <Loader isShow={isLoading} />

        {breadcrumbs && (
        <BreadcrumbsPanel
           list={breadcrumbs}
           className={classes.breadcrumbs_panel}
        />
        )}

        <ErrorBoundary>
           {dataCy ? (
              <div data-cy={dataCy}>
                 {!isHideChildren && children}
              </div>
           ) : (
              <>
                 {!isHideChildren && children}
              </>
           )}

        </ErrorBoundary>
     </>
  );
};

export default memo(BasicLayout);
