interface ICustomStylesHover {
  backgroundColor: string,
  color: string,
  border?: string | null,
}
interface ICustomStyles {
  backgroundColor: string,
  color: string,
  border?: string | null,
  hover: ICustomStylesHover
}

export const customButtonStyles:ICustomStyles = {
  backgroundColor: '#fff',
  color: '#000',
  border: '#979797',
  hover: {
    backgroundColor: '#979797',
    color: '#fff',
    border: '#979797',
  }
};
