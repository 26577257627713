import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import endpoints from '../../api/endpoints';
import { IMenuSettingsResponse } from './interface';

export const menuApi = createApi({
  reducerPath: 'menuSettings',
  tagTypes: ['MenuSettings'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getMenuSettings: build.query<IMenuSettingsResponse, void>({
      query: () => ({
        url: endpoints.menuSettings,
      }),
      providesTags: ['MenuSettings']
    }),
  }),
});
export const { useGetMenuSettingsQuery } = menuApi;
