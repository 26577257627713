import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableSortLabel
} from '@mui/material';

import clsx from 'clsx';
import { TableAlignValues } from '../gridInterfaces';
import { useStyles } from '../styles';

interface ITableHeadCellProps<T> {
  align: TableAlignValues,
  width?: string | number,
  className?: string,
  field: keyof T | null,
  sortable?: boolean,
  activeCol: keyof T | null,
  getCurrentCol: (el: keyof T) => void,
  title: React.ReactNode | string | number | 'empty',
  activeColDirection: 'desc' | 'asc' | null,
  hideCurrentSortable?: boolean
}
const TableCustomHeadCell = <T extends unknown>({
  align,
  width,
  field,
  title,
  className,
  activeCol,
  sortable,
  getCurrentCol,
  hideCurrentSortable = false,
  activeColDirection
}:ITableHeadCellProps<T>) => {
  const [cellTitle, setCellTitle] = useState<React.ReactNode | string | number | 'empty'>();
  const classes = useStyles();
  useEffect(() => {
    if (title !== 'empty') {
      setCellTitle(title);
      return;
    }
    setCellTitle('');
  }, [title]);

  return (
     <TableCell
        align={align}
        {...(width) && {
          width
        }}
        className={clsx(classes.table_cell, className)}
     >
        {/* eslint-disable-next-line no-nested-ternary */}
        {(sortable && field) ? (
           <TableSortLabel
              {...(activeColDirection && activeCol) && {
                direction: activeCol === field && activeColDirection === 'desc' ? 'desc' : 'asc'
              }}
              disabled={hideCurrentSortable}
              active={activeCol === field}
              onClick={() => {
                getCurrentCol(field as keyof T);
              }}
           >
              {typeof cellTitle === 'string' ? cellTitle.charAt(0).toUpperCase() + cellTitle.slice(1) : cellTitle }
           </TableSortLabel>
        ) : (typeof cellTitle === 'string' ? cellTitle.charAt(0).toUpperCase() + cellTitle.slice(1) : cellTitle)}
     </TableCell>
  );
};

export default TableCustomHeadCell;
