import React, { ReactChild, ReactNode } from 'react';
import { Alert, Typography } from '@mui/material';
import moment from 'moment';

interface IFinalBlockInfoListProps {
  children: ReactChild | ReactNode
}
const FinalBlockInfoList = ({ children }:IFinalBlockInfoListProps) => (
  <>
    <Typography
      variant="h5"
      fontWeight="bold"
      marginBottom={2}
    >
      Processing Complete...
    </Typography>
    <Alert
      severity="warning"
      style={{ marginBottom: '2rem' }}
    >
      Note: A copy of this order receipt has also been sent to you via email.
    </Alert>
    <Typography
      variant="body1"
      fontWeight="bold"
      marginTop={2}
    >
      Order Date:
      {' '}
      {moment().format('MM/D/YYYY h:mm:ss A')}
    </Typography>

    <Typography
      marginBottom={4}
      variant="body1"
    >
      {children}
    </Typography>

  </>
);

export default FinalBlockInfoList;
