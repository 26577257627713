interface IProps<T> {
  data: T[],
  searchText: string,
  page?: number | null,
  pageSize?: number | null
}
export const filterByText = <T, >({ searchText, data }:IProps<T>) => {
  const result = data.filter((obj:T) => JSON.stringify(Object.values(obj as T)).toLowerCase().includes(searchText.toLowerCase()));
  return {
    result,
    total: result.length
  };
};
