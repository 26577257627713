import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadingsInitialState, ILoadingsReducer } from './loadingsInitialState';

export const slice = createSlice({
  name: 'loadings',
  initialState: loadingsInitialState,
  reducers: {
    addLoading: (state:ILoadingsReducer, action:PayloadAction<string | string[]>): void => {
      if (!Array.isArray(action.payload)) {
        state.loadings.push(action.payload);
        return;
      }
      state.loadings = [
        ...state.loadings,
        ...action.payload
      ];
    },
    removeLoading: (state:ILoadingsReducer, action:PayloadAction<string | string[]>): void => {
      if (!Array.isArray(action.payload)) {
        state.loadings = state.loadings.filter((item:string) => item !== action.payload);
        return;
      }

      state.loadings = state.loadings.filter((item:string) => !action.payload.includes(item));
    }
  },
});
export const { removeLoading, addLoading } = slice.actions;
export default slice.reducer;
