import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import endpoints from '../../api/endpoints';

interface IResponse {
  headshot?: string | null,
  linkedIn?: string,
  publicBio?: string,
  uguid:string,
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IRequest {
  publicBio: string | null,
  linkedIn:string | null,
  headshot:string | null,
  uguid:string,
}

export const publicProfileApi = createApi({
  reducerPath: 'userPublicInfo',
  tagTypes: ['PublicInfo'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    get: build.query<IResponse, any>({
      query: () => ({
        url: endpoints.userPublicProfile,
      }),
      providesTags: ['PublicInfo']
    }),
    updateUserPublicInfo: build.mutation<IResponse, IRequest>({
      query: (body:IRequest) => ({
        url: endpoints.userPublicProfile,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['PublicInfo']
    }),
    uploadHeadShot: build.mutation<string, FormData>({
      query: (body:FormData) => ({
        url: endpoints.userHeadShot,
        method: 'POST',
        body
      }),
    }),
  }),
});

export const {
  useUpdateUserPublicInfoMutation,
  useUploadHeadShotMutation
} = publicProfileApi;
