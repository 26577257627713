import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Btn, CardWrapper, FormField, scrollUp, useHookForm } from '@monorepo/common';

import { useCreateSubBrandMutation, useGetBrandInfoQuery } from '../../../services/serviceBrands';
import { ISubBrandInfo } from '../../../services/serviceBrands/serviceBrandsInterface';
import { customButtonStyles } from '../../../constants/customButtonStyles';
import { showNotification } from '../../../features/sliceNotification';
import { useAccessStatus } from '../../../hook/useAccessStatus';
import { book } from '../../../routers/books';
import { useStyles } from './styles';
import { schema } from './schema';

// interface ICreateSubBrandProps {}
const CreateSubBrand = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { accessLevelNumber, isLoadingAccessStatus, brandGuid, noAccessComponent, hideContent, isRequest } = useAccessStatus();
  const [nameSubBrand, setNameSubBrand] = useState<string | null>(null);
  const { data: brandInfo, isLoading } = useGetBrandInfoQuery({ brandGuid }, {
    skip: !isRequest
  });
  const [createSubBrand, { isSuccess, isLoading: isCreatingSubBrand }] = useCreateSubBrandMutation();
  const {
    reset,
    register,
    handleSubmit,
    errors
  } = useHookForm<ISubBrandInfo>({
    schema
  });

  const onSubmit = (data:ISubBrandInfo) => {
    if (data && accessLevelNumber === 2) {
      setNameSubBrand(data.title);
      createSubBrand({
        body: data,
        brandGuid
      });
    }
  };

  useEffect(() => {
    if (isSuccess && nameSubBrand) {
      reset();
      scrollUp();
      dispatch(showNotification({
        show: true,
        type: 'success',
        text: `Sub brand "${nameSubBrand}" was created!`
      }));
    }
  }, [isSuccess, nameSubBrand, reset]);

  return (
    <CardWrapper
      isHideChildren={hideContent}
      noAccessComponent={noAccessComponent}
      helmet={noAccessComponent ? 'No Access' : `Add New Sub Brand: ${brandInfo?.brandName}`}
      title={noAccessComponent ? 'No Access' : 'Add New Sub Brand'}
      isLoading={isLoading || isCreatingSubBrand || isLoadingAccessStatus}
      outsideHead={{
        breadcrumbs: [
          {
            title: `${brandInfo?.brandName}`,
            link: book.brands.brandOptions(brandGuid)
          },
          {
            title: 'Add New Sub Brand'
          }
        ]
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.root}
      >
        <FormField
          isRequired
          errors={errors}
          label="Title"
          extraProps={{ ...register('title') }}
        />
        <FormField
          type="textarea"
          errors={errors}
          label="Description"
          extraProps={{ ...register('description') }}
        />
        <Btn
          type="submit"
          customStyles={customButtonStyles}
        >
          Save
        </Btn>
      </form>
    </CardWrapper>
  );
};

export default memo(CreateSubBrand);
