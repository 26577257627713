import { useEffect, useState } from 'react';
import { IAutocompleteOption } from '../interfaces';

interface IParams {
  selectOption?: IAutocompleteOption | null,
  countryName?: string | null
}
interface IProvinceProps {
  defaultProvince?: boolean,
  extraOptions?: {
    setValue: any,
    stateFieldName: string,
    provinceFieldName: string
  } | null
}

export const useIsProvince = ({ defaultProvince = false, extraOptions = null }:IProvinceProps) => {
  const [isProvince, setIsProvince] = useState<boolean>(defaultProvince);

  const getData = ({ selectOption, countryName }:IParams) => {
    if (selectOption) {
      if (selectOption.alpha2 === 'US' || selectOption.value === 'United States' || selectOption.value === 'USA') {
        setIsProvince(false);
        return;
      }
      setIsProvince(true);
      return;
    }

    setIsProvince(true);

    if (countryName) {
      // just in case "countryName === 'US'"
      if (countryName === 'US' || countryName === 'United States' || countryName === 'USA') {
        setIsProvince(false);
        return;
      }
      setIsProvince(true);
      return;
    }
    setIsProvince(true);
  };

  useEffect(() => {
    if (extraOptions) {
      if (isProvince) {
        extraOptions.setValue(extraOptions.stateFieldName, '');
        return;
      }
      extraOptions.setValue(extraOptions.provinceFieldName, '');
    }
  }, [extraOptions, isProvince]);

  return {
    isProvince,
    getData
  };
};
