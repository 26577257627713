interface IFormattedRedirectLink {
  to: string
}
export const formattedRedirectLink = ({ to }:IFormattedRedirectLink) => {
  let finalUrl:string = '';
  if (to.indexOf('?') < 0) {
    finalUrl = `${to}?returnUrl=${document.location.href}`;
    return finalUrl;
  }

  finalUrl = `${to}&returnUrl=${document.location.href}`;
  return finalUrl;
};
