import React, { memo } from 'react';
import clsx from 'clsx';

import { MenuItem } from './MenuItem';
import { ParentItem } from './ParentItem';
import { IMenuItem } from '../interface';

import { useStyles } from '../styles';

interface IMenuGroupProps {
  title?: string;
  classNameForTitle?: string;
  classNameForWrapper?: string;
  menuItems: IMenuItem[];
}
export const MenuGroup = memo(
  ({
    menuItems,
    title,
    classNameForTitle,
    classNameForWrapper,
  }: IMenuGroupProps) => {
    const classes = useStyles();

    return (
       <div className={clsx(classes.menu_group, classNameForWrapper)}>
          {title && <h3 className={clsx(classes.menu_group_title, classNameForTitle)}>{title}</h3>}

          {menuItems.map((item) => {
            if (!item.subMenus) {
              return (
                 <MenuItem
                    key={item.href}
                    {...item}
                 />
              );
            }
            return (
               <ParentItem
                  {...item}
                  key={item.title}
               />
            );
          })}
       </div>
    );
  }
);
