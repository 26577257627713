import { createApi } from '@reduxjs/toolkit/dist/query/react';
import endpoints from '../../api/endpoints';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IRequest, IJobListingResponse } from '../../interfaces';

export const jobsApi = createApi({
  reducerPath: 'jobs',
  tagTypes: ['jobs', 'JobsApplicants', 'JobsApplicantDetail'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    fetchJobsPrepaid: build.query({
      query: () => ({
        url: endpoints.jobs.jobsPrepaid,
      }),
    }),
    getJobsPrepaidDetail: build.query({
      query: (id) => ({
        url: endpoints.jobs.jobsPrepaidDetail(id),
      }),
    }),
    getJobsApplicants: build.query({
      query: (id) => ({
        url: endpoints.jobs.jobsApplicants(id),
      }),
      providesTags: ['JobsApplicants'],
    }),
    getJobsApplicantDetail: build.query({
      query: ({ eguid, applicationId }) => ({
        url: endpoints.jobs.jobsApplicantDetail(eguid, applicationId),
      }),
      providesTags: ['JobsApplicantDetail'],
    }),
    markAsReadJobsApplicantion: build.mutation({
      query: ({ applicationId }) => ({
        url: endpoints.jobs.markAsReadJobsApplicantion(applicationId),
        method: 'PUT',
      }),
      invalidatesTags: ['JobsApplicants', 'JobsApplicantDetail'],
    }),
    fetchActiveJobsListing: build.query<
    IJobListingResponse,
    IRequest
    >({
      query: ({ pageSize, page, SortBy, SortDirection, searchText }) => ({
        url: endpoints.jobs.activeJobsListing,
        params: {
          pageSize,
          page,
          SortBy,
          SortDirection,
          searchText,
        },
      }),
    }),
    fetchInactiveJobsListing: build.query<
    IJobListingResponse,
    IRequest
    >({
      query: ({ pageSize, page, SortBy, SortDirection, searchText }) => ({
        url: endpoints.jobs.inactiveJobsListing,
        params: {
          pageSize,
          page,
          SortBy,
          SortDirection,
          searchText,
        },
      }),
    }),
  }),
});

export const {
  useFetchJobsPrepaidQuery,
  useGetJobsPrepaidDetailQuery,
  useGetJobsApplicantsQuery,
  useGetJobsApplicantDetailQuery,
  useMarkAsReadJobsApplicantionMutation,
} = jobsApi;
