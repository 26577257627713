// import { mixin } from '../../utils/mixin';
// '@media(max-width: 768px)': {}
import { makeStyles } from '@material-ui/core';
import { mixin } from '../../utils/mixin';

export const useStyles = makeStyles((theme) => ({
  icon_preview: {
    display: 'block',
    fontSize: '4.8rem',
    fill: '#848484',
    marginRight: '1rem',
  },
  defaultValue: {},
  cancel: {},
  wrapper_image: {
    position: 'relative',
    // overflow: 'hidden',
    // borderRadius: theme.shape.borderRadius,
    maxHeight: 900,
    padding: 1,
    // textAlign: 'center',
    '& img': {
      width: 'auto',
      maxWidth: '100%',
      maxHeight: 900,
      objectPosition: 'left',
      objectFit: 'contain',
      // boxShadow: '0 0 7px 0px #000000b5',
    },
  },
  removeBtn: {
    textTransform: 'capitalize',
    margin: '2rem 0 !important',
  },
  cont: {
    ...mixin.br('#C9C9C9'),
    margin: 0,
    padding: '1.5rem',
    width: '100%',
    ...mixin.flex('center', 'center'),
  },
  preview_file: {
    ...mixin.br(),
    padding: '0 !important',
    ...mixin.flex('center'),
    maxWidth: 'fit-content',
  },
  uploadField: {
    cursor: 'pointer',
    // background: '#E5E5E5',
    border: '1.5px dashed #B9B9B9',
    borderRadius: theme.shape.borderRadius,
    height: '20.8rem',
    // margin: 'auto',
    width: '100%',
    maxWidth: '100%',
    padding: '1.5rem',
    ...mixin.flex('center', 'center'),
    flexDirection: 'column',
    position: 'relative',
    transition: '.4s height, background-color .4s',
    overflow: 'hidden',
    '&$disabled': {
      opacity: 0.7,
    },
    '& select, & $inputLabelWithFiles': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      cursor: 'pointer',
      opacity: 0,
      height: '100%',
      '&:disabled': {
        cursor: 'default',
        background: 'red',
      },
    },

    '& $presentText': {
      display: 'block',
      padding: '1rem 0 0 0',
      ...mixin.fontText(1.6, 2),
      '& span': {
        ...mixin.fontText(1.6, 2),
        color: 'inherit',
      },
      '& u, & a': {
        ...mixin.fontText(1.6, 2),
        color: 'inherit',
        borderBottom: '1px solid',
      },
    },
    // '&:hover': {
    //   backgroundColor: 'rgba(55, 65, 81, 0.04)',
    //   '& $plug_style': {
    //     opacity: 0.5,
    //   },
    // },
  },
  line_for_uploaded: {
    padding: '2rem 0 0',
    flexWrap: 'wrap',
    ...mixin.flex('center', 'center'),
  },
  plug_style: {
    alignItems: 'center',
    borderRadius: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    outline: 'none',
    padding: 6,
    width: '100%',
    height: '100%',
    transition: '.4s',
  },
  freeze: {
    pointerEvents: 'none'
  },
  error: {
    borderColor: theme.palette.error.main,
    background: '#ff000052',
    '& $text': {
      color: theme.palette.error.main,
    },
  },
  backField: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    cursor: 'pointer',
    opacity: 0,
    height: '100%',
  },
  text: {
    color: '#7C7C7C',
    fontSize: '1.4rem',
    lineHeight: '2rem',
    display: 'block',
    width: '100%',
    maxWidth: '37.2rem',
    margin: '3rem auto',
    textAlign: 'center',
  },
  file: {
    padding: 5,
    ...mixin.flex('center'),
    '& + $file': {
      marginLeft: '1rem',
    },
    '& $fileText': {
      ...mixin.fontText(1.2, 1.5, 600, theme.palette.primary.dark),
      paddingLeft: '.7rem',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      maxWidth: '18rem',
    },
  },

  disabled: {},
  fileText: {},
  select: {},
  presentText: {},
  uploaded: {},
  inputLabelWithFiles: {},
}));
