import {
  ColSortDirections,
  IApiProps,
  ISearchableProps,
  IStyleProps,
  ITablePropsPositionElement
} from './gridInterfaces';

export const defaultSearchableProps: ISearchableProps = {
  showSearchField: true,
  placeholder: 'Search...',
  changeSearchField: null,
  renderCustomSearchField: null
};

// @ts-ignore
export const defaultApiValues:IApiProps<T> = {
  api: null,
  apiMethodName: '',
  isSkip: false,
  isServerOperation: true,
  apiParams: null,
  externalData: null,
  responseData: (val) => val,
  getExternalFilteredData: (val) => val
};

export const defaultValueApiRequest = {
  defaultSortBy: null as any,
  defaultSortDirection: null as ColSortDirections,
  defaultPageSize: 50,
  scrollUpAfterRequest: true
};

export const defaultStyleEffects:IStyleProps = {
  isSelectedEven: false,
  isSelectedOdd: false,
  isHover: false,
  classNameForTableCellChildren: null
};

export const emptyGridData = {
  total: 0,
  result: [] as any
};

export const defaultTableProps: ITablePropsPositionElement = {
  atTheEndOfTable: null,
  toTheTopOfTable: null,
  hideTableHead: false,
  hideTableBody: false
};
