import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MenuItemWithSubMenu } from './MenuItemWithSubMenu';
import { MenuItem } from './MenuItem';
import { IMenuItem } from '../interface';

interface IParentProps extends IMenuItem {
  currentItem?: IMenuItem,
  setIsOpenParent?: (v: boolean) => void
}

export const ParentItem = ({ currentItem, title, icon, subMenus, isPermission = true, setIsOpenParent = () => {} }:IParentProps) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(currentItem?.isOpen));
  const handleOpen = (v: boolean) => {
    setIsOpen(v);
    setIsOpenParent(v);
  };
  const handleClick = (v: boolean) => {
    setIsOpen(v);
  };

  useEffect(() => {
    setIsOpen(Boolean(currentItem?.isOpen));
  }, [currentItem?.isOpen]);

  if (isPermission) {
    return (
       <MenuItemWithSubMenu
          icon={icon}
          title={title}
          isOpen={isOpen}
          setIsOpen={handleClick}
       >
          {subMenus && subMenus.map((item, i) => {
            if (!item.subMenus) {
              return (
                 <MenuItem
                    pathname={pathname}
                    setIsOpen={handleOpen}
                    key={`${item.href}-${i.toString()}`}
                    {...item}
                 />
              );
            }
            return (
               <ParentItem
                  {...item}
                  currentItem={item}
                  setIsOpenParent={handleOpen}
                  key={`${item.href}-${i.toString()}`}
               />
            );
          })}
       </MenuItemWithSubMenu>
    );
  }
  return null;
};
