import React, { memo } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';

import { ItemWithSubMenuProps } from '../interface';

import { useStyles } from '../styles';

export const MenuItemWithSubMenu = memo(
  ({
    title,
    icon,
    children,
    isOpen = false,
    setIsOpen,
  }: ItemWithSubMenuProps) => {
    const classes = useStyles();

    const handleClick = () => {
      if (setIsOpen) {
        setIsOpen(!isOpen);
      }
    };

    return (
       <>
          <ListItem
             button
             onClick={handleClick}
             className={clsx(classes.menu_item_element, classes.menuItem, classes.headSubMenu, {
               [classes.activeHeadItem]: isOpen
             })}
          >
             {icon && (
             <div className={classes.wrapperIcon}>
                {icon}
             </div>
             )}
             <ListItemText
                primary={title}
                className="title_head"
             />
             {isOpen ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>

          <div
             className={clsx(classes.wrapper_for_dropdown)}
             style={{ display: isOpen ? 'block' : 'none' }}
          >
             {children}
          </div>
       </>
    );
  }
);
