import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authInitialState, IAuthReducer } from './authInitialState';

export const slice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    changeAuthStatus: (state:IAuthReducer, action:PayloadAction<boolean>): void => {
      state.isAuth = action.payload;
    },

  },
});

export const { changeAuthStatus } = slice.actions;
export default slice.reducer;
