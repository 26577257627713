import React, { memo, useCallback, useEffect, useState } from 'react';
import { FormAutocomplete } from '../FormAutocomplete';
import ErrorBoundary from '../../containers/ErrorBoundary';
import { HttpApi } from '../../api/HttpApi';
import { CountryAutocompleteNotification } from './components/CountryAutocompleteNotification';

interface IOption {
  value: string,
  text: string,
  alpha2: string,
}

interface CountryAutocompleteProps {
  errors?: any;
  disableClearable?: boolean,
  label?: string;
  getValues?: any;
  setValue?: any;
  extraProps?: any;
  isDisabled?: boolean,
  isRequired?: boolean;
  originalName?: string;
  fieldComment?: string | null;
  handleBlur?: (selectedOption: IOption | null) => void;
  handleChange?: (selectedOption: IOption | null) => void;
}

const CountryAutocomplete = ({
  originalName,
  extraProps = {},
  label,
  disableClearable = false,
  errors = false,
  fieldComment,
  getValues,
  isDisabled = false,
  handleChange,
  handleBlur,
  setValue,
  isRequired = false,
}: CountryAutocompleteProps) => {
  const [optionsList, setOptionsList] = useState<IOption[] | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [textError, setTextError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const get = async () => HttpApi.get('/misc/GetCountriesAlpha2');

  const blur = useCallback(
    (val: unknown) => {
      if (handleBlur) {
        handleBlur(val as IOption | null);
      }
    },
    [handleBlur]
  );
  const change = useCallback(
    (val: unknown) => {
      if (handleChange) {
        handleChange(val as IOption | null);
      }
    },
    [handleChange]
  );

  const handleClose = () => {
    setOpen(false);
    setTextError('');
  };

  useEffect(() => {
    if (!localStorage.getItem('country-list')) {
      setIsLoading(true);
      get()
        .then((res: IOption[]) => {
          if (res) {
            setOptionsList(res);
            localStorage.setItem('country-list', JSON.stringify(res));
            setIsLoading(false);
            return;
          }
          setOptionsList(null);
        })
        .catch((error) => {
          setIsLoading(false);
          setTextError(error.message);
          setOpen(true);
        });
    } else {
      const countryList: string = localStorage.getItem('country-list') as string;
      setOptionsList(JSON.parse(countryList));
    }
  }, []);

  return (
     <ErrorBoundary>
        <CountryAutocompleteNotification
           isShow={open}
           close={handleClose}
           text={textError}
        />

        <FormAutocomplete<IOption>
           openOnFocus
           optionsSettings={{
             optionValue: 'alpha2',
             optionText: 'text'
           }}
           setValue={setValue}
           label={label}
           errors={errors}
           onBlur={blur}
           getValues={getValues}
           isLoading={isLoading}
           isDisabled={isDisabled}
           isRequired={isRequired}
           extraProps={extraProps}
           originalName={originalName}
           fieldComment={fieldComment}
           options={optionsList || []}
           onChange={change}
           disableClearable={disableClearable}
        />
     </ErrorBoundary>
  );
};

export default memo(CountryAutocomplete);
