import React, { memo, useEffect } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { IMenuLinkProps } from '../interface';

import { useStyles } from '../styles';

export const MenuItem = memo(({
  href,
  icon,
  title,
  target,
  onClick,
  subItem,
  externalLink,
  classNameFotItem,
  isPermission = true,
  setIsOpen,
  pathname,
  extraInternalLinks
}: IMenuLinkProps) => {
  const classes = useStyles();

  const itemClick = () => {
    if (onClick) {
      onClick();
    }
  };

  useEffect(() => {
    if (pathname && setIsOpen && href) {
      if (href === pathname) {
        setIsOpen(true);
      }
    }
    if (extraInternalLinks) {
      extraInternalLinks.forEach((link) => {
        if (link === pathname) {
          if (setIsOpen) setIsOpen(true);
        }
      });
    }
  }, []);

  if (isPermission) {
    if (href) {
      if (!externalLink) {
        return (
           <NavLink
              to={href}
              className={clsx(classes.menu_item_element, classes.link, classNameFotItem, {
                [classes.subItem]: subItem
              })}
              activeClassName={classes.active}
              isActive={(match, { pathname: currentPathName }) => {
                if (currentPathName === href) return true;
                if (extraInternalLinks?.includes(currentPathName)) return true;
                return false;
              }}
           >
              <ListItem
                 button
                 className={clsx(classes.menuItem)}
              >
                 {icon && (
                 <div className={classes.wrapperIcon}>
                    {icon}
                 </div>
                 )}
                 <ListItemText
                    primary={title}
                    className={clsx(
                      !icon && classes.menuItemText,
                      classNameFotItem
                    )}
                 />
              </ListItem>
           </NavLink>
        );
      }
      return (
         <a
            href={href}
            target={target && target}
            className={clsx(classes.menu_item_element, classes.link, classNameFotItem, {
              [classes.subItem]: subItem
            })}
         >
            <ListItem
               button
               className={clsx(classes.menuItem)}
            >
               {icon && (
               <div className={classes.wrapperIcon}>
                  {icon}
               </div>
               )}
               <ListItemText
                  primary={title}
                  className={clsx(
                    !icon && classes.menuItemText,
                    classNameFotItem
                  )}
               />
            </ListItem>
         </a>
      );
    }

    return (
       <ListItem
          button
          onClick={itemClick}
          className={clsx(classes.menu_item_element, classes.menuItem)}
       >
          {icon && icon}
          <ListItemText
             primary={title}
             className={clsx(
               !icon && classes.menuItemText,
               classNameFotItem,
               {
                 [classes.noIcon]: !icon,
               }
             )}
          />
       </ListItem>
    );
  }
  return null;
});
