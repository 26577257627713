import React, { memo, useEffect, useState } from 'react';
import clsx from 'clsx';

import DefaultText from './DefaultText';
import ErrorText from './ErrorText';
import { IDefaultUploadPlugProps } from '../interface';

import { useStyles } from '../styles';

const DefaultPlug = ({ dropsProps, onImageUpload, errors, isDisabled, className, maxFileSize, maxNumber, uploadAreaSettings }:IDefaultUploadPlugProps) => {
  const classes = useStyles();
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState<string>('Error');

  useEffect(() => {
    if (errors && !errors.maxNumber) {
      setShowError(true);
      if (errors.acceptType) {
        setErrorText('Your selected file type is not allow');
      }
      if (errors.maxFileSize) {
        const a = maxFileSize / 1000000;
        setErrorText(`Selected file size exceed ${a.toFixed(0)}Mb`);
      }
      if (errors.resolution) {
        setErrorText('Selected file is not match your desired resolution');
      }
    }
  }, [errors, maxFileSize, maxNumber]);

  useEffect(() => {
    if (showError) {
      const timeout = setTimeout(() => {
        setShowError(false);
        clearTimeout(timeout);
      }, 3000);
    }
  }, [showError]);
  return (
     <div
        role="presentation"
        onClick={onImageUpload}
        className={clsx(classes.uploadField, className, {
          [classes.error]: showError,
          [classes.freeze]: isDisabled
        })}
        {...dropsProps}
     >
        <div className={classes.plug_style}>
           {!showError ? (
              <DefaultText uploadAreaSettings={uploadAreaSettings} />
           ) : <ErrorText text={errorText} />}
        </div>
     </div>
  );
};

export default memo(DefaultPlug);
