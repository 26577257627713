import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import cookies from 'js-cookie';
import { FormField, useHookForm, cookieNames } from '@monorepo/common';
import { useVerifyCodeMutation } from '../../../../services/serviceInvoice';
import { IVerifyCodeRequest } from '../../../../services/serviceInvoice/responseInterfaces';
import { schema } from './schema';
import { showNotification } from '../../../../features/sliceNotification';

interface IStep2Props {
  setIsCodeSending: (isEmailSending: boolean) => void;
  email: string;
}

const Step2 = ({ setIsCodeSending, email }: IStep2Props) => {
  const [verifyCode, { isLoading, isSuccess, data: response, isError, error }] = useVerifyCodeMutation();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    getValues,
    errors
  } = useHookForm<IVerifyCodeRequest>({
    schema
  });

  const onSubmit = (data: IVerifyCodeRequest) => {
    data.email = email;
    verifyCode(data);
  };

  useEffect(() => {
    setIsCodeSending(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess) {
      const { code } = getValues();
      cookies.set(cookieNames.invoice_token, code, { expires: 365 });
      history.replace('/invoice');
    }
  }, [isSuccess, response]);

  useEffect(() => {
    if (isError) {
      if ('data' in error) {
        dispatch(showNotification({
          show: true,
          type: 'error',
          text: error.data as string
        }));
      }
    }
  }, [isError, error]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ padding: 20 }}
    >
      <FormField
        extraProps={{ ...register('code') }}
        label="Code"
        errors={errors}
        fieldComment="Please check your email for letter from 'noreply@bevnet.com' with 6 digit code."
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        style={{ width: '100%' }}
      >
        Verify
      </Button>
    </form>
  );
};

export default Step2;
