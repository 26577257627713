import React, { memo } from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import { Btn } from '@monorepo/common';
import { customButtonStyles } from '../../../constants/customButtonStyles';
import { IUserOrders } from '../../../features/sliceUserProfile/UserProfileInitialState';

interface IOrderTableLineProps extends IUserOrders{}
const OrderTableLine = ({ orderId, date, companyName, total, guid }:IOrderTableLineProps) => (
  <TableRow
    hover
  >
    <TableCell style={{ borderBottom: '1px solid #E6E8F0' }}>
      <Typography
        color="textPrimary"
        variant="subtitle2"
      >
        {moment(date).format('MM/DD/YYYY') || '-'}
      </Typography>
    </TableCell>

    <TableCell style={{ borderBottom: '1px solid #E6E8F0' }}>
      {orderId || '-'}
    </TableCell>
    <TableCell style={{ borderBottom: '1px solid #E6E8F0' }}>
      {companyName || '-'}
    </TableCell>
    <TableCell style={{ borderBottom: '1px solid #E6E8F0' }}>
      {parseFloat(total || '').toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })}
    </TableCell>
    <TableCell style={{ borderBottom: '1px solid #E6E8F0' }}>
      <Btn
        link={`https://app.bevnet.com/orders/receipt/${guid}`}
        customStyles={customButtonStyles}
        target="_blank"
        external
      >
        View Receipt
      </Btn>
    </TableCell>
  </TableRow>
);

export default memo(OrderTableLine);
