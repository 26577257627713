import { IGridDataResponse } from '../gridInterfaces';
import { filterByText } from './filterByText';
import { paginatedResults } from './pagination';

interface IProps<T> {
  direction: number;
  incomingData: T[];
  searchText: string;
  pageSize: number;
  activeColName: keyof T | null;
  setInternalData?: (data: IGridDataResponse<T>) => void
}
export const sortData = <T, >({ incomingData, setInternalData, direction = 0, searchText, activeColName, pageSize }:IProps<T>) => {
  const newData = [...incomingData];
  if (setInternalData) {
    if (direction === 1 && activeColName) {
      const sorted: T[] = newData.sort((a, b) => ((a[activeColName] > b[activeColName]) ? 1 : -1));
      const {
        total,
        result
      } = filterByText({
        data: sorted,
        searchText
      });
      setInternalData({
        total,
        result: paginatedResults<T>({
          pageSize,
          page: 1,
          data: result
        })
      });
      return {
        total,
        result
      };
    }
    if (direction === 2 && activeColName) {
      const sorted: T[] = newData.sort((a, b) => ((a[activeColName] < b[activeColName]) ? 1 : -1));
      const { total, result } = filterByText({ data: sorted, searchText });
      setInternalData({
        total,
        result: paginatedResults<T>({
          pageSize,
          page: 1,
          data: result
        })
      });
      return {
        total,
        result
      };
    }

    const { total, result } = filterByText({ data: incomingData, searchText });
    setInternalData({
      total,
      result: paginatedResults<T>({
        pageSize,
        page: 1,
        data: result
      })
    });
    return {
      total,
      result
    };
  }

  const sorted: T[] = newData.sort((a, b) => {
    if (activeColName) {
      if (direction === 1) {
        return (a[activeColName] > b[activeColName]) ? 1 : -1;
      }
      if (direction === 2) {
        return (a[activeColName] < b[activeColName]) ? 1 : -1;
      }
      return 1;
    }
    return 1;
  });
  return {
    total: sorted.length,
    result: sorted
  };
};
