import React, { ReactNode, useEffect, useState } from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { IBrandOptionsList } from '../interface';

import { useStyles } from '../styles';

interface IBrandLinkProps extends IBrandOptionsList {
  id: number,
  isHref?: boolean
}

const BrandLink = ({ link, subText, title, icon, id, isHref }:IBrandLinkProps) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [componentBody, setComponentBody] = useState<ReactNode | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsActive(true);
      clearTimeout(timeout);
    }, 10);
  }, []);

  useEffect(() => {
    setComponentBody(
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            {icon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={subText}
        />
      </ListItem>
    );
  }, [icon, title, subText]);

  if (isHref) {
    return (
      <a
        href={link}
        target="_blank"
        className={clsx(classes.link, {
          [classes.active]: isActive
        })}
        style={{
          transitionDelay: `.${id}s`
        }}
        rel="noreferrer"
      >
        {componentBody}
      </a>
    );
  }

  return (
    <Link
      to={link}
      className={clsx(classes.link, {
        [classes.active]: isActive
      })}
      style={{
        transitionDelay: `.${id}s`
      }}
    >
      {componentBody}
    </Link>
  );
};

export default BrandLink;
