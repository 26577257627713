import React, { memo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { ErrorBoundary } from '@monorepo/common';

import { book } from './books';
import ChangePassword from '../pages/ChangePassword';
import PublicProfile from '../pages/PublicProfile';
import DiscordVerify from '../pages/DiscordVerify';
import ConfirmEmail from '../pages/ConfirmEmail';
import Newsletter from '../pages/Newsletter';
import Account from '../pages/Account';
import Update from '../pages/Update';
import Orders from '../pages/Orders';

import EditBrand from '../pages/brands/EditBrand';
import BrandOptions from '../pages/brands/BrandOptions';
import UploadLogo from '../pages/brands/UploadLogo';
import EditProducts from '../pages/brands/EditProducts';
import CreateProduct from '../pages/brands/CreateProduct';
import CreateSubBrand from '../pages/brands/CreateSubBrand';
import EditProductInfo from '../pages/brands/EditProductInfo';
import UploadProductPhotos from '../pages/brands/UploadProductPhotos';
import Help from '../pages/Help';
import Invoice from '../pages/Invoice';
import InvoiceConfirmEmail from '../pages/InvoiceConfirmEmail/InvoiceConfirmEmail';
import InvoiceCompleted from '../pages/InvoiceCompleted/InvoiceCompleted';
import JobsPrepaid from '../pages/Jobs/JobsPrepaid';
import JobsPrepaidDetail from '../pages/Jobs/JobsPrepaidDetail';
import JobsApplicants from '../pages/Jobs/JobsApplicants';
import JobsApplicantDetail from '../pages/Jobs/JobsApplicantDetail';
import JobsListing from '../pages/Jobs/JobsListing';
import PressReleases from '../pages/PressReleases';
import Upgrade from '../pages/PressReleases/Upgrade';
import UpgradeSuccess from '../pages/PressReleases/UpgradeSuccess/UpgradeSuccess';

const RootRouters = () => (
  <>
    <Switch>
      <Route
        exact
        path="/"
      >
        <Redirect to={book.userAccount} />
      </Route>

      <Route
        exact
        path={book.userAccount}
      >
        <ErrorBoundary>
          <Account />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.userUpdate}
      >
        <ErrorBoundary>
          <Update />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.userPublicProfile}
      >
        <ErrorBoundary>
          <PublicProfile />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.userOrders}
      >
        <ErrorBoundary>
          <Orders />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.userChangePassword}
      >
        <ErrorBoundary>
          <ChangePassword />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.confirmEmail}
      >
        <ErrorBoundary>
          <ConfirmEmail />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.discordVerify}
      >
        <ErrorBoundary>
          <DiscordVerify />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.newsletter}
      >
        <ErrorBoundary>
          <Newsletter />
        </ErrorBoundary>
      </Route>
      {/* ----------------------------------   Brands */}
      <Route
        exact
        path={book.brands.brandOptions()}
      >
        <ErrorBoundary>
          <BrandOptions />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.brands.editBrand()}
      >
        <ErrorBoundary>
          <EditBrand />
        </ErrorBoundary>
      </Route>
      <Route
        exact
        path={book.brands.uploadLogo()}
      >
        <ErrorBoundary>
          <UploadLogo />
        </ErrorBoundary>
      </Route>
      <Route
        exact
        path={book.brands.editProducts()}
      >
        <ErrorBoundary>
          <EditProducts />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.brands.createProduct()}
      >
        <ErrorBoundary>
          <CreateProduct />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.brands.editProductInfo()}
      >
        <ErrorBoundary>
          <EditProductInfo />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={book.brands.uploadProductPhotos()}
      >
        <ErrorBoundary>
          <UploadProductPhotos />
        </ErrorBoundary>
      </Route>
      <Route
        exact
        path={book.brands.createSubBrand()}
      >
        <ErrorBoundary>
          <CreateSubBrand />
        </ErrorBoundary>
      </Route>

      {/* ----------------------------------   Help */}
      <Route
        exact
        path={book.needHelp}
      >
        <ErrorBoundary>
          <Help />
        </ErrorBoundary>
      </Route>

      {/* ----------------------------------   Invoice */}
      <Route
        exact
        path={book.invoices.invoice}
      >
        <ErrorBoundary>
          <Invoice />
        </ErrorBoundary>
      </Route>
      <Route
        exact
        path={book.invoices.confirmEmail}
      >
        <ErrorBoundary>
          <InvoiceConfirmEmail />
        </ErrorBoundary>
      </Route>
      <Route
        exact
        path={book.invoices.invoiceCompleted}
      >
        <ErrorBoundary>
          <InvoiceCompleted />
        </ErrorBoundary>
      </Route>

      {/* ----------------------------------   JobsPrepaid */}
      <Route
        exact
        path={book.jobs.jobsPrepaid}
      >
        <ErrorBoundary>
          <JobsPrepaid />
        </ErrorBoundary>
      </Route>
      <Route
        exact
        path={book.jobs.jobsPrepaidDetail()}
      >
        <ErrorBoundary>
          <JobsPrepaidDetail />
        </ErrorBoundary>
      </Route>
      <Route
        exact
        path={book.jobs.jobsApplicants()}
      >
        <ErrorBoundary>
          <JobsApplicants />
        </ErrorBoundary>
      </Route>
      <Route
        exact
        path={book.jobs.jobsApplicantDetail()}
      >
        <ErrorBoundary>
          <JobsApplicantDetail />
        </ErrorBoundary>
      </Route>
      <Route
        exact
        path={book.jobs.jobsListing}
      >
        <ErrorBoundary>
          <JobsListing />
        </ErrorBoundary>
      </Route>
      {/* ----------------------------------   Press Releases */}
      <Route
        exact
        path={book.pressReleases.pressReleases}
      >
        <ErrorBoundary>
          <PressReleases />
        </ErrorBoundary>
      </Route>
      <Route
        exact
        path={book.pressReleases.upgrade()}
      >
        <ErrorBoundary>
          <Upgrade />
        </ErrorBoundary>
      </Route>
      <Route
        exact
        path={book.pressReleases.upgradeSuccess}
      >
        <ErrorBoundary>
          <UpgradeSuccess />
        </ErrorBoundary>
      </Route>
    </Switch>
  </>
);

export default memo(RootRouters);
