import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import endpoints from '../../api/endpoints';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IRequest {
  email: string,
  code?: string
}

export const emailApi = createApi({
  reducerPath: 'userEmail',
  tagTypes: ['Email'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    checkEmail: build.mutation<boolean, IRequest>({
      query: (body:IRequest) => ({
        url: endpoints.checkEmail,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Email']
    }),

    confirmEmail: build.mutation<boolean, IRequest>({
      query: (body:IRequest) => ({
        url: endpoints.confirmEmail,
        method: 'POST',
        body
      }),
    }),
  }),
});
export const { useCheckEmailMutation, useConfirmEmailMutation } = emailApi;
