import { makeStyles } from '@material-ui/core';
import { mixin } from '../../utils/mixin';

// '@media(max-width: 768px)': {}
export const useStyles = makeStyles((theme) => ({
  step_icon_label: {},
  column_mode: {},
  root: {
    paddingTop: '2rem',
    ...mixin.flex('flex-start', 'center'),
    '@media(max-width: 1200px)': {
      paddingTop: 0
    },
    '&$left_info_panel_move_to_top': {
      '@media(max-width: 1200px)': {
        display: 'flex',
        flexDirection: 'column',
      }
    },
    '& $step_icon_label': {
      cursor: 'pointer !important',
      width: 'fit-content',
      maxWidth: '100%',
      '&:hover .MuiTypography-root': {
        color: theme.palette.primary.dark
      }
    },
    '&$column_mode': {
      flexDirection: 'column',
      alignItems: 'center',
      '& $description_page': {
        maxWidth: '50rem',
        marginRight: 0,
        marginBottom: 30,
        '@media(max-width: 768px)': {
          maxWidth: '100%'
        }
      }
    }
  },
  left_info_panel_move_to_top: {},
  wrapper_for_steps: {
    width: '100%',
    maxWidth: '50rem',
    '& .MuiAvatar-root': {
      backgroundColor: '#e21836'
    },
    '@media(max-width: 1200px)': {
      maxWidth: '100%',
    }
  },
  description_page: {
    display: 'block',
    width: '100%',
    maxWidth: '30rem',
    marginRight: '4rem',
    order: -1,
    '&$left_info_panel_move_to_top': {
      '@media(max-width: 1200px)': {
        marginTop: '0 !important'
      }
    },
    '@media(max-width: 1200px)': {
      maxWidth: '100%',
      marginTop: '2rem',
    },
    '& p, & b,& li': {
      fontSize: 16
    },
    '& ul': {
      paddingLeft: '1.3rem'
    },
    '& p, & ul': {
      marginBottom: '1rem'
    },
    '& li': {
      marginBottom: '.7rem'
    },
  },
  active_step: {
    padding: '32px 8px 32px 20px',
    borderLeft: '2px solid rgb(230, 232, 240) !important',
    marginLeft: '20px !important'
  },

  jobs_line_buttons: {
    marginTop: '2rem',
    ...mixin.flex('center', 'flex-start'),
    minHeight: 30,
    '@media(max-width: 600px)': {
      flexDirection: 'column',
      '& button': {
        width: '100%',
        maxWidth: '100%',
      }
    }
  },
  previous_btn: {
    '@media(max-width: 600px)': {
      marginLeft: '0 !important',
      marginTop: ' 15px !important'
    }
  },
  stepper_side: {}
}));
