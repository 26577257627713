import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CardWrapper, scrollUp } from '@monorepo/common';

import {
  useGetBrandInfoQuery,
  useGetBrandProductsQuery,
  useUpdateStatusProductMutation
} from '../../../services/serviceBrands';
import { showNotification } from '../../../features/sliceNotification';
import { useAccessStatus } from '../../../hook/useAccessStatus';
import ProductCard from './components/ProductCard';
import { book } from '../../../routers/books';

import { EditProductFilterPanel } from './components/EditProductFilterPanel';
import { FilterTypes, FilterTypesInternal, IBrandsProduct } from './intyerface';
import { EditProductChips } from './components/EditProductChips';
import { EditProductsContent } from './components/EditProductsContent';
import { EmptyBrand } from './components/EmptyBrand';

// interface IEditProductsProps {}
const EditProducts = () => {
  const dispatch = useDispatch();
  const [activeType, setActiveType] = useState<FilterTypes>('all');
  const [categories, setCategories] = useState<{ [key in FilterTypesInternal]: boolean }>({
    active: true,
    discontinued: false,
  });
  const [activeSubBrand, setActiveSubBrand] = useState<string>(null);
  const [openProduct, setOpenProduct] = useState<string | null>(null);
  const { isLoadingAccessStatus, brandGuid, isRequest, noAccessComponent, hideContent } = useAccessStatus();
  const [currentPguid, setCurrentPguid] = useState<string | null>(null);

  const [filteredProducts, setFilteredProducts] = useState<IBrandsProduct[]>([]);
  const { data: brandInfo, isLoading } = useGetBrandInfoQuery({ brandGuid }, {
    skip: !isRequest
  });
  const { data: brandProducts, isLoading: productsLoading } = useGetBrandProductsQuery({ brandGuid }, {
    skip: !isRequest
  });
  // for title page and title card
  const titlePage: string = noAccessComponent ? 'No Access' : `Edit Products: ${brandInfo?.brandName}`;

  const handleOpenProduct = (productId: string): void => {
    setOpenProduct((prevValue) => (prevValue === productId ? null : productId));
  };

  const handleSubBrandClick = (subBrand: string): void => {
    setActiveSubBrand(subBrand);
  };

  const handleSubBrandFilterDelete = () => {
    setActiveSubBrand(null);
  };

  const [updateStatusProduct, { isLoading: isChangingStatus, isSuccess: isStatusWasChanged }] = useUpdateStatusProductMutation();

  const changeStatus = useCallback((productGuid: string) => {
    setCurrentPguid(productGuid);
    updateStatusProduct({
      brandGuid,
      productGuid
    });
  }, [brandGuid, setCurrentPguid]);

  useEffect(() => {
    if (isStatusWasChanged && brandProducts && currentPguid) {
      const res = brandProducts.products.find((item) => item.productGuid === currentPguid);
      scrollUp();
      dispatch(showNotification({
        show: true,
        type: 'success',
        text: `Status product "${res?.productName}" was changed!` || 'Status product  was changed!'
      }));
    }
  }, [isStatusWasChanged, brandProducts, currentPguid]);

  const { active, discontinued } = categories;

  useEffect(() => {
    if ((!active && !discontinued) || (active && discontinued)) {
      setActiveType('all');
      return;
    }
    if (active && !discontinued) {
      setActiveType('active');
      return;
    }
    if (!active && discontinued) {
      setActiveType('discontinued');
    }
  }, [categories]);

  useEffect(() => {
    if (brandProducts) {
      let res = brandProducts.products.filter((item) => {
        if (activeType === 'active' && item.isActive) {
          return item;
        }
        if (activeType === 'discontinued' && !item.isActive) {
          return item;
        }
        if (activeType === 'all') {
          return item;
        }
        return null;
      });

      if (activeSubBrand) {
        res = res.filter((item) => item.subBrand === activeSubBrand);
      }
      setFilteredProducts(res);
    }
  }, [activeType, activeSubBrand, brandProducts]);

  return (
    <CardWrapper
      isNoPadding
      isHideChildren={hideContent}
      noAccessComponent={noAccessComponent}
      isLoading={isLoading || productsLoading || isChangingStatus || isLoadingAccessStatus}
      helmet={titlePage}
      outsideHead={{
        breadcrumbs: [
          {
            title: `${brandInfo?.brandName}`,
            link: book.brands.brandOptions(brandGuid)
          },
          {
            title: 'Edit Product'
          }
        ]
      }}
    >
      <EditProductsContent
        brandGuid={brandGuid}
        title={titlePage}
        selectCategory={(
          <EditProductFilterPanel
            categories={categories}
            setCategories={setCategories}
          />
        )}
        selectedCategories={(
          <EditProductChips
            categories={categories}
            setCategories={setCategories}
            activeSubBrand={activeSubBrand}
            onDeleteActiveSubBrandFilter={handleSubBrandFilterDelete}
          />
        )}
      >

        {filteredProducts.length ? filteredProducts.map((item, i) => (
          <ProductCard
            index={i}
            bguid={brandGuid}
            key={item.productGuid}
            imageUrl={item.imageUrl}
            isActive={item.isActive}
            changeStatus={changeStatus}
            productGuid={item.productGuid}
            productName={item.productName}
            productSubBrand={item.subBrand}
            handleOpenProduct={handleOpenProduct}
            openProduct={openProduct === item.productGuid}
            handleSubBrandClick={handleSubBrandClick}
          />
        )) : (<EmptyBrand />)}
      </EditProductsContent>
    </CardWrapper>
  );
};

export default memo(EditProducts);
