import { Button } from '@material-ui/core';
import { FormField, useHookForm } from '@monorepo/common';
import { useEffect } from 'react';
import { useConfirmEmailMutation } from '../../../../services/serviceInvoice';
import { IConfirmEmailRequest } from '../../../../services/serviceInvoice/responseInterfaces';
import { schema } from './schema';
import { ConfirmEmailStep } from '../../../../constants/invoice';

interface IStep1Props {
  setStep: (step: ConfirmEmailStep) => void;
  setEmail: (email: string) => void;
  setIsEmailSending: (isEmailSending: boolean) => void;
}

const Step1 = ({ setStep, setEmail, setIsEmailSending }: IStep1Props) => {
  const [verifyEmail, { isLoading, isSuccess }] = useConfirmEmailMutation();

  const {
    register,
    handleSubmit,
    errors
  } = useHookForm<IConfirmEmailRequest>({
    schema
  });

  const onSubmit = (data: IConfirmEmailRequest) => {
    setEmail(data.email);
    verifyEmail(data);
  };

  useEffect(() => {
    setIsEmailSending(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess) {
      setStep(ConfirmEmailStep.Step2);
    }
  }, [isSuccess]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ padding: 20 }}
    >
      <FormField
        extraProps={{ ...register('email') }}
        label="Email Address"
        errors={errors}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        style={{ width: '100%' }}
      >
        Send code
      </Button>
    </form>
  );
};

export default Step1;
