import React from 'react';
import { Btn } from '@monorepo/common';
import { useStyles } from '../../styles';

interface IStep1ToolbarProps {
  handlerClick: () => void
}
const Step1Toolbar = ({ handlerClick }:IStep1ToolbarProps) => {
  const classes = useStyles();
  return (
    <div className={classes.step1_toolbar_wrapper}>
      <Btn click={() => handlerClick()}>+ Add an Invoice</Btn>
    </div>
  );
};

export default Step1Toolbar;
