import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  userProfileInitialState,
  IUserProfileData,
  IUserProfileReducer,
  IUserOrders,
  cleanUserData
} from './UserProfileInitialState';

export const slice = createSlice({
  name: 'userProfile',
  initialState: userProfileInitialState,
  reducers: {
    fillUserProfile: (state:IUserProfileReducer, action:PayloadAction<IUserProfileData>): void => {
      state.data = {
        ...state.data,
        ...action.payload
      };
    },
    fillUpdatedUserProfile: (state:IUserProfileReducer, action:PayloadAction<IUserProfileData>): void => {
      state.data = {
        ...state.data,
        ...action.payload
      };
    },
    fillPublicUserProfile: (state:IUserProfileReducer, action:PayloadAction<IUserProfileData>): void => {
      state.data = {
        ...state.data,
        ...action.payload
      };
    },
    fillOrders: (state:IUserProfileReducer, action:PayloadAction<IUserOrders[]>): void => {
      state.orders = action.payload;
    },
    changeStatusPassword: (state:IUserProfileReducer, action:PayloadAction<boolean>): void => {
      state.passwordChanged = action.payload;
    },
    fillTemporaryUserProfile: (state:IUserProfileReducer, action:PayloadAction<IUserProfileData | null>): void => {
      if (!action.payload) {
        state.temporaryUserData = {
          ...cleanUserData,
        };
        return;
      }
      state.temporaryUserData = {
        ...state.temporaryUserData,
        ...action.payload
      };
    },
  },
  extraReducers: () => {}
});
export const {
  fillUserProfile,
  fillUpdatedUserProfile,
  fillPublicUserProfile,
  fillOrders,
  changeStatusPassword,
  fillTemporaryUserProfile
} = slice.actions;
export default slice.reducer;
