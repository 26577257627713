import { useForm, useFieldArray, useWatch, Path, PathValue } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { scrollUp } from '../utils/scrollUp';

interface IProps<M> {
  schema: any;
  defaultValues?: M | null;
  data?: M | null;
  showChangedFields?: (data: M | null) => void,
  ifErrorScrollPageToUp?: boolean,
}

export function useHookForm<T>({ schema, defaultValues, data, showChangedFields, ifErrorScrollPageToUp = false }: IProps<T>) {
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    reset,
    resetField,
    setFocus,
    getFieldState,
    clearErrors,
    formState: { errors, isDirty, dirtyFields }
  } = useForm<T>({
    resolver: yupResolver(schema),
    ...(defaultValues) && { ...defaultValues }
  });

  const [watchForChangedFields, setWatchForChangedFields] = useState<T | null>(null);

  const fillField = (alreadyData:T) => {
    Object.keys(alreadyData).forEach((item) => {
      setValue(item as Path<T>, alreadyData[item as keyof T] as PathValue<T, Path<T>>);
    });
  };

  useEffect(() => {
    if (showChangedFields) {
      const subscription = watch((value) => {
        if (value) {
          if (JSON.stringify(watchForChangedFields)
            .toString() !== JSON.stringify(value)
            .toString()) {
            setWatchForChangedFields(value as T);
            showChangedFields(value as T);
          }
        }
      });
      return () => subscription.unsubscribe();
    }
    return () => {};
  }, [watch, watchForChangedFields, showChangedFields]);

  useEffect(() => {
    if (ifErrorScrollPageToUp) {
      const errorsArray: string[] = Object.keys(errors);
      if (errorsArray.length) {
        scrollUp();
      }
    }
  }, [ifErrorScrollPageToUp, errors, handleSubmit]);

  useEffect(() => {
    if (defaultValues) {
      fillField(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (data) {
      fillField(data);
    }
  }, [data]);

  const setFormData = (formData: T | null) => {
    if (formData) {
      fillField(formData);
    }
  };

  return {
    register: (name: keyof T, disabled?:boolean) => ({
      control,
      name,
      disabled
    }),
    setFormData,
    control,
    useFieldArray,
    useWatch,
    getValues,
    setValue,
    watch,
    setError,
    reset,
    resetField,
    setFocus,
    getFieldState,
    clearErrors,
    handleSubmit,
    errors,
    isDirty,
    watchForChangedFields,
    dirtyFields
  };
}
