import cookies from 'js-cookie';
import { memo } from 'react';
import { Logout } from '@mui/icons-material';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { cookieNames, UserBadgePanel } from '@monorepo/common';

import clsx from 'clsx';
import BurgerMenu from '../BurgerMenu';
import IconLogo from '../Icons/IconLogo';

import { useStyles } from './styles';
import { hostNames } from '../../constants/hosts';
import { useAppSelector } from '../../store/hooks';
import { publicProfileApi } from '../../services/userProfile/public';

// interface IHeaderProps {}
const Header = () => {
  const classes = useStyles();
  const { showLeftSide, isAuth } = useAppSelector(({ ui, auth }) => ({
    showLeftSide: ui.showLeftSide,
    isAuth: auth.isAuth
  }));
  const { data: userData } = publicProfileApi.useGetQuery('', { skip: !isAuth });

  const logOut = () => {
    cookies.remove(cookieNames.bevnet_token);
    cookies.remove(cookieNames.refresh_token);
    if (process.env.NODE_ENV === 'production') {
      window.location.href = 'https://auth.bevnet.com/user/logout?returnUrl=https://account.bevnet.com/';
      return;
    }
    window.location.reload();
  };

  return (
    <AppBar
      position="sticky"
      className={clsx(classes.header, {
        [classes.padding_for_left_side]: !showLeftSide
      })}
    >
      <Toolbar>
        <BurgerMenu />
        <IconLogo
          numberHost={hostNames[window.location.host.replace('account.', '')]}
        />
        <h5 className={classes.title_portal}>
          User Portal
        </h5>

        {isAuth && (
          <UserBadgePanel
            headshot={userData?.headshot}
            menuList={[
              {
                title: 'Log Out',
                icon: <Logout fontSize="small" />,
                handlerClick: logOut
              }
            ]}
          />
        )}

      </Toolbar>
    </AppBar>
  );
};

export default memo(Header);
