import React from 'react';
import ModalWindow from '../../../components/ModalWindow/ModalWindow';
import ErrorBoundary from '../../ErrorBoundary';
import { IModalWindowProps } from '../../../components/ModalWindow/modalWindowInterface';

interface IBasicLayoutModalWindowProps {
  modalWindowOptions?: IModalWindowProps
}
export const BasicLayoutModalWindow = ({ modalWindowOptions }:IBasicLayoutModalWindowProps) => (
   <ErrorBoundary>
      <ModalWindow
         text={modalWindowOptions?.text}
         show={!!modalWindowOptions?.show}
         title={modalWindowOptions?.title}
         errorMode={modalWindowOptions?.errorMode}
         closeModal={() => modalWindowOptions?.closeModal()}
         styleProps={modalWindowOptions?.styleProps}
      >
         {modalWindowOptions?.modalBody}
      </ModalWindow>
   </ErrorBoundary>
);
