import { Link, Typography } from '@mui/material';

// interface IAreaSubtitleProps {}
export const AreaSubtitle = () => (
   <Typography variant="h6">
      Drag & Drop or
      {' '}
      <Link underline="always">
         select a file
      </Link>
   </Typography>
);
