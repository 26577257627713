import React from 'react';
import { Typography } from '@mui/material';

import { useStyles } from '../styles';

interface IBrandInfoProps {
  slug: string
}
const BrandInfo = ({ slug }:IBrandInfoProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body1">
        Use the form below to edit your listing. Changes update in realtime.
        {' '}
        <a
          target="_blank"
          className={classes.red}
          href={`https://www.bevnet.com/reviews/${slug}`}
          rel="noreferrer"
        >
          View your public brand listing.
        </a>
      </Typography>
      <Typography variant="body1">
        Need help?
        {' '}
        <a
          target="_blank"
          href="https://support.bevnet.com/hc/en-us/requests/new#_ga=2.222720475.1154079893.1587393892-462046344.1580126121"
          className={classes.red}
          rel="noreferrer"
        >
          Contact our support team.
        </a>
      </Typography>
    </>
  );
};

export default BrandInfo;
