import { makeStyles, Theme } from '@material-ui/core';

const fon:{ [key: string]: string | number } = {
  filter: 'grayscale(1)',
  position: 'absolute',
  display: 'block',
  content: '""',
  width: '100%',
  height: 1,
  bottom: 0,
  left: 0,
};

export const useStyles = makeStyles((theme:Theme) => ({
  root: {},
  list: {
    margin: '0 0 2rem',
    padding: 0,
    overflow: 'auto',
    maxHeight: '20rem',
    minHeight: '5rem',
    '@media(max-width: 768px)': {
      maxHeight: '15rem',
    },
    '& li': {
      listStyle: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      color: '#000',
      cursor: 'pointer',
      padding: '.8rem 1rem',
      position: 'relative',
      '@media(max-width: 768px)': {
        padding: '.4rem .4rem',
        fontSize: '0.91rem'
      },
      '&:before': {
        ...fon,
        background: theme.palette.primary.light,
        opacity: 0.2
      },
      '&:after': {
        ...fon,
        height: '100%',
        transition: '.2s',
        background: theme.palette.primary.light,
        opacity: 0,
      },
      '&:hover,&$active, &.active': {
        '&:after': {
          opacity: 0.2,
        }
      },
      '&$link': {
        padding: 0,
        display: 'flex',
        '& a': {
          width: '100%',
          zIndex: 2,
          position: 'relative',
          padding: '.8rem 1rem',
        }
      },
      '& svg': {
        marginRight: 5,
        opacity: 0.6,
        width: 17,
        height: 'auto'
      },
      '& strong + small': {
        lineHeight: 1.4,
        marginLeft: 3
      }
    },
  },

  active: {},
  link: {}
}));
