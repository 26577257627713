import React, { ReactChild, ReactChildren, ReactNode } from 'react';
import clsx from 'clsx';
import { TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from '../styles';
import { IMenuExtraOptions } from '../interface';
import { MenuButton } from './MenuButton';
import Btn from '../../Btn';

interface ILeftSideWrapperProps extends IMenuExtraOptions {
  children: ReactNode | ReactChildren | ReactChild,
  setValue: (a:string) => void,
  value: string
}
export const LeftSideWrapper = ({ isMobileMenu, isShowHelp, children, setValue, value, toggleLeftSide, isShowLeftSide, dispatch }:ILeftSideWrapperProps) => {
  const classes = useStyles();

  const handlerClear = () => {
    setValue('');
  };

  return (
     <div className={clsx(classes.left_side, {
       [classes.active_item]: isMobileMenu,
       [classes.hide_left_side]: !isShowLeftSide,
     })}
     >
        <div className={classes.menu_options}>
           <MenuButton
              dispatch={dispatch}
              toggleLeftSide={toggleLeftSide}
              isShowLeftSide={isShowLeftSide}
           />
        </div>

        <TextField
           fullWidth
           color="info"
           variant="filled"
           placeholder="Search..."
           value={value}
           onChange={({ target: { value: val } }) => setValue(val)}
           InputProps={{
             ...(value.length) && {
               endAdornment: (
                  <ClearIcon
                     classes={{ root: classes.icon }}
                     onClick={handlerClear}
                  />
               ),
             } }}
           classes={{ root: classes.field }}
        />

        <div className={classes.left_side_container}>
           {children}
        </div>

        {isShowHelp
        && (
        <div className={classes.bottom_left_side_footer}>
           <hr className={classes.bottom_left_side_line} />

           <div className={classes.bottom_left_side_link_container}>
              <h3 className={classes.bottom_left_side_text}>Need Help?</h3>
              <Btn
                 className={classes.bottom_left_side_link}
                 link="/help"
              >
                 Contact Us
              </Btn>
           </div>
        </div>
        )}
     </div>
  );
};
