import * as yup from 'yup';

export const schema:any = yup.object().shape({
  firstName: yup.string().required('The first Name  field is required.'),
  lastName: yup.string().required('The last Name  field is required.'),
  email: yup.string().email('The email  field is not valid.').required('The email  field is required.'),
  company: yup.string().required('The company  field is required.').nullable(),
  title: yup.string().required('The title  field is required.').nullable(),
  phone: yup.string().required('The phone  field is required.').nullable(),
  mobilePhone: yup.string().required('The mobile phone  field is required.').nullable(),
  country: yup.string().nullable(),
  address1: yup.string().nullable(),
  address2: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  province: yup.string().nullable(),
  zip: yup.string().nullable(),
});
