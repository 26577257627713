import { makeStyles } from '@material-ui/core';
// import { mixin } from '../../utils/mixin';

// '@media(max-width: 768px)': {}
export const useStyles = makeStyles(() => ({
  fieldComment: {
    marginTop: '2px !important',
    padding: '0 15px',
    lineHeight: '1.5 !important'
  }
}));
