import { CardWrapper } from '@monorepo/common';
import { useState } from 'react';
import Step1 from './steps/Step1/Step1';
import { ConfirmEmailStep } from '../../constants/invoice';
import Step2 from './steps/Step2/Step2';

const InvoiceConfirmEmail = () => {
  const [step, setStep] = useState<ConfirmEmailStep>(ConfirmEmailStep.Step1);
  const [email, setEmail] = useState<string>('');
  const [isEmailSending, setIsEmailSending] = useState<boolean>(false);
  const [isCodeSending, setIsCodeSending] = useState<boolean>(false);

  return (
    <CardWrapper
      titleHelmet="Confirm your email address"
      isLoading={isEmailSending || isCodeSending}
    >
      {step === ConfirmEmailStep.Step1 && (
        <Step1
          setStep={setStep}
          setEmail={setEmail}
          setIsEmailSending={setIsEmailSending}
        />
      )}
      {step === ConfirmEmailStep.Step2 && (
        <Step2
          email={email}
          setIsCodeSending={setIsCodeSending}
        />
      )}
    </CardWrapper>
  );
};

export default InvoiceConfirmEmail;
