import React, { ReactNode, useEffect } from 'react';
import { TableCell, Typography } from '@mui/material';
import clsx from 'clsx';
import { TableAlignValues } from '../gridInterfaces';
import { useStyles } from '../styles';

interface ITableFooterCellProps {
  align?:TableAlignValues;
  classNameForText?: string;
  colorIndicator?: boolean;
  color?: string;
  nativeValue: number; /** this value need for add color red or green  */
  value: string; /** this value need only for user  */
  nameCol: string;
  setAllAmounts: () => void;
  totalText?: string | null;
  customElement?: {
    title?: string;
    element?: ReactNode | string | number
  }
  width?: string | number
}
const TableFooterCell = ({ classNameForText, color, value, align, colorIndicator, nativeValue, setAllAmounts, customElement, nameCol, totalText = 'Total:', width }:ITableFooterCellProps) => {
  const classes = useStyles();
  useEffect(() => {
    setAllAmounts();
  }, [nameCol, nativeValue]);

  if (!customElement) {
    return (
       <TableCell
          {...(align) && { align }}
          {...(width) && { width }}
          style={{ border: 0 }}
       >
          <Typography
             className={clsx(classNameForText, {
               [classes.value_green]: colorIndicator && nativeValue > 0,
               [classes.value_red]: colorIndicator && nativeValue < 0,
             })}
             variant="body1"
             fontWeight={600}
             color={nativeValue > 0 ? color : 'red'}
          >
             {totalText && 'Total:'}
             {' '}
             {(colorIndicator && nativeValue > 0) && value.replace('-', '')}
             {(colorIndicator && nativeValue < 0) && `(${value.replace('-', '')})`}
             {!colorIndicator && value.replace('-', '')}
          </Typography>
       </TableCell>
    );
  }
  return (
     <TableCell
        {...(align) && { align }}
        {...(width) && { width }}
     >
        <Typography
           className={clsx(classNameForText)}
           variant="body1"
           fontWeight={600}
           color={nativeValue > 0 ? color : 'red'}
        >
           {customElement?.title}
           {': '}
           {customElement?.element}
        </Typography>
     </TableCell>
  );
};

export default TableFooterCell;
