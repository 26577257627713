import { baseUrls } from '@monorepo/common';

export default {
  homeUrl: baseUrls.jobApi,
  // user
  userProfile: '/user/ContactInfo',
  userPublicProfile: '/user/PublicProfile',
  userHeadShot: '/user/PublicProfileHeadshot',
  userOrders: '/user/orders',
  userChangePassword: '/user/ChangePassword',

  // user email
  checkEmail: '/user/IsEmailChanged',
  confirmEmail: '/user/IsCodeValid',
  // newsletter
  newsletterInfo: '/user/NewsletterInfo',
  newsletterSubscribe: '/user/NewsletterSubscribe',
  newsletterUnsubscribe: '/user/NewsletterUnsubscribe',

  menuSettings: '/AccountApp/MenuSettings',

  // countries
  countries: '/misc/GetCountries',
  usaStates: '/misc/GetStates',
  // discord
  discordVerify: '/discord/VerifyJwtFromDiscord',

  // help
  help: '/help',

  // brands
  brands: {
    editBrand: (id: string) => `Brands/${id}`,
    createSubBrand: (id: string) => `/Brands/${id}/subbrand`,
    getBrandDetails: (id: string) => `/Brands/${id}`,
    brandProducts: (id: string) => `/Brands/${id}/products`,
    getLogoUrl: (id: string) => `/Brands/${id}/logo`,
    uploadLogo: (id: string) => `/Brands/${id}/logo`,
    removeLogo: (id: string) => `/Brands/${id}/logo`,
    getProductTypes: '/Brands/Products/types',
    createProduct: (id: string) => `/Brands/${id}/products`,
    getProductDetails: (brandGuid: string, productGuid: string) => `/Brands/${brandGuid}/products/${productGuid}`,
    getProductSubBrands: (id: string) => `/Brands/${id}/subbrands`,
    updateProductDetails: (brandGuid: string, productGuid: string) => `/Brands/${brandGuid}/products/${productGuid}`,
    changeStatusProduct: (brandGuid: string, productGuid: string) => `/Brands/${brandGuid}/products/${productGuid}/changestatus`,
    updateProductPhoto: (brandGuid: string, productGuid: string) => `/Brands/${brandGuid}/products/${productGuid}/photo`,
    //
    getAccessStatus: (id: string) => `/Brands/${id}/access`,
  },
  invoices: {
    invoiceDetails: '/Quotes/InvoiceDetails',
    invoiceDetailsFromTempData: (guid: string) => `/Quotes/InvoiceDetailsFromTempData/${guid}`,
    quotesDetails: (id: string) => `/Quotes/Details/${id}`,
    createDetail: '/Quotes/Details',
    updateDetail: (id: string) => `/Quotes/Details/${id}`,
    deleteDetail: (id: string) => `/Quotes/Details/${id}`,
    paymentTypes: '/Quotes/PaymentTypes',
    confirmInvoice: '/Quotes/ConfirmInvoice',
    saveTempData: '/Quotes/SaveTempData',
    paymentIntent: 'Quotes/PaymentIntent',
    confirmEmail: 'Quotes/EmailVerification',
    verifyCode: 'Quotes/CodeVerification'
  },
  // JobsPrepaid
  jobs: {
    inactiveJobsListing: '/jobs/InactiveJobsListing',
    activeJobsListing: '/jobs/ActiveJobsListing',
    jobsPrepaid: '/jobs/JobsPrepaid',
    jobsPrepaidDetail: (id: string) => `/jobs/JobsPrepaidDetail/${id}`,
    jobsApplicants: (id: string) => `/jobs/JobsApplicants/${id}`,
    jobsApplicantDetail: (eguid: string, applicationId: string) => `/jobs/JobsApplicantDetail/${eguid}/${applicationId}`,
    markAsReadJobsApplicantion: (applicationId: string) => `/jobs/MarkAsReadJobsApplicantion/${applicationId}`,
  },
  pressReleases: {
    accountPressReleases: () => '/pressreleases/accountpressreleases',
    upgradePaymentIntent: (listingTypeId: number, companyTypeId: number) => `/pressreleases/pressreleasepaymentintent/${listingTypeId}/${companyTypeId}`,
    getPressRelease: (guid: string) => `/PressReleases/PressRelease/${guid}`,
    getUpgradePrice: (listingTypeId: number, companyTypeId: number) => `/pressreleases/getupgradeprice/${listingTypeId}/${companyTypeId}`,
    upgradeFromAccount: '/pressreleases/upgradefromaccount',
  }
};
