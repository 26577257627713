import React, { memo, ReactNode, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import clsx from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';

import Loader from '../Loader';
import ErrorBoundary from '../../containers/ErrorBoundary';

import { useStyles } from './styles';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface IWrapperTabsProps {
  list: {
    tab: ReactNode,
    tabTitle: string
  }[],
  isLoading?: boolean,
  styleProps?: {
    classNameForWrapper?: string,
    classNameForHeadTabsWrapper?: string,
    classNameForHeadTab?: string,
    classNameForBodyTabsBody?: string,
  },
  defaultActiveTab?: number,
  onChange?: (t: number) => void
}
const WrapperTabs = ({
  list,
  defaultActiveTab = 0,
  onChange,
  isLoading,
  styleProps
}:IWrapperTabsProps) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:768px)');

  const [value, setValue] = React.useState(defaultActiveTab);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [onChange, value]);

  return (
     <>
        <Loader isShow={isLoading} />
        <div className={clsx(classes.root, styleProps?.classNameForWrapper)}>
           <ErrorBoundary>
              <Tabs
                 value={value}
                 onChange={handleChange}
                 className={clsx(classes.tab_head_mobile, classes.tab_wrapper, styleProps?.classNameForHeadTabsWrapper)}
                 aria-label="basic tabs example"
                 orientation={isMobile ? 'vertical' : 'horizontal'}

              >
                 {list.map((item, index) => (
                    <Tab
                       key={item.tabTitle}
                       label={item.tabTitle}
                       {...a11yProps(index)}
                       className={clsx(styleProps?.classNameForHeadTab)}
                    />
                 ))}
              </Tabs>
           </ErrorBoundary>

           <div className={clsx(styleProps?.classNameForBodyTabsBody)}>
              {list.map((item, index) => {
                if (value === index) {
                  return <ErrorBoundary key={`tab-${item.tabTitle}`}>{item.tab}</ErrorBoundary>;
                }
                return null;
              })}
           </div>
        </div>
     </>
  );
};

export default memo(WrapperTabs);
