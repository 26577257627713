import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Loader, readConfig } from '@monorepo/common';
import PaymentForm from './PaymentForm';
import { usePaymentIntentQuery } from '../../../services/pressReleases';
import { PressReleaseListingType } from '../../../constants/pressReleaseListingType';

interface IStripeWrapperProps {
  companyTypeId: number,
  pressReleaseGuid: string
}

const StripeWrapper = ({ companyTypeId, pressReleaseGuid }:IStripeWrapperProps) => {
  const [stripePromise] = useState(() => loadStripe(readConfig().stripeKey));

  const { data: paymnetIntentResponse, isLoading } = usePaymentIntentQuery({
    listingTypeId: PressReleaseListingType.Standard,
    companyTypeId,
    upgrade: true
  });

  if (!paymnetIntentResponse?.clientSecret) {
    return null;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: paymnetIntentResponse.clientSecret }}
    >
      <Loader isShow={isLoading} />
      <PaymentForm pressReleaseGuid={pressReleaseGuid} />
    </Elements>
  );
};

export default StripeWrapper;
