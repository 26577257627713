interface ILocalData<T> {
  internalData: {
    total: number;
    result: T[]
  } | null;
}
export const localTotal = <T, >({ internalData }:ILocalData<T>) => {
  if (internalData) {
    return internalData.total;
  }
  return 0;
};
