import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import endpoints from '../../api/endpoints';
import { IUserProfileData } from '../../features/sliceUserProfile/UserProfileInitialState';

export const personalInfoApi = createApi({
  reducerPath: 'userPersonalInfo',
  tagTypes: ['PersonalInfo'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getPersonalInfo: build.query<IUserProfileData, any>({
      query: () => ({
        url: endpoints.userProfile,
      }),
      providesTags: ['PersonalInfo']
    }),

    updatePersonalInfo: build.mutation<any, IUserProfileData>({
      query: (body:IUserProfileData) => ({
        url: endpoints.userProfile,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['PersonalInfo']
    }),
  }),
});
export const {
  useGetPersonalInfoQuery,
  useUpdatePersonalInfoMutation
} = personalInfoApi;
