import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import MediaQuery from 'react-responsive';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { getRowId } from '../getRowId';
import TableCustomHeadCell from './TableCustomHeadCell';
import { TableFooterDesktop } from './TableFooterDesktop';
import {
  ColSortDirections,
  ICollapseProps,
  IColumnsProps,
  ICurrentRowTotalValue,
  IEvents, IGridDataResponse,
  IStyleProps, ITableChildren,
  ITablePropsPositionElement
} from '../gridInterfaces';
import ErrorBoundary from '../../../containers/ErrorBoundary';
import TableCollapseRow from './TableCollapseRow';

import { useStyles } from '../styles';
import EmptyTable from './EmptyTable';
import { defaultStyleEffects } from '../defaultValues';
import { convertToCurrency } from '../utils/convertToCurrency';

interface IGridDesktopProps<T> extends ICurrentRowTotalValue, ITableChildren {
  configureDataForExel: boolean;
  data: IGridDataResponse<T> | null,
  events?: IEvents;
  collapseProps?: ICollapseProps<T>;
  sortable?: boolean;
  styleProps?: IStyleProps;
  globalId?: number | null;
  activeCol: keyof T | null;
  columns: IColumnsProps<T>[];
  isVisibleTableFooter?: boolean;
  tableHeadComponent?: ReactNode;
  keyExtractor: (item: T) => string;
  activeColDirection: ColSortDirections;
  getCurrentCol: (el: keyof T) => void;
  tableProps?: ITablePropsPositionElement;
}
interface ICellHead {
  type: 'string';
  value: string;
}
const GridDesktop = <T extends unknown>({
  configureDataForExel,
  collapseProps,
  events,
  data,
  keyExtractor,
  columns,
  isVisibleTableFooter = false,
  tableProps,
  getCurrentCol,
  styleProps,
  activeColDirection,
  activeCol,
  sortable = false,
  children,
  globalId = null,
  tableHeadComponent,
  getCurrentRowTotalValue,
}:IGridDesktopProps<T>) => {
  const classes = useStyles();
  const [activeRow, setActiveRow] = useState<number | null>(null);

  const { isSelectedEven, isSelectedOdd, classNameForTableHead, classNameForTableCellChildren, isHover, classNameForTableBody } = {
    ...defaultStyleEffects,
    ...styleProps
  };

  const toggleRow = useCallback((i: number) => {
    if (i === activeRow) {
      setActiveRow(null);
    } else {
      setActiveRow(i);
    }
  }, [activeRow]);

  const forTitle = (title: string): string => title.charAt(0).toUpperCase() + title.slice(1);

  useEffect(() => {
    if (configureDataForExel) {
      console.log({ configureDataForExel });
      if (columns.length && data?.result.length) {
        const cellHead:ICellHead[] = [];
        columns.forEach((item) => {
          if (item.title) {
            cellHead.push({
              type: 'string',
              value: item.title as string
            });
            return;
          }
          cellHead.push({
            type: 'string',
            value: forTitle(item.field as string)
          });
        });
      }
    }
  }, [configureDataForExel, columns, data]);

  if (!data) {
    return (
       <TableBody
          className={clsx(classNameForTableBody, {
            [classes.even]: isSelectedEven,
            [classes.odd]: isSelectedOdd
          })}
       >
          <TableRow>
             <TableCell>
                <EmptyTable />
             </TableCell>
          </TableRow>
       </TableBody>
    );
  }

  return (
     <MediaQuery minWidth={1024}>
        {!tableHeadComponent && !tableProps?.hideTableHead && (
        <TableHead
           className={clsx(classes.table_head, classNameForTableHead)}
        >
           <TableRow>
              {columns.map((col, index) => (
                 <TableCustomHeadCell<T>
                    hideCurrentSortable={col.hideCurrentSortable}
                    field={col.field}
                    sortable={sortable}
                    activeCol={activeCol}
                    key={`${index.toString()}_${col.field}`}
                    getCurrentCol={getCurrentCol}
                    align={col.align || 'inherit'}
                    width={col.width && col.width}
                    title={col.title !== 'empty' ? (col.title || col.field) : 'empty'}
                    className={clsx(col.className)}
                    activeColDirection={activeColDirection}
                 />
              ))}
           </TableRow>
        </TableHead>
        )}

        <TableBody
           className={clsx(classNameForTableBody, {
             [classes.even]: isSelectedEven,
             [classes.odd]: isSelectedOdd
           })}
        >
           {(tableProps && tableProps.toTheTopOfTable) && tableProps.toTheTopOfTable}

           {!tableProps?.hideTableBody ? data?.result.map((item: T, iNumb: number) => (
              <ErrorBoundary key={`${keyExtractor(item)}_${iNumb.toString()}`}>
                 <TableRow
                    key={`${keyExtractor(item)}_${iNumb.toString()}`}
                    hover={isHover || !!events?.rowClick}
                    onClick={() => {
                      // for outside click
                      if (events?.rowClick) {
                        events.rowClick(item);
                      }
                      // for toggle collapse
                      if (collapseProps && collapseProps.render) {
                        toggleRow(iNumb);
                      }
                    }}
                    className={clsx({
                      [classes.pointer]: (events && events.rowClick) || (collapseProps && collapseProps.render),
                      [classes.collapse_mode]: collapseProps && collapseProps.render, // for show triangle into first td
                      [classes.open_row_collapse]: iNumb === activeRow, // for toggle visible next tr
                    })}
                 >
                    {columns.map((col, index) => {
                      if ((globalId && getRowId(columns, item)) && (getRowId(columns, item) === globalId)) {
                        return (
                           <TableCell
                              key={col.field as string || index.toString()}
                              align={col?.align}
                              {...(col?.width) && {
                                width: col.width
                              }}
                              className={clsx(col?.className)}
                           >
                              {col.editTemplate && col.editTemplate(item)}
                           </TableCell>
                        );
                      }
                      return (
                         <TableCell
                            align={col?.align}
                            {...(col?.width) && {
                              width: col.width
                            }}
                            className={clsx(col?.className)}
                            key={`${col.field as string}-${index.toString()}` || index.toString()}
                         >
                            {typeof col.template === 'function' && col.template(item)}
                            {(typeof col.template !== 'function') && (col.field ? convertToCurrency({
                              value: item[col.field],
                              isCurrency: !!col?.isCurrency
                            }) : '-')}
                         </TableCell>
                      );
                    })}
                 </TableRow>
                 {/* ----------------------------  for collapse Mode */}
                 {collapseProps && collapseProps.render ? (
                    <TableCollapseRow
                       colspan={columns.length}
                       renderChild={collapseProps.render(item)}
                    />
                 ) : null}
              </ErrorBoundary>
           )) : null}
           {children && (
           <TableRow>
              <TableCell
                 colSpan={columns.length}
                 style={{ padding: 0 }}
                 className={clsx(classNameForTableCellChildren)}
              >
                 {children}
              </TableCell>
           </TableRow>
           )}

           {/* ----------   for visible Total cell */}
           {isVisibleTableFooter && data?.result.length ? (
              <TableFooterDesktop
                 columns={columns}
                 data={data}
                 getCurrentRowTotalValue={getCurrentRowTotalValue}
              />
           ) : null}
           {(tableProps && tableProps.atTheEndOfTable) && tableProps.atTheEndOfTable}
        </TableBody>
     </MediaQuery>
  );
};

export default GridDesktop;
