import * as yup from 'yup';
// import { regExp } from '@monorepo/common';

export const schema:any = yup.object().shape({
  company: yup.string().required('Required').nullable(),
  guid: yup.string().required('Required').nullable(),
  phone: yup.string().nullable(),
  email: yup.string().nullable(),
  contactUrl: yup.string().url('must be a valid URL').nullable(),
  overview: yup.string().nullable(),
  website: yup.string().nullable(),
  facebook: yup.string().nullable(),
  twitter: yup.string().nullable(),
  instagram: yup.string().nullable(),

});
