import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { CardWrapper } from '@monorepo/common';
import FinalBlock from '../Invoice/components/FinalBlock';

const InvoiceCompleted = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const tempDataGuid = params.get('guid');
  const paymentIntentId = params.get('payment_intent');
  const redirectStatus = params.get('redirect_status');

  return (
    <CardWrapper>
      {redirectStatus === 'succeeded' && (
      <FinalBlock
        paymentIntentId={paymentIntentId}
        tempDataGuid={tempDataGuid}
      />
      )}
      {redirectStatus !== 'succeeded' && (
        <p>Something went wrong. Please try again.</p>
      )}
    </CardWrapper>
  );
};

export default memo(InvoiceCompleted);
