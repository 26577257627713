import { memo } from 'react';
import { Btn, CardWrapper, Grid, baseUrls } from '@monorepo/common';
import moment from 'moment';
import { pressReleasesApi } from '../../services/pressReleases/index';
import { IPressReleaseItem } from '../../interfaces';
import { useStyles } from './styles';
import { book } from '../../routers/books';
import { PressReleaseListingType } from '../../constants/pressReleaseListingType';
import { PressReleaseStatus } from '../../constants/pressReleaseStatus';
import TableTitle from './components/TableTitle';

const PressReleases = () => {
  const classes = useStyles();

  const onUpdateBtnClick = (guid: string) => {
    window.open(`${baseUrls.pressReleases}/update/${guid}`, '_blank');
  };

  return (
    <CardWrapper
      outsideHead={{
        title: 'Press Releases',
        childrenTitleRight: (
          <Btn
            link={book.submit.pressReleases.create}
            external
          >
            Submit Press Release
          </Btn>
        ),
      }}
      helmet="Press Releases"
      maxWidth="90rem"
    >
      <Grid<IPressReleaseItem>
        keyExtractor={(col) => col.id as string}
        apiProps={{
          api: pressReleasesApi,
          apiMethodName: 'useLazyFetchPressReleasesQuery',
        }}
        defaultGridProps={{
          defaultPageSize: 10,
        }}
        searchableProps={{
          showSearchField: true,
        }}
        columns={[
          {
            title: 'Title',
            field: 'title',
            template: (col) => (
              <TableTitle
                title={col.title}
                slug={col.slug}
                isBevnet={col.isBevnet}
                isBrewbound={col.isBrewbound}
                isNosh={col.isNosh}
                status={col.status}
              />
            ),
          },
          {
            title: 'Status',
            field: 'status',
            template: (col) => (PressReleaseStatus[col.status]),
          },
          {
            title: 'Posted Date',
            field: 'postedDate',
            template: (col) => (moment(col.postedDate).format('MM/DD/YYYY')),
          },
          {
            title: 'empty',
            field: 'lastCol',
            template: (col) => (
              <div className={classes.actions}>
                <Btn
                  type="button"
                  click={() => onUpdateBtnClick(col.guid)}
                >
                  Update
                </Btn>
                {col.listingTypeId === PressReleaseListingType.Standard
                  && (
                  <Btn
                    link={book.pressReleases.upgrade(col.guid)}
                  >
                    Upgrade to Promoted
                  </Btn>
                  )}
              </div>
            ),
          },
        ]}
      />
    </CardWrapper>
  );
};

export default memo(PressReleases);
