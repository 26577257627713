import { makeStyles } from '@material-ui/core';
import { Mixin } from '@monorepo/common';

// '@media(max-width: 768px)': {}
export const useStyles = makeStyles(() => ({
  field: {
    marginBottom: '2rem'
  },
  line: {
    marginBottom: '2rem'
  },
  list: {
    marginBottom: '2rem',
    listStyle: 'none',
    '& li': {
      marginBottom: 10,
      ...Mixin.mixin.flex(),
      '&:before': {
        content: '""',
        display: 'block',
        marginRight: 10,
        ...Mixin.mixin.whSize(0.5, 0.5),
        background: '#000',
        borderRadius: '50%'
      }
    }
  }
}));
