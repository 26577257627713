import React, { memo, useEffect, useState } from 'react';
import { Card } from '@mui/material';
import { currencyFormatter, Grid, Loader } from '@monorepo/common';
import { invoiceApi, useGetInvoiceDetailsFromTempDataQuery, useConfirmInvoiceMutation } from '../../../services/serviceInvoice';
import { IConfirmInvoiceResponse, IQuotesDetail } from '../../../services/serviceInvoice/responseInterfaces';
import FinalBlockInfoList from './FinalBlockInfoList';

interface ITable extends IQuotesDetail{
  empty: string
}

interface IFinalBlockProps {
  tempDataGuid: string,
  paymentIntentId: string
}
const FinalBlock = ({ tempDataGuid, paymentIntentId }: IFinalBlockProps) => {
  const [confirmInvoiceData, setConfirmInvoiceData] = useState<IConfirmInvoiceResponse>();
  const { data } = useGetInvoiceDetailsFromTempDataQuery(tempDataGuid);
  const [confirmInvoice, { isLoading }] = useConfirmInvoiceMutation();

  useEffect(() => {
    async function process() {
      const confirmInvoiceResponse = await confirmInvoice({
        tempDataGuid,
        paymentIntentId
      });

      if (confirmInvoiceResponse) {
        const invoiceData = confirmInvoiceResponse as { data: IConfirmInvoiceResponse };
        setConfirmInvoiceData(invoiceData?.data);
      }
    }

    process();
  }, [confirmInvoice, tempDataGuid, paymentIntentId]);

  return (
    <Card
      sx={{
        p: 2
      }}
      variant="outlined"
    >
      <Loader isShow={isLoading} />
      <FinalBlockInfoList>
        {`${confirmInvoiceData?.firstName} ${confirmInvoiceData?.lastName}`}
        <br />
        {confirmInvoiceData?.company}
        <br />
        {confirmInvoiceData?.phone}
        <br />
        {confirmInvoiceData?.address1}
        <br />
        {confirmInvoiceData?.address2}
        <br />
        {confirmInvoiceData?.city}
        ,
        {confirmInvoiceData?.province && `${confirmInvoiceData.province},`}
        {confirmInvoiceData?.state && `${confirmInvoiceData.state},`}
        {confirmInvoiceData?.zipCode}
        {' '}
        {confirmInvoiceData?.country}
      </FinalBlockInfoList>

      <Grid<ITable>
        searchableProps={{
          showSearchField: false
        }}
        keyExtractor={(col) => `col-id-${col.quoteDetailId}`}
        apiProps={{
          api: invoiceApi,
          apiMethodName: 'useLazyGetQuotesDetailsQuery',
          apiParams: {
            id: data?.quoteId
          },
          isSkip: !data,
        }}
        columns={[
          {
            field: 'partNumber',
            title: 'Part #',
            template: (col) => col.partNumber
          },
          {
            field: 'quoteDetailId',
            title: 'Description',
            template: (col) => (
              <>
                <p>_INVOICE PAYMENT</p>
                <p>
                  {col.details}
                </p>
              </>
            )
          },
          {
            field: 'price',
            title: 'Price',
            template: (col) => currencyFormatter.format(col.price)
          },
          {
            field: 'extPrice',
            title: 'Ext Price',
            template: (col) => currencyFormatter.format(col.extPrice),
            footerProps: {
              showTotalAmount: true
            }
          }

        ]}
      />
    </Card>
  );
};
export default memo(FinalBlock);
