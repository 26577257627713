import React, { memo, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from '../styles';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';

interface FileImageProps {
  index: number;
  className?: string;
  src: string | undefined,
  removeLabel?: string,
  needConfirm?: boolean,
  confirmText?: string,
  onImageRemove?: (i: number) => void;
  onFileRemove: (status: boolean) => void
}
const FileImage = ({ src, onImageRemove, index, className, removeLabel = 'Remove  photo', needConfirm, confirmText, onFileRemove }:FileImageProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);

  const cancel = () => {
    onFileRemove(true);

    if (onImageRemove) {
      onImageRemove(index);
    }
  };

  const eventCancel = () => {
    if (!needConfirm) {
      cancel();
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (confirm) {
      setConfirm(false);
      cancel();
    }
  }, [confirm]);

  return (
     <>
        <div
           className={clsx(classes.wrapper_image, className)}
        >
           <img
              src={src}
              alt=""
           />
        </div>

        <Button
           variant="text"
           onClick={eventCancel}
           className={classes.removeBtn}
        >
           {removeLabel}
        </Button>
        <ConfirmDialog
           open={open}
           setOpen={setOpen}
           setConfirm={setConfirm}
           confirmText={confirmText}
        />
     </>
  );
};

export default memo(FileImage);
