import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Button, Divider, Typography } from '@mui/material';

import { FormAutocomplete, FormField, Loader, scrollUp, useHookForm } from '@monorepo/common';

import { useStyles } from '../styles';
import { useAccessStatus } from '../../../../hook/useAccessStatus';
import {
  useGetBrandInfoQuery,
  useGetProductDetailsQuery,
  useGetProductSubBrandsQuery,
  useUpdateProductDetailsMutation
} from '../../../../services/serviceBrands';
import { ProductDetailsResponse, ProductSuBrandsOption } from '../../../../services/serviceBrands/serviceBrandsInterface';
import { schema } from '../../EditProductInfo/schema';
import { showNotification } from '../../../../features/sliceNotification';
import { Lock } from './Lock';

interface IProductCardBodyProps {
  bguid: string;
  isShow: boolean;
  isActive: boolean;
  productName: string;
  productGuid: string;
  handlerChangeStatus: () => void,
  handleOpenProduct: (value: string) => void,
}

export const ProductCardBody = ({ bguid, isShow, handlerChangeStatus, handleOpenProduct, productName, productGuid, isActive }:IProductCardBodyProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoadingAccessStatus, brandGuid, isRequest, noAccessComponent, hideContent } = useAccessStatus();
  const { data: productInfo, isLoading: isLoadingProductInfo } = useGetProductDetailsQuery({ brandGuid, productGuid }, {
    skip: !isRequest
  });
  const { data: brandInfo, isLoading: isLoadingBrandInfo } = useGetBrandInfoQuery({ brandGuid }, {
    skip: !isRequest
  });
  const { data: options, isLoading: isLoadingSubBrands } = useGetProductSubBrandsQuery({ brandGuid }, {
    skip: !isRequest
  });

  const [updateProductDetails, { isLoading: isUpdatingInfo, isSuccess: isUpdated }] = useUpdateProductDetailsMutation();
  const {
    handleSubmit,
    setFormData,
    register,
    getValues,
    setValue,
    errors
  } = useHookForm<ProductDetailsResponse>({
    schema
  });

  const onSubmit = (data:ProductDetailsResponse) => {
    data.subBrand = data.subBrand || null;
    updateProductDetails({
      brandGuid,
      productGuid,
      body: data
    });
  };

  useEffect(() => {
    if (productInfo) {
      setFormData(productInfo);
    }
    if (isUpdated && brandInfo) {
      if (brandInfo.authLevel === 2) {
        scrollUp();
        dispatch(showNotification({
          show: true,
          type: 'success',
          text: `Product "${productName}" was edited!`
        }));
        return;
      }
      scrollUp();
      dispatch(showNotification({
        show: true,
        type: 'info',
        text: 'Thank you for your update.  The BevNET brand listing has been updated.'
      }));
    }
  }, [isUpdated, scrollUp, brandInfo, productInfo]);

  if (!isShow || hideContent || !!noAccessComponent) {
    return null;
  }

  return (
    <>
      <Loader isShow={isUpdatingInfo || isLoadingSubBrands || isLoadingBrandInfo || isLoadingProductInfo || isLoadingAccessStatus} />
      <div className={clsx(classes.card_content)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isActive ? (
            <div className={classes.body}>
              <Typography variant="h6">
                Basic details
              </Typography>
              <Divider sx={{
                mt: 2,
                mb: 4
              }}
              />

              <div className={classes.fields_line}>
                <FormField
                  type="textarea"
                  errors={errors}
                  label="Ingredients"
                  extraProps={{ ...register('ingredients') }}
                />
                <FormField
                  type="textarea"
                  errors={errors}
                  label="Nutritional Info"
                  extraProps={{ ...register('nutritionalInfo') }}
                />
              </div>
              <FormAutocomplete<ProductSuBrandsOption>
                optionsSettings={{
                  optionText: 'name',
                  optionValue: 'id'
                }}
                options={(productInfo && options) || []}
                label="Sub Brand"
                errors={errors}
                extraProps={{ ...register('subBrand') }}
                setValue={setValue}
                getValues={getValues}
              />
            </div>
          )
            : (<Lock />)}

          <div className={classes.card_content_footer}>
            <div className={classes.buttons_line}>
              {isActive && (
              <Button
                fullWidth
                type="submit"
                variant="contained"
              >
                Update
              </Button>
              )}
              <Button
                fullWidth
                type="button"
                variant="outlined"
                onClick={() => handleOpenProduct(bguid)}
              >
                Cancel
              </Button>
            </div>

            <Button
              variant="text"
              color="error"
              type="button"
              fullWidth
              onClick={handlerChangeStatus}
            >
              {isActive ? 'Delete product' : 'Return product'}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
