import {
  Box,
  Divider,
  Table, TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { memo } from 'react';

import { CardWrapper, ErrorBoundary } from '@monorepo/common';

import EmptyTable from '../../components/EmptyTable';
import { IUserOrders } from '../../features/sliceUserProfile/UserProfileInitialState';
import { orderHistoryApi } from '../../services/orderHistory';
import OrderTableLine from './components/OrderTableLine';
import { useStyles } from './styles';

const tableHeadList: string[] = [
  'Date',
  'Order #',
  'Company/Name',
  'Total',
  ''
];

// interface UserOrdersProps {}
const Orders = () => {
  const classes = useStyles();
  const { data, isLoading } = orderHistoryApi.useGetQuery('');
  return (
    <CardWrapper
      isLoading={isLoading}
      titleHelmet="Order History"
    >
      <Divider />
      <div className={classes.wrapper_for_table}>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeadList.map((item:string) => (
                  <TableCell
                    key={item}
                    style={{ borderBottom: '1px solid #E6E8F0' }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.orders.length ? data.orders.map((item:IUserOrders) => (
                <ErrorBoundary key={item.guid}>
                  <OrderTableLine
                    date={item.date}
                    orderId={item.orderId}
                    companyName={item.companyName}
                    total={item.total}
                    guid={item.guid}
                  />
                </ErrorBoundary>
              )) : <EmptyTable colSpan={4} />}

            </TableBody>
          </Table>
        </Box>
      </div>
    </CardWrapper>
  );
};

export default memo(Orders);
