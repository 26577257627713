import React from 'react';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

interface ICountryAutocompleteNotificationProps {
  text: string,
  isShow: boolean
  close: () => void,
}
export const StateAutocompleteNotification = ({ close, isShow, text }:ICountryAutocompleteNotificationProps) => (
   <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isShow}
      onClose={close}
   >
      <Alert severity="error">{text}</Alert>
   </Snackbar>
);
