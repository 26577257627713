import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { CardWrapper, FormFieldUpload, scrollUp, Btn } from '@monorepo/common';

import {
  useGetBrandInfoQuery,
  useGetBrandProductsQuery,
  useUpdateProductPhotoMutation
} from '../../../services/serviceBrands';
import UploadProductPhotosInfo from './components/UploadProductPhotosInfo';
import { showNotification } from '../../../features/sliceNotification';
import { useAccessStatus } from '../../../hook/useAccessStatus';
import { book } from '../../../routers/books';
import { useStyles } from './styles';
import { customButtonStyles } from '../../../constants/customButtonStyles';

// interface IUploadLogoProps {}
const UploadLogo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [productName, setProductName] = useState<string>('');
  const [productPhoto, setProductPhoto] = useState<string | null>(null);
  const { isLoadingAccessStatus, brandGuid, isRequest, productGuid, noAccessComponent, hideContent } = useAccessStatus();

  const { data: brandInfo, isLoading } = useGetBrandInfoQuery({ brandGuid }, {
    skip: !isRequest
  });
  const { data: brandProducts, isLoading: brandProductsLoading } = useGetBrandProductsQuery({ brandGuid }, {
    skip: !isRequest
  });
  const [updateProductPhoto, { isLoading: isSending, isSuccess }] = useUpdateProductPhotoMutation();

  const [fileLogo, setFileLogo] = useState<File | null>(null);

  const change = (dataFile:File | File[]) => {
    if (dataFile) {
      setFileLogo(dataFile as File);
      return;
    }
    setFileLogo(null);
  };

  const updateLogo = () => {
    if (fileLogo) {
      const formData = new FormData();
      formData.append('photo', fileLogo);
      updateProductPhoto({
        brandGuid,
        productGuid,
        body: formData
      });
    }
  };

  useEffect(() => {
    if (brandProducts) {
      const res = brandProducts.products.find((item) => item.productGuid === productGuid);
      setProductName(res.productName);
      setProductPhoto(res.imageUrl);
    }
  }, [brandProducts, productGuid]);

  useEffect(() => {
    if (isSuccess) {
      scrollUp();
      dispatch(showNotification({
        show: true,
        type: 'success',
        text: 'Photo was updated!'
      }));
    }
  }, [isSuccess]);

  return (
    <CardWrapper
      isHideChildren={hideContent}
      noAccessComponent={noAccessComponent}
      isLoading={isLoading || brandProductsLoading || isSending || isLoadingAccessStatus}
      titleHelmet={noAccessComponent ? 'No Access' : `Upload Product Logo: ${productName}`}
      outsideHead={{
        breadcrumbs: [
          {
            title: `${brandInfo?.brandName}`,
            link: book.brands.brandOptions(brandGuid)
          },
          {
            title: 'Products',
            link: book.brands.editProducts(brandGuid)
          },
          {
            title: `Upload Product Logo: ${productName}`
          }
        ]
      }}
    >
      <UploadProductPhotosInfo />

      {(productPhoto || fileLogo) && (
      <Typography
        variant="body1"
        marginTop={2}
        marginBottom={1}
      >
        Current photo
      </Typography>
      )}
      <FormFieldUpload
        onChange={change}
        formats={['jpg', 'jpeg', 'gif', 'png']}
        defaultValue={productPhoto}
        classNameForWrap={classes.field}
      />

      <Btn
        disabled={!fileLogo}
        click={updateLogo}
        customStyles={customButtonStyles}
      >
        Upload
      </Btn>
    </CardWrapper>
  );
};

export default UploadLogo;
