import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { CardWrapper, FormField, FormAutocomplete, Btn, useHookForm } from '@monorepo/common';

import { CreateProductProps, IProductTypeOption } from '../../../services/serviceBrands/serviceBrandsInterface';
import { useCreateProductMutation, useGetBrandInfoQuery, useGetProductTypesQuery } from '../../../services/serviceBrands';
import { showNotification } from '../../../features/sliceNotification';
import { useAccessStatus } from '../../../hook/useAccessStatus';
import { book } from '../../../routers/books';
import { schema } from './schema';

import { useStyles } from './styles';
import { customButtonStyles } from '../../../constants/customButtonStyles';

// interface ICreateProductProps {}
const CreateProduct = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { accessLevelNumber, isLoadingAccessStatus, brandGuid, noAccessComponent, hideContent, isRequest } = useAccessStatus();
  const [nameForNewProduct, setNameForNewProduct] = useState<string | null>(null);
  const { data: dataOptions, isLoading: isLoadingOptions } = useGetProductTypesQuery(null);
  const [createProduct, { isLoading, isSuccess }] = useCreateProductMutation();
  const { data: brandInfo, isLoading: isLoadingGetBrand } = useGetBrandInfoQuery({ brandGuid }, {
    skip: !isRequest
  });
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    errors
  } = useHookForm<CreateProductProps>({
    schema
  });
  const onSubmit = (data:CreateProductProps) => {
    if (data && accessLevelNumber === 2) {
      setNameForNewProduct(data.title);
      createProduct({
        body: data,
        brandGuid
      });
    }
  };

  useEffect(() => {
    if (isSuccess && nameForNewProduct) {
      dispatch(showNotification({
        show: true,
        type: 'success',
        text: `Product "${nameForNewProduct}" was saved!`
      }));
      history.push(book.brands.editProducts(brandGuid));
    }
  }, [isSuccess, brandGuid, nameForNewProduct]);

  return (
    <CardWrapper
      maxWidth="45rem"
      isHideChildren={hideContent}
      noAccessComponent={noAccessComponent}
      titleHelmet={noAccessComponent ? 'No Access' : 'Add New Product'}
      isLoading={isLoadingOptions || isLoading || isLoadingAccessStatus || isLoadingGetBrand}
      outsideHead={{
        breadcrumbs: [
          {
            title: `${brandInfo?.brandName}`,
            link: book.brands.brandOptions(brandGuid)
          },
          {
            title: 'Add New Product'
          }
        ]
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={classes.line}>
          <FormField
            isRequired
            errors={errors}
            label="Product Name"
            extraProps={{ ...register('title') }}
          />
          <FormAutocomplete<IProductTypeOption>
            selectSettings={{
              multiple: true
            }}
            optionsSettings={{
              optionValue: 'value',
              optionText: 'text'
            }}
            isRequired
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            extraProps={{ ...register('typeIds') }}
            options={(dataOptions) || []}
            label="Product type"
          />

        </div>
        <Btn
          type="submit"
          customStyles={customButtonStyles}
        >
          Submit
        </Btn>
      </form>
    </CardWrapper>
  );
};

export default memo(CreateProduct);
