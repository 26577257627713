import React, { memo, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { IChildrenProps } from '../../interfaces';
import { useStyles } from './styles';

interface IEventItem {
  eventName: string,
  id?: string | number,
  attendeeId?: string | number,
}
interface ILocalListProps extends IChildrenProps{
  list?: IEventItem[],
  tagLink?:boolean,
  linkHref?: string,
  activeItem?: string,
  classNameForList?: string,
  onChoose?: (el:string | null, id: number | string | null) => void,
}

const RenderList = ({
  list = [],
  onChoose,
  activeItem,
  classNameForList,
  tagLink,
  linkHref,
  children
}:ILocalListProps) => {
  const classes = useStyles();
  const [link, setLink] = useState<string | null>(null);
  const [eventId, setEventId] = useState<string | number | null>(null);

  const chooseEvent = (el:string, idElement?: number | string) => {
    setLink(el);
    if (idElement) {
      setEventId(idElement);
    }
  };
  useEffect(() => {
    if (activeItem) {
      setLink(activeItem);
    }
  }, [activeItem]);

  useEffect(() => {
    if (onChoose && link && eventId) {
      onChoose(link, eventId);
    }
  }, [link, eventId]);

  const escEvent = useCallback((event) => {
    if (event.keyCode === 27) {
      setLink(null);
      setEventId(null);
      if (onChoose) {
        onChoose(null, null);
      }
    }
  }, [link, onChoose]);

  useEffect(() => {
    document.addEventListener('keydown', escEvent, false);

    return () => {
      document.removeEventListener('keydown', escEvent, false);
      setEventId(null);
      setLink(null);
    };
  }, []);

  return (
     <ul className={clsx(classes.list, classNameForList)}>
        {children || (
          list && list.length ? list.map((item:IEventItem) => (
             <li
                className={clsx({
                  [classes.active]: link && link === item.eventName,
                  [classes.link]: tagLink,
                })}
                key={item.id || item.attendeeId}
                onClick={() => {
                  if (!tagLink && !linkHref) {
                    if (item.id) {
                      chooseEvent(item.eventName, item.id);
                    } else {
                      chooseEvent(item.eventName, item.attendeeId);
                    }
                  }
                }}
                role="presentation"
             >
                {tagLink && linkHref ? (<NavLink to={`${linkHref}`}>{item.eventName}</NavLink>) : item.eventName}
             </li>
          )) : null
        )}
     </ul>
  );
};

export default memo(RenderList);
