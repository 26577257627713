import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from '../styles';

interface UserSocialProps {
  title: string,
  link: string | null,
  plug: string,
  isLink?: boolean
}
const UserSocial = ({ title, link, plug, isLink = true }:UserSocialProps) => {
  const classes = useStyles();
  return (
    <Typography
      color="textSecondary"
      variant="body1"
    >
      {title}
      :
      {' '}

      {link ? (
        <>
          {isLink ? (
            <a
              href={`${link}`}
              target="_blank"
              rel="noreferrer"
            >
              {link}
            </a>
          ) : (
            <b>
              {link}
            </b>
          )}
        </>

      ) : <span className={classes.plug}>{plug}</span>}
    </Typography>
  );
};
export default UserSocial;
