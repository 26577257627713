import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userProfile from '../features/sliceUserProfile';
import notification from '../features/sliceNotification';
import loadings from '../features/sliceLoading';
import checkEmail from '../features/sliceUserEmail';
import ui from '../features/ui/sliceMobileMenu';
import auth from '../features/sliceAuth';
import { middlewareHandler } from './middlewareList';

import { orderHistoryApi } from '../services/orderHistory';
import { publicProfileApi } from '../services/userProfile/public';
import { personalInfoApi } from '../services/userProfile/personal';
import { emailApi } from '../services/userProfile/email';
import { passwordApi } from '../services/userProfile/password';
import { menuApi } from '../services/menuSettings';
import { newsletterInfoApi } from '../services/newsletter';
import { brandsApi } from '../services/serviceBrands';
import { helpApi } from '../services/helpForm';
import { invoiceApi } from '../services/serviceInvoice';
import { jobsApi } from '../services/jobs';
import { pressReleasesApi } from '../services/pressReleases';

export const store = configureStore({
  middleware: (getDefaultMiddleware) => middlewareHandler(getDefaultMiddleware),
  reducer: {
    auth,
    loadingList: loadings,
    userProfile,
    notification,
    checkEmail,
    ui,
    [orderHistoryApi.reducerPath]: orderHistoryApi.reducer,
    [menuApi.reducerPath]: menuApi.reducer,
    [publicProfileApi.reducerPath]: publicProfileApi.reducer,
    [personalInfoApi.reducerPath]: personalInfoApi.reducer,
    [emailApi.reducerPath]: emailApi.reducer,
    [passwordApi.reducerPath]: passwordApi.reducer,
    [newsletterInfoApi.reducerPath]: newsletterInfoApi.reducer,
    [brandsApi.reducerPath]: brandsApi.reducer,
    [helpApi.reducerPath]: helpApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [jobsApi.reducerPath]: jobsApi.reducer,
    [pressReleasesApi.reducerPath]: pressReleasesApi.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
