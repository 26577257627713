import React, { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useGetAccessStatusQuery } from '../services/serviceBrands';

export const useAccessStatus = () => {
  const { brandGuid, productGuid } = useParams<{ brandGuid: string, productGuid?: string }>();
  const { data, isFetching, isLoading } = useGetAccessStatusQuery({ brandGuid }, {
    skip: !brandGuid
  });
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const [slug, setSlug] = useState<string | null>(null);
  const [accessLevelNumber, setAccessLevelNumber] = useState<number | null>(null);

  useEffect(() => {
    if (data) {
      const { accessLevel, brandSlug } = data;
      setSlug(brandSlug);
      setAccessLevelNumber(accessLevel);
      if (accessLevel === 2) {
        setIsRequest(!!brandGuid);
        // goBack();
        return;
      }
      setIsRequest(false);
      return;
    }
    setIsRequest(false);
    setSlug(null);
    setAccessLevelNumber(null);
  }, [data]);

  const noAccess = (n: number | null):null | ReactNode => {
    if (n !== null) {
      if (n === 0) {
        return (
          <>
            <Typography variant="h4">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              You don't have access!
            </Typography>
          </>
        );
      }
      return null;
    }
    return null;
  };

  const setToActiveRequest = () => {
    setIsRequest(true);
  };

  useEffect(() => {
    if (accessLevelNumber !== null && accessLevelNumber === 1) {
      window.location.href = `https://www.bevnet.com/brands/${slug}/claimpage`;
    }
  }, [accessLevelNumber, slug]);

  return {
    slug,
    accessLevelNumber,
    isRequest,

    brandGuid,
    productGuid,
    setToActiveRequest,
    hideContent: !!((accessLevelNumber === null || accessLevelNumber === 1 || accessLevelNumber === 0)),
    isLoadingAccessStatus: isFetching || isLoading,
    noAccessComponent: noAccess(accessLevelNumber),
  };
};
