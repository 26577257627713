import { makeStyles } from '@material-ui/core';
// import { mixin } from '../../utils/mixin';

// '@media(max-width: 768px)': {}
export const useStyles = makeStyles((theme) => ({
  error: {},
  error_text: {
    position: 'absolute',
    left: 0,
    bottom: '-23px',
    color: 'red'
  },
  stripe_field: {
    marginBottom: '2rem',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #E6E8F0',
    '&$error': {
      border: '1px solid red',
      marginBottom: '2.5rem',
      '& $stripe_field_label': {
        color: 'red'
      },
      '&:hover': {
        borderColor: 'red'
      },
    },
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },
    '& input:-webkit-autofill': {
      ' -webkit-text-fill-color': 'inherit !important',
      '-webkit-box-shadow': '0 0 0 100px #fff inset !important'
    }
  },
  stripe_field_label: {
    position: 'absolute',
    background: '#fff',
    padding: '0 6px',
    top: '-12px',
    left: 8,
    fontWeight: 400,
    fontSize: '0.7rem',
    zIndex: 1,
    color: 'rgba(0, 0, 0, 0.6)',
    pointerEvents: 'none',
    '& + div': {
      backgroundColor: '#fff !important',
      padding: '0 14px',
      borderRadius: 4
    }
  }
}));
