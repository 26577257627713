import { memo } from 'react';
import { useParams } from 'react-router-dom';
import StripeWrapper from './StripeWrapper';
import { useGetPressReleaseQuery } from '../../../services/pressReleases';
import Description from './Description';

const Upgrade = () => {
  const { guid } = useParams<{ guid: string }>();
  const { data } = useGetPressReleaseQuery({ guid });

  if (!data) {
    return null;
  }

  return (
    <>
      <Description
        companyTypeId={data.companyTypeId}
      />
      <StripeWrapper
        companyTypeId={data.companyTypeId}
        pressReleaseGuid={guid}
      />
    </>
  );
};

export default memo(Upgrade);
