import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { Btn, CardWrapper, FormFieldUpload, scrollUp } from '@monorepo/common';

import { useGetBrandInfoQuery, useGetBrandLogoQuery, useUploadLogoMutation, useRemoveLogoMutation } from '../../../services/serviceBrands';
import { customButtonStyles } from '../../../constants/customButtonStyles';
import { showNotification } from '../../../features/sliceNotification';
import { useAccessStatus } from '../../../hook/useAccessStatus';
import { book } from '../../../routers/books';
import { useStyles } from './styles';

// interface IUploadLogoProps {}
const UploadLogo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isLoadingAccessStatus, brandGuid, noAccessComponent, hideContent, isRequest } = useAccessStatus();

  const { data, isLoading: isLoadLogo } = useGetBrandLogoQuery({ brandGuid }, {
    skip: !isRequest
  });
  const { data: brandInfo, isLoading } = useGetBrandInfoQuery({ brandGuid }, {
    skip: !isRequest
  });
  const [uploadLogo, { isLoading: isSending, isSuccess, status, }] = useUploadLogoMutation();
  const [removeLogo, { isLoading: isSendingRemove, isSuccess: isSuccessRemove, status: statusRemove, }] = useRemoveLogoMutation();

  const [fileLogo, setFileLogo] = useState<File | null>(null);

  const change = (dataFile:File | File[]) => {
    if (dataFile) {
      setFileLogo(dataFile as File);
      return;
    }
    setFileLogo(null);
  };

  const updateLogo = () => {
    if (fileLogo) {
      const formData = new FormData();
      formData.append('logo', fileLogo);
      uploadLogo({
        brandGuid,
        body: formData
      });
    }
  };

  const removeLogoHandler = () => {
    removeLogo(brandGuid);
  };

  useEffect(() => {
    if (isSuccess && brandInfo) {
      if (brandInfo.authLevel === 2) {
        scrollUp();
        dispatch(showNotification({
          show: true,
          type: 'success',
          text: 'Logo was updated!'
        }));
        return;
      }

      scrollUp();
      dispatch(showNotification({
        show: true,
        type: 'info',
        text: 'Thank you for your update.  The BevNET brand listing has been updated.'
      }));
    }
  }, [isSuccess, status]);

  useEffect(() => {
    if (isSuccessRemove && brandInfo) {
      scrollUp();
      dispatch(showNotification({
        show: true,
        type: 'success',
        text: 'Logo has been deleted.'
      }));
    }
  }, [isSuccessRemove, statusRemove]);

  return (
    <CardWrapper
      isHideChildren={hideContent}
      noAccessComponent={noAccessComponent}
      isLoading={isLoading || isLoadLogo || isSending || isLoadingAccessStatus || isSendingRemove}
      titleHelmet={noAccessComponent ? 'No Access' : `Upload Brand Logo: ${brandInfo?.brandName}`}
      outsideHead={{
        breadcrumbs: [
          {
            title: `${brandInfo?.brandName}`,
            link: book.brands.brandOptions(brandGuid)
          },
          {
            title: 'Upload Brand Logo'
          }
        ]
      }}
    >
      {(data?.brandLogo || fileLogo) && (
      <Typography
        variant="body1"
        marginTop={2}
        marginBottom={1}
      >
        Current Logo
      </Typography>
      )}
      <FormFieldUpload
        onChange={change}
        formats={['jpg', 'jpeg', 'gif', 'png']}
        defaultValue={data?.brandLogo}
        classNameForWrap={classes.field}
        removeLabel="Remove Logo"
        onRemove={removeLogoHandler}
        confirmText="Are you sure to delete logo?"
        needConfirm
      />

      <Btn
        disabled={!fileLogo}
        customStyles={customButtonStyles}
        click={updateLogo}
      >
        Upload
      </Btn>
    </CardWrapper>
  );
};

export default UploadLogo;
