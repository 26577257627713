// @ts-nocheck

import * as React from 'react';
import { SVGProps } from 'react';

export const IconEmptyImage = (props: SVGProps<SVGSVGElement>) => (
   <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511.01 397.16"
   >
      <defs>
         <style>{'.cls-1{fill:#020202}'}</style>
      </defs>
      <g
         id="Layer_2"
         data-name="Layer 2"
      >
         <g
            id="Layer_1-2"
            data-name="Layer 1"
         >
            <g id="Me7INM.tif">
               <path
                  className="cls-1"
                  d="M0 365.64V27.18a11.08 11.08 0 0 1 .42-3C4.69 8.8 17.14 0 33.11 0h444.62C497.18.05 511 14 511 33.5q.06 164.9-.05 329.8a39.67 39.67 0 0 1-.32 5.34c-2.45 17.75-15.7 28.51-33.87 28.51H31.64c-12.49-.6-21.82-6.39-27.79-17.32a37.54 37.54 0 0 1-2.33-5.49 25.84 25.84 0 0 1-1.52-8.7Zm475-100.52a2.23 2.23 0 0 0 3.73-1.57v-1.22q0-111.94.1-223.89c0-4.47-1.05-5.89-5.75-5.88-144.85.13-436.92.24-438 .36a2.11 2.11 0 0 0-1.9 2.1v220.2a2.23 2.23 0 0 0 3.82 1.45q56.63-65.74 113.36-131.4a33.47 33.47 0 0 1 12.25-8.91c10.24-4.1 20-.29 28.44 9.8q61 72.83 122 145.64c.65.78 1.32 1.53 2 2.32a2.23 2.23 0 0 0 3.24.09c19.42-19.37 38.84-37.91 57.15-57.49 15.06-16.11 29.66-16.41 45.68-.77 17.16 16.7 35.27 32.39 53.88 49.17Z"
               />
               <path
                  className="cls-1"
                  d="M385.46 162.32a44.6 44.6 0 0 1-44.65-44.24c-.23-24.49 20-44.81 44.64-44.81a44.53 44.53 0 1 1 0 89.05Z"
               />
            </g>
         </g>
      </g>
   </svg>
);
