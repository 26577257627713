import React, { memo } from 'react';
import { Typography } from '@mui/material';

import { BasicLayout, currencyFormatter } from '@monorepo/common';

import { useStyles } from './styles';
import { useGetUpgradePriceQuery } from '../../../services/pressReleases';
import { PressReleaseListingType } from '../../../constants/pressReleaseListingType';

interface IDescriptionProps {
  companyTypeId: number
}

const Description = ({ companyTypeId }:IDescriptionProps) => {
  const classes = useStyles();

  const { data: getUpgradePriceResponse, isLoading } = useGetUpgradePriceQuery({
    listingTypeId: PressReleaseListingType.Standard,
    companyTypeId,
  });

  return (
    <BasicLayout isLoading={isLoading}>
      <div className={classes.description_page_wrapper}>
        <div className={classes.description_page}>
          <Typography
            sx={{ mb: 3 }}
            variant="h1"
            fontWeight={800}
          >
            Upgrade your PR
          </Typography>

          <p>
            A promoted press release appears at the top of the press release list and allows you to submit multiple images, customize hyperlink text, and schedule for your preferred date and time. Promoted press releases are also automatically transcribed to audio so our audience can either read or listen to your news.
          </p>
          <p>
            <strong>
              Use this form to upgrade your Press Release for
              {' '}
              {currencyFormatter.format(getUpgradePriceResponse?.price)}
              .
            </strong>
          </p>
        </div>
      </div>
    </BasicLayout>
  );
};

export default memo(Description);
