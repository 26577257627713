import { ColSortDirections } from '../gridInterfaces';

interface UpdateDirectionValue<T> {
  isFirstInit: boolean;
  setIsFirstInit: (val:boolean) => void;
  colSortBy: number;
  setActiveCol: (value: keyof T | null) => void;
  setActiveColDirection: (value: ColSortDirections) => void;
}

export const updateDirectionValue = <T, >({ colSortBy, setActiveColDirection, setActiveCol, isFirstInit, setIsFirstInit }:UpdateDirectionValue<T>) => {
  if (!isFirstInit) {
    if (colSortBy === 1) {
      setActiveColDirection('asc');
      return;
    }
    if (colSortBy === 2) {
      setActiveColDirection('desc');
      return;
    }
    setActiveCol(null);
    setActiveColDirection(null);
    return;
  }
  setIsFirstInit(false);
};
