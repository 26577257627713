import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import { TableCell, TableRow } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from '../styles';
import { isEvenNumber } from '../isEvenNumber';
import Btn from '../../Btn/Btn';

interface ITableCollapsePanelProps {
  index: number,
  colSpan: number,
  renderElement: ReactNode | ReactElement | string | null | number,
  toggle: (e:number | null) => void,
  activeId:number | null
}
const TableCollapsePanel = ({ index, toggle, renderElement, colSpan, activeId = null }:ITableCollapsePanelProps) => {
  const classes = useStyles();
  const [icon, setIcon] = useState<ReactNode>(<ExpandMoreIcon />);
  useEffect(() => {
    if (activeId === index) {
      setIcon(<ExpandLessIcon />);
      return;
    }
    setIcon(<ExpandMoreIcon />);
  }, [index, activeId]);

  return (
     <TableRow
        className={clsx(classes.td_equal, {
          [classes.selectedRow]: isEvenNumber(index)
        })}
     >
        <TableCell
           colSpan={colSpan}
           className={classes.td_collapse}
        >

           {activeId !== null && (
           <div className={clsx(classes.mobile_collapse_panel, {
             [classes.active]: index === activeId
           })}
           >
              <CloseIcon
                 className={classes.close}
                 onClick={() => toggle(null)}
              />
              {renderElement}
           </div>
           )}

           <Btn
              click={() => toggle(index)}
              iconProps={{ afterIconElement: icon }}
              fullWidth
           >
              Collapse
           </Btn>
        </TableCell>
     </TableRow>
  );
};

export default TableCollapsePanel;
