import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  label_exist: {},
  field_wrapper: {
    marginBottom: '2rem',
    position: 'relative',
    '& .MuiChip-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      color: '#000',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '7px 9px'
    },
    '& $fieldComment': {
      marginBottom: '1rem',
    },
    '&$label_exist': {
      '& .MuiFormLabel-filled, & .Mui-focused': {
        fontWeight: 'bold'
      },
    },
    '&:not($label_exist) legend span': {
      display: 'none'
    },
    '& label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -9px) scale(0.75) !important'
    }
  },
  hidden: {
    display: 'none',
  },
  fieldComment: {},
  hideClearButton: {
    '& .MuiAutocomplete-clearIndicatorDirty': {
      display: 'none !important',
    },
  },
}));
