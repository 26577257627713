import { makeStyles } from '@material-ui/core';
import { mixin } from '../../utils/mixin';

// '@media(max-width: 768px)': {}
const drawerWidth = 256;
const blue: string = '#111827';
const green: string = '#10b975';
const greenHover: string = '#0b8f5a';
const grey: string = '#2d3748';
const lightGrey: string = '#9CA3A6';
const activeColor:string = '#10b975';
const activeBg: string = 'rgba(255, 255, 255, .1)';
export const useStyles = makeStyles((theme) => ({
  appMenu: {},
  navList: {
    width: '100%',
  },
  activeHeadItem: {
    '&:hover': {
      background: activeBg,
    },
    '& span': {
      color: activeColor,
      fontWeight: 'bold'
    },
    '& svg': {
      fill: activeColor,
    },
  },
  menuItem: {
    width: '100%',
    color: '#9ca3af',
    borderBottom: 5,
    borderRadius: theme.shape.borderRadius,
    paddingTop: 5,
    '&:hover': {
      color: activeColor,
      borderRadius: theme.shape.borderRadius,
      background: activeBg,
      '& *': {
        color: activeColor,
      },
      '& svg': {
        fill: activeColor,
      },
    }
  },
  subItem: {
    display: 'block',
    width: '90%',
    marginLeft: 'auto',
    marginBottom: 5,
    '& $menuItem': {
      marginBottom: 0
    }
  },
  headSubMenu: {
    marginBottom: 5
  },
  menu_item_element: {},
  wrapper_for_dropdown: {
    '&::-webkit-scrollbar': { width: '10px', borderRadius: '2rem' },
    '&::-webkit-scrollbar-track': {
      borderRadius: '2rem',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '2rem',
    },
    '&>$wrapper_for_dropdown,&>$menu_item_element ': {
      // background: 'blue',
      width: '90%',
      marginLeft: 'auto'
    }
  },

  link: {
    ...mixin.flex(),
    color: 'inherit',
    textDecoration: 'none',
    borderRadius: theme.shape.borderRadius,
    marginBottom: 5,
    '&:hover': { backgroundColor: activeBg }
  },
  active: {
    color: activeColor,
    fontWeight: 'bold',
    borderRadius: theme.shape.borderRadius,
    background: activeBg,
    '& *': {
      color: activeColor,
      fontWeight: 'bold',
    }
  },
  menuItemIcon: {
    color: '#6b778c',
    marginRight: '1.4rem',
  },
  menuItemText: {},
  list_subheader: {
    color: 'text.primary',
    fontSize: '0.75rem',
    lineHeight: 2.5,
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: '0 0 0 1rem',
  },
  noIcon: {
    paddingLeft: '1rem',
  },
  wrapperIcon: {
    marginRight: '1rem',
    display: 'flex'
  },
  menu_group: {
    '& + $menu_group': {
      marginTop: 20
    }
  },
  menu_group_title: {
    color: '#65748B',
    textTransform: 'uppercase',
    fontSize: ' 0.75rem',
    fontWeight: 700,
    lineHeight: 2.5,
    padding: '0 14px'
  },
  field: {
    marginBottom: 20,
    '& input': {
      color: '#fff',
      paddingTop: 10,
    },
    '& > div:before': {
      borderColor: green,
    }
  },
  icon: {
    cursor: 'pointer',
    '& path': {
      fill: '#fff'
    }
  },
  search_icon: {
    cursor: 'pointer',
    '& path': {
      fill: '#fff'
    },
    '&:hover': {
      '& path': {
        fill: green
      }
    }

  },
  //  ----------------------------------------------------------
  active_item: {},
  menuOpen: {
    transform: 'scale(.95)',
    pointerEvents: 'none',
    filter: 'blur(5px)',
    zIndex: 0,
  },
  hide_left_side: {},
  menu_options: {},
  bottom_left_side_footer: {
    width: '100%',
    marginTop: 'auto'
  },
  bottom_left_side_line: {
    borderWidth: '0px 0px thin',
    borderStyle: 'solid',
    borderColor: grey
  },
  bottom_left_side_link_container: {
    padding: '16px',
    width: '100%'
  },
  bottom_left_side_text: {
    color: lightGrey,
    paddingBottom: '16px'
  },
  bottom_left_side_link: {
    color: blue,
    backgroundColor: green,
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    width: '100%',
    '&:hover': {
      backgroundColor: greenHover,
    },
  },
  left_side: {
    zIndex: 1202,
    width: drawerWidth,
    flexShrink: 0,
    transition: '.4s',
    background: blue,
    position: 'relative',
    transform: 'translateX(0px)',
    display: 'flex',
    flexDirection: 'column',
    '@media(min-width: 1025px)': {
      '&$hide_left_side': {
        transform: `translateX(-${drawerWidth}px)`,
        marginRight: `-${drawerWidth}px`
      }
    },
    '@media(max-width: 1024px)': {
      position: 'fixed',
      height: '100%',
      left: `-${drawerWidth + 100}px`,
      zIndex: 3,
      '&$active_item': {
        left: 0,
        width: '100%'
      }
    },
    '& nav': {
      paddingTop: 20,
      paddingBottom: '3rem',
      height: 'fitContent',
      width: '100%',
    },
    '& $menu_options': {
      minHeight: 64,
      position: 'relative',
      gridGap: 10,
      padding: '.7rem 22px',
      ...mixin.flex('center', 'flex-end')
    }
  },
  left_side_container: {
    overflow: 'auto',
    display: 'block',
    padding: '0 8px',
    maxHeight: 'calc(100vh - 220px)',
    /* width */
    '&::-webkit-scrollbar': {
      width: 7
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: 'silver',
      borderRadius: 20
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#10b975',
      borderRadius: 20
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  },
  menu_arrow: {},
  menu_button: {
    width: 30,
    position: 'relative',
    cursor: 'pointer',
    transition: '.4s transform',
    border: 0,
    background: 'none',
    transform: 'translateX(80px)',
    '& span': {
      width: '95%',
      display: 'block',
      background: '#fff',
      height: 3,
      margin: '5px 0',
      transition: '.4s',
      position: 'relative',
      '&:before': {
        content: '""',
        width: '100%',
        height: '100%',
        background: blue,
        display: 'block',
        transition: '.4s',
        position: 'absolute',
      },
      '&:nth-child(2)': {
        width: '112%',
      }
    },
    '& $menu_arrow': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      transition: '.4s',
      right: ' -13px',
      width: '13px',
      height: '13px',
      margin: 'auto',
      transform: 'rotate(45deg)',
      borderRight: '3px solid',
      borderTop: '3px solid',
      borderColor: blue
    },
    '&$active': {
      transform: 'translateX(0px)',
      '&:hover span:before': {
        background: green,
        width: '100%'
      },
      '&:hover $menu_arrow': {
        borderColor: green,
      },

      '& $menu_arrow': {
        borderColor: ' #fff',
        transform: 'rotate(-135deg)'
      },
      '& span:nth-child(2)': {
        width: '75%',
      },
      '& span': {
        width: '97%',
        '&:before': {
          width: '0',
        }
      }
    },
    '&:hover span:before': {
      background: green,
      width: '100%'
    },
    '&:hover $menu_arrow': {
      borderColor: green,
    },
  },
}));
