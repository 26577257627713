import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import endpoints from '../../api/endpoints';

export const newsletterInfoApi = createApi({
  reducerPath: 'newsletterInfo',
  tagTypes: ['NewsletterInfo'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getNewsletterInfo: build.query({
      query: () => ({
        url: endpoints.newsletterInfo,
      }),
      providesTags: ['NewsletterInfo']
    }),
    subscribeUser: build.mutation({
      query: (id) => ({
        url: `/user/NewsletterSubscribe?listId=${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['NewsletterInfo']
    }),
    unSubscribeUser: build.mutation({
      query: (id) => ({
        url: `/user/NewsletterUnsubscribe?listId=${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['NewsletterInfo']
    }),
  }),
});

export const {
  useGetNewsletterInfoQuery,
  useSubscribeUserMutation,
  useUnSubscribeUserMutation,
} = newsletterInfoApi;
