interface IPaginationProps<T> {
  page: number,
  pageSize: number,
  data: T[]
}
export const paginatedResults = <T, >({ data, page, pageSize }:IPaginationProps<T>) => {
  const internalData:T[] = [];
  const newData:T[] = internalData.concat(data);
  return newData.slice((page - 1) * pageSize, page * pageSize);
};
