import { makeStyles } from '@material-ui/core';

// '@media(max-width: 768px)': {}
export const useStyles = makeStyles((theme) => ({
  root: {},
  active: {},
  link: {
    transition: 'opacity .3s, transform .3s, margin-top .3s',
    transform: 'translateY(-15px)',
    opacity: 0,
    color: 'inherit',
    display: 'block',
    borderRadius: '5px',
    textDecoration: 'none',
    boxShadow: '0px 0px 7px 2px #00000014',
    '&:first-child': {
      marginTop: 0,
    },
    '&$active': {
      marginTop: 20,
      transform: 'translateY(0)',
      opacity: 1,
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
  },
  red: {
    color: theme.palette.info.light,
    fontSize: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.info.dark
    }
  },
}));
