import React, { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LeftSideWrapper } from './components/LeftSideWrapper';
import { ParentItem } from './components/ParentItem';
import { ICustomMenuProps, IMenu } from './interface';
import { MenuGroup } from './components/MenuGroup';
import { MenuItem } from './components/MenuItem';
import { filterMenu } from './filterMenu';
import Loader from '../Loader';

import { useStyles } from './styles';

const MenuNavigation = ({ menu, isLoading, options }:ICustomMenuProps) => {
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = useState<string>('');
  const [filteredMenu, setFilteredMenu] = useState<IMenu>({ menu: [] });

  useEffect(() => {
    if (value.length) {
      const result: IMenu = { menu: [] };
      filteredMenu.menu.forEach((item, i) => {
        result.menu.push({
          ...(item.groupTitle) && {
            groupTitle: item.groupTitle
          },
          menuItems: filterMenu(value, menu[i].menuItems) || []
        });
      });
      setFilteredMenu(result);
      return;
    }
    setFilteredMenu({ menu });
  }, [value, menu, location]);

  if (isLoading) {
    return <Loader isShow={isLoading} />;
  }

  return (
     <LeftSideWrapper
        {...options}
        setValue={setValue}
        value={value}
     >
        <nav className={classes.appMenu}>
           {filteredMenu.menu.map((menuGroup) => {
             if (menuGroup.groupTitle) {
               return (
                  <MenuGroup
                     key={menuGroup.groupTitle}
                     title={menuGroup.groupTitle}
                     menuItems={menuGroup.menuItems}
                  />
               );
             }
             return menuGroup.menuItems.map((item) => {
               if (!item.subMenus) {
                 return (
                    <MenuItem
                       key={item.href}
                       {...item}
                    />
                 );
               }
               return (
                  <ParentItem
                     {...item}
                     currentItem={item}
                     key={item.title}
                  />
               );
             });
           })}
        </nav>
     </LeftSideWrapper>
  );
};

export default memo(MenuNavigation);
