import React, { useEffect } from 'react';
import { Btn, CardWrapper } from '@monorepo/common';
import { Box, Divider, Table, TableCell, TableBody, TableHead, TableRow } from '@material-ui/core';
import {
  useGetNewsletterInfoQuery,
  useSubscribeUserMutation,
  useUnSubscribeUserMutation
} from '../../services/newsletter';
import { customButtonStyles } from '../../constants/customButtonStyles';
import { useActionAfterUpdatedData } from '../../hook/useActionAfterUpdatedData';

const tableHeadList: string[] = [
  'Newsletter',
  'Status',
  ''
];

const Newsletter = () => {
  const { data, isLoading } = useGetNewsletterInfoQuery('');
  const [SubscribeUser, { isLoading: isSubscribeLoading, isSuccess: isSuccessSubscribe }] = useSubscribeUserMutation();
  const [UnsubscribeUser, { isLoading: isUnSubscribeLoading, isSuccess: isSuccessUnsubscribe }] = useUnSubscribeUserMutation();
  const { dataWasUpdated } = useActionAfterUpdatedData();

  useEffect(() => {
    if (isSuccessSubscribe || isSuccessUnsubscribe) {
      dataWasUpdated({
        text: 'Your request has been submitted. Please note that it make take 1-2 hours for your request to be processed.'
      });
    }
  }, [isSuccessSubscribe, isSuccessUnsubscribe]);

  return (
    <CardWrapper
      titleHelmet="Newsletter Subscriptions"
      isLoading={isLoading || isSubscribeLoading || isUnSubscribeLoading}
    >
      <Divider />
      <div className="test">
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeadList.map((item:string) => (
                  <TableCell
                    key={item}
                    style={{ borderBottom: '1px solid #E6E8F0' }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.newsletters.length ? data.newsletters.map((item:any) => (
                <TableRow key={`${item.name}`}>
                  <TableCell style={{ borderBottom: '1px solid #E6E8F0' }}>
                    {item.name || '-'}
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #E6E8F0' }}>
                    {item.status || '-'}
                  </TableCell>
                  <TableCell style={{ borderBottom: '1px solid #E6E8F0' }}>
                    {item.status === 'Subscribed' && (
                    <Btn
                      click={() => UnsubscribeUser(item.mailchimpListId)}
                      customStyles={customButtonStyles}
                    >
                      Unsubscribe
                    </Btn>
                    )}
                    {item.status !== 'Subscribed' && (
                    <Btn
                      click={() => SubscribeUser(item.mailchimpListId)}
                      customStyles={customButtonStyles}
                    >
                      Subscribe
                    </Btn>
                    )}
                  </TableCell>
                </TableRow>
              )) : null}

            </TableBody>
          </Table>
        </Box>
      </div>
    </CardWrapper>
  );
};
export default Newsletter;
