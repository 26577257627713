import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import endpoints from '../../api/endpoints';

interface IRequest {
  Password:string
  ConfirmPassword: string
}

export const passwordApi = createApi({
  reducerPath: 'userPassword',
  tagTypes: ['Password'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    changePassword: build.mutation<boolean, IRequest>({
      query: (body:IRequest) => ({
        url: endpoints.userChangePassword,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Password']
    }),
  }),
});
export const { useChangePasswordMutation } = passwordApi;
