import { makeStyles } from '@material-ui/core';
import { Mixin } from '@monorepo/common';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '2rem',
    ...Mixin.mixin.flex('flex-start', 'center'),
    '@media(max-width: 1200px)': {
      display: 'block'
    }
  },
  wrapper_card: {
    padding: '40px 20px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius
  },
  description_page: {
    '& h1': {
      textAlign: 'center'
    },
    '@media(max-width: 1200px)': {
      maxWidth: '100%',
      marginTop: '2rem',
    },
    '& p, & b,& li': {
      fontSize: 16
    },
    '& p, & ul': {
      marginBottom: '1rem'
    }
  },
  table_wrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
    ...Mixin.mixin.br()
  },
  link: {
    color: theme.palette.error.main
  },
  wrapper_for_steps: {
    width: '100%',
    maxWidth: '50rem',
    '& .MuiAvatar-root': {
      backgroundColor: '#e21836'
    },
    '@media(max-width: 1200px)': {
      maxWidth: '100%',
    }
  },
  jobs_line_buttons: {
    marginTop: '2rem',
    ...Mixin.mixin.flex('center', 'flex-start'),
    minHeight: 30,
    '@media(max-width: 600px)': {
      flexDirection: 'column'
    }
  },
  previous_btn: {
    '@media(max-width: 600px)': {
      marginLeft: '0 !important',
      marginTop: ' 15px !important'
    }
  },
  active_step: {
    padding: '32px 8px 32px 20px',
    borderLeft: '2px solid rgb(230, 232, 240) !important',
    marginLeft: '20px !important'
  },
  stepper: {
    '& .MuiStepConnector-line': {
      ml: 1,
      borderLeftColor: 'divider',
      borderLeftWidth: 2
    }

  },
  step3_address_wrap: {
    ...Mixin.mixin.flex('flex-start', 'flex-start'),
    gridGap: '2rem',
    marginBottom: '2rem',
    '& > div': {
      flex: 1,
      marginBottom: 0,
      '@media(max-width: 768px)': {
        marginBottom: '2rem'
      }
    },
    '@media(max-width: 768px)': {
      display: 'block'
    }
  },
  stripe_field: {
    marginBottom: '2rem',
    position: 'relative',
    borderRadius: (theme.shape.borderRadius as number) / 2,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    }
  },
  stripe_field_label: {
    position: 'absolute',
    background: '#fff',
    padding: '0 6px',
    top: '-12px',
    left: 8,
    fontWeight: 400,
    fontSize: '0.8rem',
    zIndex: 1,
    color: 'rgba(0, 0, 0, 0.6)',
    pointerEvents: 'none'
  },
  card_99: {
    padding: '0 14px',
  },
  card_with_icon: {
    '& svg': {
      opacity: '1 !important',
      transform: 'scale(1) !important'
    }
  },

  // ---------------------------------
  step1_toolbar_wrapper: {
    marginBottom: 15
  },
  step1_modal_create_invoice_wrapper: {
    padding: 15
  },
  step1_wrapper_buttons: {
    ...Mixin.mixin.flex('center', 'center'),
    marginTop: 25,
    gridGap: 40,
    width: '100%'
  },
  text_divider: {
    textAlign: 'center',
    ...Mixin.mixin.flex('center', 'center'),
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '2rem 0 2.2rem !important',
    gridGap: '2rem',
    '&:before, &:after': {
      content: '""',
      display: 'block',
      height: 1,
      flex: 1,
      background: 'rgba(0, 0, 0, 0.2)',
    }
  },
  form_input: {
    margin: '15px 0',

    '& input': {
      '&.MuiInputBase-input': {
        fontWeight: 400,
        padding: '11.5px 14px',
        borderRadius: '5px!important',
        fontSize: '15px!important'
      },

      '&::placeholder': {
        fontWeight: 400
      },
    },

    '& .MuiOutlinedInput-root:hover fieldset': {
      borderColor: '#e6e6e6!important'
    },

    '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: '#BFDBF6!important',
      borderWidth: '4px'
    },

    '& .Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: '#df1b41',
      borderWidth: '2px'
    },

    '& label.MuiInputLabel-shrink.MuiInputLabel-formControl': {
      fontWeight: 400,
      color: '#252A34',
      fontSize: '15px',
      lineHeight: '15px',

      '&.Mui-error': {
        color: '#252A34',
        fontSize: '15px',
        lineHeight: '15px',
      }
    },

    '& p.MuiFormHelperText-root.Mui-error': {
      fontWeight: 400,
      marginLeft: '0',
      color: '#df1b41',
      fontSize: '15px',
      lineHeight: '16px',
    },

    '& label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      position: 'static',
      textAlign: 'left',
      marginBottom: '7px',
      transform: 'initial !important'
    },

    '& legend': {
      display: 'none'
    },

    '& fieldset': {
      top: 0
    },

    '@media(max-width: 830px)': {
      width: '100%',
      marginBottom: '16px'
    }
  }
}));

export const stripeFieldOptions = {
  showIcon: true,
  style: {
    iconStyle: 'solid',
    base: {
      padding: '15px',
      lineHeight: '56px',
    },
  }
};
