import React, { memo, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import TablePagination from '@mui/material/TablePagination';

import clsx from 'clsx';
import { useStyles } from './styles';

interface IPageCounterProps {
  change?: (event:any) => void,
  currentPage: number,
  total: number,
  pageSize: number,
  classNameForWrap?: string,
  classNameForDisplayedRows?: string,
}

const TablePaginationComponent = ({ total, currentPage, change, pageSize, classNameForWrap, classNameForDisplayedRows }:IPageCounterProps) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  useEffect(() => {
    if (change) {
      change(page);
    }
  }, [page]);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  return (
     <div className={clsx(classes.root, classNameForWrap)}>
        <Pagination
           className={classes.pagination_wrapper}
           count={Math.ceil(total / pageSize)}
           page={page}
           onChange={handleChange}
        />

        <TablePagination
           component="div"
           count={total}
           page={page - 1}
           onPageChange={() => {}}
           rowsPerPage={pageSize}
           rowsPerPageOptions={[]}
           onRowsPerPageChange={() => {}}
           nextIconButtonProps={{
             style: {
               display: 'none'
             }
           }}
           backIconButtonProps={{
             style: {
               display: 'none'
             }
           }}
           classes={{
             displayedRows: clsx(classes.count, classNameForDisplayedRows)
           }}
        />
     </div>
  );
};

export default memo(TablePaginationComponent);
