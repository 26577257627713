import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper_card: {
    padding: '40px 20px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    maxWidth: '720px',
    margin: '0 auto'
  },
  form_input: {
    margin: '15px 0',

    '& input': {
      '&.MuiInputBase-input': {
        fontWeight: 400,
        padding: '11.5px 14px',
        borderRadius: '5px!important',
        fontSize: '15px!important'
      },

      '&::placeholder': {
        fontWeight: 400
      },
    },

    '& .MuiOutlinedInput-root:hover fieldset': {
      borderColor: '#e6e6e6!important'
    },

    '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: '#BFDBF6!important',
      borderWidth: '4px'
    },

    '& .Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: '#df1b41',
      borderWidth: '2px'
    },

    '& label.MuiInputLabel-shrink.MuiInputLabel-formControl': {
      fontWeight: 400,
      color: '#252A34',
      fontSize: '15px',
      lineHeight: '15px',

      '&.Mui-error': {
        color: '#252A34',
        fontSize: '15px',
        lineHeight: '15px',
      }
    },

    '& p.MuiFormHelperText-root.Mui-error': {
      fontWeight: 400,
      marginLeft: '0',
      color: '#df1b41',
      fontSize: '15px',
      lineHeight: '16px',
    },

    '& label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      position: 'static',
      textAlign: 'left',
      marginBottom: '7px',
      transform: 'initial !important'
    },

    '& legend': {
      display: 'none'
    },

    '& fieldset': {
      top: 0
    },

    '@media(max-width: 830px)': {
      width: '100%',
      marginBottom: '16px'
    }
  },
  submit_btn: {
    marginTop: '20px !important'
  },
  description_page: {
    maxWidth: '50rem',
    '& h1': {
      textAlign: 'center'
    },
    '@media(max-width: 1200px)': {
      maxWidth: '100%',
      marginTop: '2rem',
    },
    '& p, & b,& li': {
      fontSize: 16
    },
    '& p, & ul': {
      marginBottom: '1rem'
    }
  },
  description_page_wrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
    marginBottom: '2rem'
  }
}));
