import { CardWrapper } from '@monorepo/common';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import EmptyTable from '../../../components/EmptyTable';
import { IBreadcrumbs, IHistoryState } from '../../../interfaces';
import { book } from '../../../routers/books';
import { useGetJobsApplicantsQuery } from '../../../services/jobs';

interface IParams {
  id: string;
}

export const JobsApplicants = () => {
  const { id } = useParams<IParams>();
  const { data, isLoading } = useGetJobsApplicantsQuery(id);
  const history = useHistory();
  const historyLocation = history.location.state as IHistoryState;
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbs[]>([
    { title: 'Home', link: book.userAccount },
    { title: 'Manage Applications' },
  ]);

  useEffect(() => {
    if (historyLocation?.from === 'jobListing') {
      setBreadcrumbs([
        { title: 'Job Listings', link: book.jobs.jobsListing },
        { title: 'Manage Applications' },
      ]);
    } else if (historyLocation?.from === 'jobsPrepaidDetail') {
      setBreadcrumbs([
        { title: 'Prepaid Job Packages', link: book.jobs.jobsPrepaid },
        {
          title: data
            ? `Prepaid Job Listings Package: #${data.prepaidId} - ${data.company}`
            : 'Prepaid Job Packages',
          link: data ? book.jobs.jobsPrepaidDetail(data.prepaidGuid) : null,
        },
        { title: 'Manage Applications' },
      ]);
    }
  }, [data, historyLocation]);

  const getToUrl = (applicationId: string) => book.jobs.jobsApplicantDetail(data.eguid, applicationId);

  return (
    <CardWrapper
      maxWidth="70rem"
      helmet="Applicants"
      isLoading={isLoading}
      outsideHead={{
        title: data
          ? `Manage Applications for: ${data.adTitle}`
          : 'Manage Applications',
        breadcrumbs,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>From</TableCell>
            <TableCell>Date Sent</TableCell>
            <TableCell>Applied Via</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.jobApplications && data.jobApplications.length ? (
            data.jobApplications.map((item: any) => (
              <TableRow key={item.applicationId}>
                <TableCell>
                  <Typography
                    fontWeight={!item.isRead ? '700' : '400'}
                    variant="body2"
                  >
                    <Link to={getToUrl(item.applicationId)}>
                      {item.name}
                      {' '}
                      (
                      {item.from}
                      )
                    </Link>
                  </Typography>
                </TableCell>
                <TableCell>
                  {moment(item.dateSent).format('MM/DD/YYYY') || '-'}
                </TableCell>
                <TableCell>{item.appliedVia}</TableCell>
              </TableRow>
            ))
          ) : (
            <EmptyTable colSpan={3} />
          )}
        </TableBody>
      </Table>
    </CardWrapper>
  );
};

export default memo(JobsApplicants);
