import { useDispatch } from 'react-redux';
import { showNotification } from '../features/sliceNotification';
import { scrollUp } from '../utils/scrollUp';

interface IUseActionAfterUpdatedData {
  scrollUpPage?: boolean,
  text?: string
}

export const useActionAfterUpdatedData = () => {
  const dispatch = useDispatch();

  const dataWasUpdated = ({ scrollUpPage = true, text }:IUseActionAfterUpdatedData) => {
    if (text) {
      dispatch(showNotification({
        type: 'success',
        text
      }));
    }
    if (scrollUpPage) {
      scrollUp();
    }
  };

  return {
    dataWasUpdated
  };
};
