export enum HOST {
  BEVNET = 1,
  BREWBOUND = 2,
  NOSH = 3,
  TASTE_RADIO = 4,
}

export const hostNames: { [key: string]: number } = {
  'localhost:3000': 1,
  'bevnet.com': 1,

  'localhost:3001': 2,
  'brewbound.com': 2,

  'localhost:3002': 3,
  'nosh.com': 3,

  'localhost:3003': 4,
  'tasteradio.com': 4,
};
