import { HOST } from '../../constants/hosts';
import LogoBrewbound from './LogoBrewbound';
import LogoBevNetNosh from './LogoBevNetNosh';
import { useStyles } from '../Header/styles';

interface IIconLogoProps {
  numberHost: number
}

const IconLogo = ({ numberHost }: IIconLogoProps) => {
  const classes = useStyles();
  if (numberHost === HOST.BREWBOUND) {
    return (<LogoBrewbound className={classes.logo} />);
  }

  return (<LogoBevNetNosh className={classes.logoDouble} />);
};

export default IconLogo;
