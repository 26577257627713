import React from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './styles';

interface IFieldCommentProps {
  fieldComment: string | null | undefined,
  className?: string
}
interface IFormatterLabel {
  label?: string,
  isRequired?: boolean
}

export const FieldComment = ({ fieldComment, className }:IFieldCommentProps) => {
  const classes = useStyles();
  if (!fieldComment) {
    return null;
  }
  return (
     <Typography
        className={clsx(classes.fieldComment, className)}
        color="textSecondary"
        variant="body2"
     >
        {fieldComment}
     </Typography>
  );
};

export const formatterLabel = ({ label, isRequired }:IFormatterLabel) => {
  if (label) {
    if (!isRequired) {
      return label.charAt(0).toUpperCase() + label.slice(1);
    }
    return (
       <>
          {label.charAt(0).toUpperCase() + label.slice(1)}
          {' '}
          <span style={{ color: 'red' }}> *</span>
       </>
    );
  }
  return null;
};
