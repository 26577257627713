import React, { memo } from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import { convertedNameToLabels } from '../../../utils/convertedNameToLabels';

interface InfoLineProps {
  name: string,
  value: string
}

const UserInfoLine = ({ name, value }:InfoLineProps) => {
  const classes = useStyles();

  if (value) {
    return (
      <TableRow>
        <TableCell style={{ borderBottom: '1px solid #E6E8F0' }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
            className={classes.item}
          >
            {convertedNameToLabels(name)}
          </Typography>
        </TableCell>
        <TableCell style={{ borderBottom: '1px solid #E6E8F0' }}>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {value}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  return null;
};

export default memo(UserInfoLine);
