import { makeStyles } from '@material-ui/core';
import { mixin } from '../../utils/mixin';

// '@media(max-width: 768px)': {}
export const useStyles = makeStyles(() => ({
  root: {
    ...mixin.flex('center', 'center'),
    minHeight: 200,
    width: '100%',
    padding: 15
  }
}));
