export const convertedNameToLabels = (name: string) => {
  switch (name) {
    case 'firstName':
      return 'First name';
    case 'lastName':
      return 'Last name';
    case 'mobilePhone':
      return 'Phone number';
    case 'address1':
      return 'Address 1';
    case 'address2':
      return 'Address 2';

    default:
      return name.charAt(0).toUpperCase() + name.slice(1);
  }
};
