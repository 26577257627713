import { makeStyles } from '@material-ui/core';
import { Mixin } from '@monorepo/common';

// '@media(max-width: 768px)': {}
export const useStyles = makeStyles(() => ({
  line: {
    ...Mixin.mixin.flex('center', 'center'),
    gridGap: 15,
    '@media(max-width: 992px)': {
      display: 'block'
    },
    '& > div': {
      flex: 1
    }
  }
}));
