import React from 'react';
import { Typography } from '@mui/material';
import { useStyles } from '../styles';

// interface IUploadProductPhotosInfoProps {}
const UploadProductPhotosInfo = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.line}>
        <Typography
          variant="h6"
          fontWeight="bold"
          marginBottom={1}
        >
          Instructions - PLEASE READ
        </Typography>

        <Typography
          variant="body2"
        >
          Please note that it is your responsibility to meet the requirements below. BevNET will not perform any image adjustments for user submitted photos (although our system does resize images to the proper size).  Invalid submissions will be ignored.
        </Typography>
      </div>

      <div className={classes.line}>
        <Typography
          variant="h6"
          fontWeight="bold"
          marginBottom={1}
        >
          Licensing and Legal Requirements
        </Typography>

        <Typography
          variant="body2"
        >
          Photos are only accepted from the company that produces the product in the photo OR from an authorized agent (e.g. PR company).
          All photos that are submitted to BevNET are assumed to be provided royalty free and we assume that the company submitting the product owns the rights to the photos.
        </Typography>
      </div>

      <div className={classes.line}>
        <Typography
          variant="h6"
          fontWeight="bold"
          marginBottom={1}
        >
          Core Photo Requirements:
        </Typography>

        <Typography
          variant="body2"
          marginBottom={2}
        >
          These requirements must be met or BevNET will not utilize your photo:
        </Typography>

        <ul className={classes.list}>
          <li>
            <Typography
              variant="body2"
            >
              White or transparent background with no text or objects in the photo
            </Typography>
          </li>
          <li>
            <Typography
              variant="body2"
            >
              RGB color mode
            </Typography>
          </li>
          <li>
            <Typography
              variant="body2"
            >
              File types: JPG or PNG
            </Typography>
          </li>
          <li>
            <Typography
              variant="body2"
            >
              Minimum size: 640px high (larger is better) and 240dpi
            </Typography>
          </li>
          <li>
            <Typography
              variant="body2"
            >
              One SKU per photo
            </Typography>
          </li>
          <li>
            <Typography
              variant="body2"
            >
              Photo must be in focus and have reasonable depth of field
            </Typography>
          </li>
          <li>
            <Typography
              variant="body2"
            >
              Cropped properly with a small amount of white space on all sides of the image
            </Typography>
          </li>
          <li>
            <Typography
              variant="body2"
            >
              Not contain any watermarks or copyright notices
            </Typography>
          </li>
        </ul>

        <Typography
          variant="body2"
        >
          Photos are only accepted from the company that produces the product in the photo OR from an authorized agent (e.g. PR company).
          All photos that are submitted to BevNET are assumed to be provided royalty free and we assume that the company submitting the product owns the rights to the photos.
        </Typography>
      </div>

    </>
  );
};

export default UploadProductPhotosInfo;
