import { orderHistoryApi } from '../services/orderHistory';
import { publicProfileApi } from '../services/userProfile/public';
import { personalInfoApi } from '../services/userProfile/personal';
import { emailApi } from '../services/userProfile/email';
import { passwordApi } from '../services/userProfile/password';
import { menuApi } from '../services/menuSettings';
import { newsletterInfoApi } from '../services/newsletter';
import { brandsApi } from '../services/serviceBrands';
import { helpApi } from '../services/helpForm';
import { invoiceApi } from '../services/serviceInvoice';
import { jobsApi } from '../services/jobs';
import { pressReleasesApi } from '../services/pressReleases';

export const middlewareHandler = (getDefaultMiddleware: any) => {
  const middlewareList = [
    ...getDefaultMiddleware()
      .concat(orderHistoryApi.middleware)
      .concat(menuApi.middleware)
      .concat(publicProfileApi.middleware)
      .concat(personalInfoApi.middleware)
      .concat(emailApi.middleware)
      .concat(passwordApi.middleware)
      .concat(newsletterInfoApi.middleware)
      .concat(brandsApi.middleware)
      .concat(helpApi.middleware)
      .concat(invoiceApi.middleware)
      .concat(jobsApi.middleware)
      .concat(pressReleasesApi.middleware)
  ];
  return middlewareList;
};
