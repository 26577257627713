import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import endpoints from '../../api/endpoints';
import {
  BrandLogoResponse,
  CreateProductRequest,
  EditBrandRequest,
  IBrandInfoResponse,
  IProductTypesResponse,
  ProductDetailsRequest,
  ProductDetailsResponse,
  ProductPhotoRequest,
  ProductSuBrandsRequest,
  ProductSuBrandsResponse,
  SuBrandRequest,
  UpdateProductDetailsRequest,
  UploadLogoRequest,
  IBrandProductsResponse
} from './serviceBrandsInterface';

export const brandsApi = createApi({
  reducerPath: 'brands',
  tagTypes: ['Brands', 'Products', 'BrandInfo', 'BrandLogo', 'ProductDetails', 'SubBrandOptions'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    // Brands
    getBrandInfo: build.query<IBrandInfoResponse, { brandGuid: string }>({
      query: ({ brandGuid }) => ({
        url: endpoints.brands.getBrandDetails(brandGuid),
      }),
      providesTags: () => ['BrandInfo']
    }),
    getBrandProducts: build.query<IBrandProductsResponse, { brandGuid: string }>({
      query: ({ brandGuid }) => ({
        url: endpoints.brands.brandProducts(brandGuid),
      }),
      providesTags: () => ['Products']
    }),
    getBrandLogo: build.query<BrandLogoResponse, { brandGuid: string }>({
      query: ({ brandGuid }) => ({
        url: endpoints.brands.getLogoUrl(brandGuid),
      }),
      providesTags: () => ['BrandLogo']
    }),
    uploadLogo: build.mutation<{ url: string }, UploadLogoRequest>({
      query: ({ brandGuid, body }) => ({
        url: endpoints.brands.uploadLogo(brandGuid),
        method: 'PUT',
        body
      }),
      invalidatesTags: ['BrandLogo']
    }),
    removeLogo: build.mutation<{ url: string }, string>({
      query: (brandGuid) => ({
        url: endpoints.brands.removeLogo(brandGuid),
        method: 'DELETE'
      }),
      invalidatesTags: ['BrandLogo']
    }),
    editBrandInfo: build.mutation<any, EditBrandRequest>({
      query: ({ brandGuid, body }) => ({
        url: endpoints.brands.editBrand(brandGuid),
        method: 'PUT',
        body
      }),
      invalidatesTags: ['BrandInfo']
    }),
    createSubBrand: build.mutation<any, SuBrandRequest>({
      query: ({ body, brandGuid }) => ({
        url: endpoints.brands.createSubBrand(brandGuid),
        method: 'POST',
        body
      }),
      invalidatesTags: ['SubBrandOptions']
    }),

    // product
    getProductTypes: build.query<IProductTypesResponse[], null>({
      query: () => ({
        url: endpoints.brands.getProductTypes,
      }),
      providesTags: () => ['BrandInfo']
    }),
    getProductSubBrands: build.query<ProductSuBrandsResponse[], ProductSuBrandsRequest>({
      query: ({ brandGuid }) => ({
        url: endpoints.brands.getProductSubBrands(brandGuid),
      }),
      providesTags: () => ['SubBrandOptions']
    }),
    getProductDetails: build.query<ProductDetailsResponse, ProductDetailsRequest>({
      query: ({ brandGuid, productGuid }) => ({
        url: endpoints.brands.getProductDetails(brandGuid, productGuid),
      }),
      providesTags: () => ['ProductDetails']
    }),
    updateProductDetails: build.mutation<any, UpdateProductDetailsRequest>({
      query: ({ brandGuid, productGuid, body }) => ({
        url: endpoints.brands.updateProductDetails(brandGuid, productGuid),
        method: 'PUT',
        body
      }),
      invalidatesTags: ['ProductDetails']
    }),
    createProduct: build.mutation<any, CreateProductRequest>({
      query: ({ brandGuid, body }) => ({
        url: endpoints.brands.createProduct(brandGuid),
        method: 'POST',
        body
      }),
      invalidatesTags: ['BrandInfo']
    }),
    updateStatusProduct: build.mutation<any, ProductDetailsRequest>({
      query: ({ brandGuid, productGuid }) => ({
        url: endpoints.brands.changeStatusProduct(brandGuid, productGuid),
        method: 'PUT',
      }),
      invalidatesTags: ['Products']
    }),
    updateProductPhoto: build.mutation<any, ProductPhotoRequest>({
      query: ({ brandGuid, productGuid, body }) => ({
        url: endpoints.brands.updateProductPhoto(brandGuid, productGuid),
        method: 'PUT',
        body
      }),
      invalidatesTags: ['BrandInfo']
    }),

    //
    getAccessStatus: build.query<{ accessLevel: number, brandSlug: string }, { brandGuid: string }>({
      query: ({ brandGuid }) => ({
        url: endpoints.brands.getAccessStatus(brandGuid),
      }),
      providesTags: () => ['BrandInfo']
    }),
  }),
});

export const {
  // brands
  useGetBrandInfoQuery,
  useEditBrandInfoMutation,
  useGetBrandLogoQuery,
  useCreateSubBrandMutation,
  useUploadLogoMutation,
  useRemoveLogoMutation,

  // products
  useGetBrandProductsQuery,
  useGetProductTypesQuery,
  useCreateProductMutation,
  useGetProductDetailsQuery,
  useGetProductSubBrandsQuery,
  useUpdateProductDetailsMutation,
  useUpdateStatusProductMutation,
  useUpdateProductPhotoMutation,

  // Access
  useGetAccessStatusQuery
} = brandsApi;
