import { useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cookies from 'js-cookie';

import { cookieNames, pageRedirect } from '@monorepo/common';

import Layout from './containers/Layout';
import { useAppSelector } from './store/hooks';
import RootRouters from './routers/RootRouters';
import Notification from './components/Notification';
import { changeAuthStatus } from './features/sliceAuth';
import { ProjectNavigation } from './components/ProjectNavigation';
import { publicProfileApi } from './services/userProfile/public';
import { book } from './routers/books';

function App() {
  const location = useLocation();
  const { showNotification, isAuth } = useAppSelector(
    ({ notification, auth }) => ({
      showNotification: notification.show,
      isAuth: auth.isAuth,
    })
  );
  const {
    location: { pathname },
  } = useHistory();
  const dispatch = useDispatch();
  const [isUserAuth, setIsUserAuth] = useState(isAuth);

  publicProfileApi.useGetQuery('', {
    skip: !isUserAuth,
  });

  // init countries testEventsList
  useEffect(() => {
    setIsUserAuth(isAuth);
  }, [isAuth]);

  // GLOBAL INIT
  useEffect(() => {
    if (cookies.get(cookieNames.bevnet_token)) {
      dispatch(changeAuthStatus(true));
      return;
    }
    dispatch(changeAuthStatus(false));
  }, [pathname]);

  // if cookie not exist, redirect page
  useEffect(() => {
    let clearPath = pathname;
    if (clearPath.endsWith('/') && clearPath !== '/') {
      clearPath = clearPath.slice(0, -1);
    }

    if (!cookies.get(cookieNames.bevnet_token)
        && clearPath !== book.invoices.invoice
        && clearPath !== book.invoices.confirmEmail
        && clearPath !== book.invoices.invoiceCompleted) {
      pageRedirect({ token: cookies.get(cookieNames.bevnet_token) });
    }
    if (clearPath === book.invoices.invoice
        && !cookies.get(cookieNames.bevnet_token)
        && !cookies.get(cookieNames.invoice_token)) {
      window.location.href = '/invoice/confirmEmail';
    }
  }, [location, pathname]);

  return (
    <>
      {showNotification && <Notification />}

      <Layout
        isAuth={isAuth}
        projectNavigation={<ProjectNavigation />}
      >
        <RootRouters />
      </Layout>
    </>
  );
}

export default App;
