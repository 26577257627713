import * as yup from 'yup';
import { regExp } from '@monorepo/common';

export const schema:any = yup.object().shape({
  headshot: yup.mixed(),
  publicBio: yup.string().nullable(),
  linkedIn: yup
    .string()
    .nullable()
    .matches(regExp.urlLinkedin, 'Please enter a valid LinkedIn profile URL!')
});
