import { memo, useEffect, useState } from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FaceIcon from '@material-ui/icons/Face';
import HomeIcon from '@material-ui/icons/Home';
import WorkIcon from '@material-ui/icons/Work';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CreditCardIcon from '@mui/icons-material/CreditCard';
// Temporary disable menu item according to comment in https://app.asana.com/0/11286219252453/1206586670889199
// import NewspaperIcon from '@mui/icons-material/Newspaper';

import { MenuNavigation } from '@monorepo/common';

import { useDispatch } from 'react-redux';
import { toggleLeftSide } from '../features/ui/sliceMobileMenu';
import { book } from '../routers/books';
import { useGetMenuSettingsQuery } from '../services/menuSettings';
import { useAppSelector } from '../store/hooks';

// interface IMenuInternalLinksProps {}
export const ProjectNavigation = memo(() => {
  const dispatch = useDispatch();
  const { data, isLoading } = useGetMenuSettingsQuery();

  const [brandsLinks, setBrandsLinks] = useState<
  { title: string; href: string; extraInternalLinks?: string[] }[]
  >([]);

  useEffect(() => {
    if (data?.userBrands) {
      const { userBrands } = data;
      userBrands.map((item) => setBrandsLinks((brand) => [
        ...brand,
        {
          title: item.brandName,
          href: book.brands.brandOptions(item.brandGuid),
          subMenus: [{
            title: 'Update Brand Information',
            href: book.brands.editBrand(item.brandGuid)
          }, {
            title: 'Update Brand Logo',
            href: book.brands.uploadLogo(item.brandGuid)
          }, {
            title: 'Existing Products',
            href: book.brands.editProducts(item.brandGuid)
          }, {
            title: 'Add Product',
            href: book.brands.createProduct(item.brandGuid)
          }, {
            title: 'Add Sub Brand',
            href: book.brands.createSubBrand(item.brandGuid)
          },
          {
            title: 'View Public Page',
            href: `https://www.bevnet.com/brands/${item.brandSlug}`,
            target: '_blank',
            externalLink: true
          }]
        },
      ]));
    }
  }, [data]);
  const { isShowLeftSide, isMobileMenu } = useAppSelector(({ ui }) => ({
    isMobileMenu: ui.isMobileMenu,
    isShowLeftSide: ui.showLeftSide as boolean
  }));

  return (
    <MenuNavigation
      options={{
        dispatch,
        isShowHelp: true,
        isMobileMenu,
        toggleLeftSide,
        isShowLeftSide,
      }}
      isLoading={isLoading}
      menu={[
        {
          groupTitle: 'Account',
          menuItems: [
            {
              title: 'Home',
              href: book.userAccount,
              icon: <HomeIcon />,
            },
            {
              title: 'Account Info',
              href: book.userAccount,
              icon: <FaceIcon />,
              subMenus: [
                {
                  title: 'Contact info',
                  href: book.userUpdate
                },
                {
                  title: 'Public profile',
                  href: book.userPublicProfile
                },
                {
                  title: 'Change Password',
                  href: book.userChangePassword
                },
                {
                  href: book.newsletter,
                  title: 'Newsletter Opt-in'
                },
                {
                  title: 'BevNET Magazine',
                  href: data?.magazineSubscriptionItem?.guid ? book.magazine.renew(data.magazineSubscriptionItem.guid) : book.magazine.subscribe,
                  externalLink: true
                }
              ]
            },
            {
              title: 'Brands',
              subMenus: brandsLinks,
              icon: <AccountCircleIcon />,
              isPermission: !!data?.userBrands.length,
            },
            {
              href: book.userOrders,
              title: 'Order History',
              icon: <AttachMoneyIcon />
            },
            {
              href: book.invoices.invoice,
              title: 'Pay an Invoice',
              icon: <CreditCardIcon />
            },
            {
              externalLink: true,
              icon: <AccountCircleIcon />,
              title: 'Insider Subscription',
              isPermission: data?.subscriptionItem?.showSubscribeLink,
              href: `https://app.bevnet.com/subscription/${data?.subscriptionItem?.organizationGuid}/overview`
            },
            {
              href: book.jobs.jobsListing,
              title: 'Job Listings',
              icon: <WorkIcon />
            },
            {
              href: book.jobs.jobsPrepaid,
              title: 'Prepaid Job Packages',
              icon: <WorkIcon />
            },
            {
              href: book.pressReleases.pressReleases,
              title: 'Press Releases',
              icon: <NewspaperIcon />
            },
          ]
        }
      ]}
    />
  );
});
