import React, { memo } from 'react';
import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import clsx from 'clsx';
import { IChildrenProps } from '../../../interfaces';

import { useStyles } from '../styles';
import { IBackProps, InfoPanelPosition, IStyleProps } from '../interface';

interface IInfoPanelContainerProps extends IChildrenProps, IStyleProps{
  backStepSettings?:IBackProps,
  leftInfoPanelPhonePosition: InfoPanelPosition
}

const InfoPanelContainer = ({
  children,
  styleProps,
  backStepSettings,
  leftInfoPanelPhonePosition
}:IInfoPanelContainerProps) => {
  const classes = useStyles();
  const eventBack = () => {
    if (backStepSettings && backStepSettings.handlerClick) {
      backStepSettings.handlerClick();
    }
  };
  if (children) {
    return (
       <div
          className={clsx(classes.description_page, {
            [classes.left_info_panel_move_to_top]: leftInfoPanelPhonePosition === 'top'
          })}
          style={{
            ...(styleProps?.descriptionSide) && { maxWidth: `${styleProps?.descriptionSide}rem` },
            ...(styleProps?.widthAllStepper) && { maxWidth: `${styleProps?.widthAllStepper}rem` },
          }}
       >
          {backStepSettings && (
          <Button
             data-cy="stepper_go_back"
             style={{ textTransform: 'none' }}
             onClick={eventBack}
             className={backStepSettings?.className}
             startIcon={backStepSettings?.icon || <ChevronLeftIcon />}
          >
             {backStepSettings?.text || 'Back'}
          </Button>
          )}
          {children}
       </div>
    );
  }
  return null;
};

export default memo(InfoPanelContainer);
