import { makeStyles } from '@material-ui/core';
import { mixin } from '../../utils/mixin';

export const useStyles = makeStyles((theme) => ({
  btnRoot: {
    boxShadow: '0px 2px 4px rgb(0, 0, 0, / 15), 0px 0px 0px 1px rgb(0 0 0 / 5%)',
    padding: '6px 16px',
    overflow: 'hidden',
    fontSize: '0.875rem',
    minWidth: '64px',
    position: 'relative',
    boxSizing: 'border-box',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0s',
    fontWeight: 600,
    lineHeight: 1.75,
    borderRadius: (theme.shape.borderRadius as number) / 2,
    border: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    ...mixin.flex('center', 'center'),
    width: 'fit-content',
    '& *': {
      fontSize: 'inherit',
      fontWeight: 'inherit',
      lineHeight: 'inherit',
      transition: '.4s'
    },
    '&:active': {
      boxShadow: '0 0 8px 2px rgb(0 0 0 / 58%) inset'
    },
  },
  text: {
    margin: '0 8px'
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  fullWidth: {
    width: '100%'
  },
  contained: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    // border: `0px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      // border: `1px solid ${theme.palette.primary.dark}`,
    }
  },
  outlined: {
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    '& span': {
      zIndex: 2,
      position: 'relative',
    },
    '&:before': {
      content: '""',
      transition: '.4s',
      ...mixin.overlayBlock(theme.palette.primary.main, 0)
    },
    '&:hover': {
      color: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.primary.dark}`,
      '&:before': {
        ...mixin.overlayBlock(theme.palette.primary.main, 3)
      }
    }
  },
}));
