import React from 'react';
import { Stepper } from '@mui/material';
import Alert from '@mui/material/Alert';
import clsx from 'clsx';

import InfoPanelContainer from './InfoPanelContainer';
import Loader from '../../../components/Loader/Loader';
import StepsWrapperTitle from './StepsWrapperTitle';
import PlugNoData from '../../../components/PlugNoData';
import { IWrapperProps } from '../interface';

import { useStyles } from '../styles';

const Wrapper = ({
  none_data,
  helmet,
  leftInfoPanelPhonePosition = 'bottom',
  isHideLeftInfoPanelAfterComplete,
  children,
  leftInfoPanel,
  isLoading,
  complete,
  finalComponent,
  activeStep,
  backStepSettings,
  stepperRightToolbar = null,
  orientation,
  styleProps,
}:IWrapperProps) => {
  const classes = useStyles();
  return (
     <>
        <Loader isShow={isLoading} />

        <StepsWrapperTitle helmet={helmet} />

        {!none_data ? (
           <div
              className={clsx(classes.root, {
                [classes.left_info_panel_move_to_top]: leftInfoPanelPhonePosition === 'top',
                [classes.column_mode]: orientation === 'vertical',
              })}
           >
              <div
                 className={classes.wrapper_for_steps}
                 style={{
                   ...(styleProps?.stepperSide) && { maxWidth: `${styleProps?.stepperSide}rem` },
                   ...(styleProps?.widthAllStepper) && { maxWidth: `${styleProps?.widthAllStepper}rem` },
                 }}
              >
                 {stepperRightToolbar && (
                 <div className={classes.stepper_side}>
                    <Alert severity={stepperRightToolbar.type}>
                       {stepperRightToolbar.body}
                    </Alert>
                 </div>
                 )}
                 {
                     !complete
                       ? (
                          <>
                             {/* {!isLoading && ( */}
                             <Stepper
                                activeStep={activeStep}
                                orientation="vertical"
                                sx={{
                                  '& .MuiStepConnector-line': {
                                    ml: 1,
                                    borderLeftColor: 'divider',
                                    borderLeftWidth: 2
                                  }
                                }}
                             >
                                {children}
                             </Stepper>
                          </>
                       ) : finalComponent
                 }
              </div>
              <InfoPanelContainer
                 leftInfoPanelPhonePosition={leftInfoPanelPhonePosition}
                 backStepSettings={backStepSettings}
                 styleProps={styleProps}
              >
                 {!isHideLeftInfoPanelAfterComplete && leftInfoPanel}
              </InfoPanelContainer>
           </div>
        ) : <PlugNoData card /> }
     </>
  );
};

export default Wrapper;
