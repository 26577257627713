import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  label_exist: {},
  field_wrapper: {
    marginBottom: '2rem',
    '& input:-webkit-autofill': {
      ' -webkit-text-fill-color': 'inherit !important',
      '-webkit-box-shadow': '0 0 0 100px #fff inset !important'
    },
    '&$label_exist': {
      '& .MuiFormLabel-filled, & .Mui-focused': {
        fontWeight: 'bold'
      },
    },
    '&:not($label_exist) legend span': {
      display: 'none'
    },
    '& label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -9px) scale(0.75) !important'
    }
  },
  hidden: {
    display: 'none'
  },
  fieldComment: {
    marginTop: '5px !important',
    padding: '0 15px'
  },
  relative: {
    position: 'relative'
  },
  absolute: {
    position: 'absolute'
  },
  currency: {
    '& input': {
      paddingLeft: 25
    }
  },
  currencySymbol: {
    top: '67%',
    left: 10,
    position: 'absolute',
    fontSize: '1.3em',
    transform: 'translateY(-50%)'
  },
  required: {
    color: 'red'
  }
}));
