import { currencyFormatter } from '../../../utils/currencyFormatter';

export const convertToCurrency = ({ isCurrency, value }:{ isCurrency: boolean; value: number | any }) => {
  if (isCurrency) {
    if (typeof value !== 'number') {
      return value;
    }
    return currencyFormatter.format(value);
  }
  return value;
};
