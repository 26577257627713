import { makeStyles } from '@material-ui/core';
import { Mixin } from '../../index';
// import { mixin } from '../../utils/mixin';

// '@media(max-width: 768px)': {}
export const useStyles = makeStyles(() => ({
  steps_line_buttons: {
    marginTop: '2rem',
    ...Mixin.mixin.flex('center', 'flex-start'),
    minHeight: 30,
    '@media(max-width: 600px)': {
      flexDirection: 'column'
    }
  },
  previous_btn: {
    '@media(max-width: 600px)': {
      marginLeft: '0 !important',
      marginTop: ' 15px !important'
    }
  },
}));
