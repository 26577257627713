import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { memo } from 'react';
import { useStyles } from '../styles';
import { NewsSites } from '../../../constants/newsSites';
import { PressReleaseStatus } from '../../../constants/pressReleaseStatus';

interface IParams {
  title: string;
  slug: string;
  isBevnet: boolean;
  isBrewbound: boolean;
  isNosh: boolean;
  status: PressReleaseStatus;
}

const TableTitle = ({
  title,
  slug,
  isBevnet,
  isBrewbound,
  isNosh,
  status,
}: IParams) => {
  const classes = useStyles();

  const getUrl = (site: NewsSites) => {
    switch (site) {
      case NewsSites.Bevnet:
        return `https://www.bevnet.com/pr/${slug}`;
      case NewsSites.Brewbound:
        return `https://www.brewbound.com/pr/${slug}`;
      case NewsSites.Nosh:
        return `https://www.nosh.com/pr/${slug}`;
      default:
        return `https://www.bevnet.com/pr/${slug}`;
    }
  };

  return (
    <div>
      {title}
      {(status === PressReleaseStatus.Approved
        || status === PressReleaseStatus.Featured
        || status === PressReleaseStatus.Promoted)
        && (isBevnet || isBrewbound || isNosh) && (
          <div>
            Site: &nbsp;
            {isBevnet && (
              <a
                href={getUrl(NewsSites.Bevnet)}
                className={classes.viewLink}
                target="_blank"
                rel="noreferrer"
              >
                Bevnet
                <OpenInNewIcon className={classes.viewIcon} />
              </a>
            )}
            {isBrewbound && (
              <a
                href={getUrl(NewsSites.Brewbound)}
                className={classes.viewLink}
                target="_blank"
                rel="noreferrer"
              >
                Brewbound
                <OpenInNewIcon className={classes.viewIcon} />
              </a>
            )}
            {isNosh && (
              <a
                href={getUrl(NewsSites.Nosh)}
                className={classes.viewLink}
                target="_blank"
                rel="noreferrer"
              >
                Nosh
                <OpenInNewIcon className={classes.viewIcon} />
              </a>
            )}
          </div>
      )}
    </div>
  );
};

export default memo(TableTitle);
