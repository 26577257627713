import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  subtitle: {
    marginTop: '1rem !important',
    marginBottom: '2rem !important'
  },
  actions: {
    display: 'flex',
    gap: '1rem',
  },
  viewIcon: {
    verticalAlign: 'middle',
    transform: 'scale(.6)',
  },
  viewLink: {
    textWrap: 'nowrap',
  },
}));
