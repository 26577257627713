import React, { memo, useEffect, useState } from 'react';
import { FormAutocomplete } from '../FormAutocomplete';
import ErrorBoundary from '../../containers/ErrorBoundary';
import { HttpApi } from '../../api/HttpApi';
import { StateAutocompleteNotification } from './components/StateAutocompleteNotification';

interface IOption {
  value: string,
  text: string,
}

interface UsaStatesAutocompleteProps {
  originalName?: string,
  placeholder?: string;
  isRequired?: boolean;
  visible?: boolean;
  disableClearable?: boolean;
  label?: string;
  errors?: any;
  fieldComment?: string | null;
  extraProps?: any;
  getValues?: any;
  setValue?: any,
  handleChange?: (selectedOption: IOption | null) => void;
}

const UsaStatesAutocomplete = ({
  label,
  setValue,
  originalName,
  disableClearable = false,
  extraProps = {},
  isRequired = false,
  placeholder = 'State',
  visible = true,
  errors = false,
  fieldComment,
  getValues,
  handleChange,
}: UsaStatesAutocompleteProps) => {
  const [usaOptionsList, setUsaOptionsList] = useState<IOption[] | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [textError, setTextError] = useState<string>('');
  const [open, setOpen] = React.useState(false);

  const get = async () => HttpApi.get('/misc/GetStates');

  const selectChange = (value: IOption | null) => {
    if (handleChange) {
      handleChange(value);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setTextError('');
  };

  useEffect(() => {
    if (!localStorage.getItem('state-list')) {
      setIsLoading(true);
      get()
        .then((res: IOption[]) => {
          if (res) {
            setUsaOptionsList(res);
            localStorage.setItem('state-list', JSON.stringify(res));
            setIsLoading(false);
            return;
          }
          setUsaOptionsList(null);
        })
        .catch((error) => {
          setIsLoading(false);
          setTextError(error.message);
        });
    } else {
      const stateList: string = localStorage.getItem('state-list') as string;
      setUsaOptionsList(JSON.parse(stateList));
    }
  }, []);

  if (visible) {
    return (
       <ErrorBoundary>
          <StateAutocompleteNotification
             isShow={open}
             close={handleClose}
             text={textError}
          />
          <FormAutocomplete<IOption>
             disableClearable={disableClearable}
             originalName={originalName}
             optionsSettings={{
               optionText: 'text',
               optionValue: 'value'
             }}
             isLoading={isLoading}
             isRequired={isRequired}
             setValue={setValue}
             options={usaOptionsList || []}
             placeholder={placeholder}
             onChange={(val) => selectChange(val as IOption | null)}
             fieldComment={fieldComment}
             getValues={getValues}
             label={label}
             extraProps={extraProps}
             errors={errors}
          />
       </ErrorBoundary>
    );
  }
  return null;
};

export default memo(UsaStatesAutocomplete);
