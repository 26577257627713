import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';

import { Loader } from '@monorepo/common';

import { useRemoveDetailMutation } from '../../../../services/serviceInvoice';
import { useStyles } from '../../styles';

interface IStep1AlertProps {
  id: number | null,
  closeModal: () => void
}
const Step1Alert = ({ id, closeModal }:IStep1AlertProps) => {
  const classes = useStyles();
  const [removeDetail, { isLoading: isRemoving, isSuccess }] = useRemoveDetailMutation();

  const remove = () => {
    if (id) {
      removeDetail({
        id: id.toString()
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return (
    <>
      <Loader isShow={isRemoving} />
      <Typography variant="h5">
        Are you sure you want to delete this record?
      </Typography>

      <div className={classes.step1_wrapper_buttons}>
        <Button
          variant="contained"
          onClick={remove}
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
export default Step1Alert;
