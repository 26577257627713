import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@mui/material';

import { IAlignProps, IOutsideHead } from '../interfaceCardWrapper';
import BreadcrumbsPanel from '../../../components/BreadcrumbsPanel/BreadcrumbsPanel';

import { useStyles } from '../styles';

interface ICardWrapperOutsideHeadProps extends IAlignProps{
  outsideHead?: IOutsideHead,
  internalStyles: any,
  maxWidth?: string | number,
}

const CardWrapperOutsideHead = ({ outsideHead, internalStyles, maxWidth, align }:ICardWrapperOutsideHeadProps) => {
  const classes = useStyles();
  const [variant, setVariant] = useState<'h4' | 'h5' | 'h6'>('h4');
  const large = useMediaQuery('(min-width:992px)');
  const medium = useMediaQuery('(max-width:768px)');
  const small = useMediaQuery('(max-width:500px)');
  useEffect(() => {
    if (large) {
      setVariant('h4');
      return;
    }
    if (medium) {
      setVariant('h5');
      return;
    }
    if (small) {
      setVariant('h6');
      return;
    }
    setVariant('h4');
  }, [large, medium, small]);

  if (outsideHead) {
    return (
       <>
          {(outsideHead.title || outsideHead.childrenTitleRight || outsideHead.breadcrumbs) && (
          <div
             className={clsx(classes.page_head, outsideHead.classNameForOutsideHead, {
               [classes.page_head_offset]: !outsideHead.breadcrumbs,
               [classes.left]: align === 'left',
               [classes.right]: align === 'right',
             })}
             {...internalStyles}
          >
             {outsideHead.breadcrumbs && outsideHead.breadcrumbs.length && (
             <BreadcrumbsPanel
                maxWidth={maxWidth}
                list={outsideHead.breadcrumbs}
             />
             )}

             {outsideHead.title && (
             <Typography
                variant={variant}
             >
                {outsideHead.title}
             </Typography>
             )}

             {outsideHead.childrenTitleRight && outsideHead.childrenTitleRight}

             {outsideHead.extraChildrenAfterBreadcrumps && outsideHead.extraChildrenAfterBreadcrumps}
          </div>
          )}

       </>
    );
  }
  return null;
};

export default CardWrapperOutsideHead;
