import React from 'react';
import clsx from 'clsx';
import { useStyles } from '../styles';

interface IProductCardStatusProps {
  isActive: boolean
}
export const ProductCardStatus = ({ isActive = true }:IProductCardStatusProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.card_status, {
      [classes.active_card]: isActive,
      [classes.disabled_card]: !isActive,
    })}
    >
      <b>
        {isActive ? 'Active' : 'Discontinued'}
      </b>
    </div>
  );
};
