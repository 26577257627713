import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  field_wrapper: {
    marginBottom: '2rem'
  },
  hidden: {
    display: 'none'
  },
  fieldComment: {
    marginBottom: '.8rem !important'
  },
  relative: {
    position: 'relative'
  },
  absolute: {
    position: 'absolute'
  },
  currency: {
    '& input': {
      paddingLeft: 25
    }
  },
  currencySymbol: {
    top: '67%',
    left: 10,
    position: 'absolute',
    fontSize: '1.3em',
    transform: 'translateY(-50%)'
  },
  required: {
    color: 'red'
  },
  field: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: (theme.shape.borderRadius as number) / 2
    }
  }
}));
