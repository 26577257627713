import { makeStyles } from '@material-ui/core';
import { Mixin } from '@monorepo/common';

//  '@media(max-width: 500px)': {}
export const useStyles = makeStyles((theme) => ({
  empty_wrapper: {
    height: 200,
    padding: 15,
    ...Mixin.mixin.flex('center', 'center'),
    borderBottom: '1px solid #E6E8F0'
  },
  lock_wrapper: {
    height: 175,
    width: '100%',
    padding: 15,
    ...Mixin.mixin.flex('center', 'center'),
    '& svg': {
      width: 80,
      height: 80,
      fill: '#bdbdbd'
    }
  },
  wrapper_content: {
    padding: '0 25px'
  },
  search_wrapper: {
    '& fieldset': {
      borderRadius: 0,
    }
  },
  chips_wrapper: {
    padding: '15px 25px',
    marginBottom: 10,
    borderBottom: '1px solid #E6E8F0',
    borderTop: '1px solid #E6E8F0',
    ...Mixin.mixin.flex('flex-start', 'flex-start'),
    flexWrap: 'wrap',
    gridGap: 15,
    '& *': {
      textTransform: 'capitalize'
    }
  },
  active_card: {},
  disabled_card: {},
  card_status: {
    color: '#fff',
    padding: '2px 10px',
    borderRadius: 50,
    marginRight: 'auto',
    height: '24px',
    '&$active_card': {
      background: theme.palette.info.main
    },
    '&$disabled_card': {
      background: theme.palette.error.main
    },
    '@media(max-width: 768px)': {
      margin: 'auto',
      marginTop: '0.5rem'
    }
  },
  add_new_product: {
    display: 'inline-block',
    width: '100%',
    maxWidth: '26rem',
    textDecoration: 'none',
    color: 'inherit',
    margin: '3rem 0 3rem',

  },
  wrapper: {},
  no_activated: {},
  open: {},
  body: {},
  card: {
    overflow: 'hidden',
    opacity: '0',
    position: 'relative',
    transform: 'translateY(35px)',
    transition: 'opacity .8s, transform .6s',
    borderBottom: 'none',
    width: '100%',
    '&$active': {
      transform: 'translateY(0)',
      opacity: '1',
    },
    '&:after': {
      top: 0,
      left: 0,
      width: 3,
      height: 0,
      content: ' " "',
      position: 'absolute',
      transition: '.4s',
      backgroundColor: theme.palette.primary.main
    },
    '&$open': {
      '&$open:after': {
        height: 'calc(100% + 1px)'
      },
      '& + $card': {
        background: 'rgba(55, 65, 81, 0.04)'
      }
    }
  },
  card_head: {
    gridGap: 25,
    width: '100%',
    padding: '15px',
    ...Mixin.mixin.flex('center', 'flex-start'),
    borderBottom: '1px solid rgb(230, 232, 240)',
    '&:hover': {
      background: 'rgba(55, 65, 81, 0.04)',
    },
    '& img': {
      objectFit: 'contain',
      width: 80,
      minWidth: 80,
      height: 80,
      borderRadius: 8,
      background: 'rgba(55, 65, 81, 0.04)',
      position: 'relative',
      transition: '.4s',
      border: '1px solid rgb(230, 232, 240)'
    },
  },
  card_content: {
    '& $body': {
      padding: 25,
      // borderBottom: '1px solid rgb(230, 232, 240)',
    }
  },
  card_content_footer: {
    padding: '14px 25px',
    borderTop: '1px solid rgb(230, 232, 240)',
    borderBottom: '1px solid rgb(230, 232, 240)',
    ...Mixin.mixin.flex('center', 'space-between'),
    '@media(max-width: 500px)': {
      display: 'block'
    }
  },
  empty: {
    width: 80,
    height: 80,
    padding: 27,
    minWidth: 80,
    borderRadius: 8,
    background: 'rgba(55, 65, 81, 0.04)',
    ...Mixin.mixin.flex('center', 'center')
  },
  title: {
    lineHeight: '1.3 !important'
  },
  active: {},
  internal_wrapper: {
    padding: '0 30px 30px'
  },
  arrow_button: {
    transform: 'rotate(0deg)',
    '&$active': {
      transform: 'rotate(90deg)',
    }
  },
  buttons_line: {
    ...Mixin.mixin.flex('center'),
    gridGap: 15,
    '& + button': {
      maxWidth: 200
    },
    '@media(max-width: 500px)': {
      flexDirection: 'column',
      marginBottom: 30,
      '& + button': {
        maxWidth: '100%'
      }
    }
  },
  fields_line: {
    ...Mixin.mixin.flex(),
    gridGap: 15,
    '@media(max-width: 768px)': {
      display: 'block'
    },
    '& > div': {
      flex: 1
    }
  },
  popoverPaper: {
    width: '100%',
    background: 'red'
  },
  captions: {
    marginTop: 15,
    backgroundColor: 'rgb(243, 244, 246)',
    padding: '13px 15px 13px 180px',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr',
    '@media(max-width: 768px)': {
      gridTemplateColumns: 'auto',
      textAlign: 'center',
      '& span:not($head_info_mobile)': {
        display: 'none'
      }
    }
  },
  product_info: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr',
    flexGrow: 1,
    '@media(max-width: 768px)': {
      gridTemplateColumns: 'auto',
      textAlign: 'center'
    }
  },
  sub_brand: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#7986cb'
  },
  head_info_mobile: {
    '@media(min-width: 769px)': {
      display: 'none'
    }
  }
}));
