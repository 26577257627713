import { IFieldAddressComponent } from '../../interfaces';

interface IParams {
  addressComponents: IFieldAddressComponent[],
  formattedAddress: string,
}
export const generateAddress = ({
  addressComponents,
  formattedAddress,
}:IParams) => {
  const result: any = {};
  if (addressComponents) {
    addressComponents.forEach((item) => {
      if (item.types.length > 1) {
        if (item.types.includes('administrative_area_level_1')) {
          result.province = item.long_name;
          result.state = item.short_name;
          return result;
        }
        if (item.types.includes('country')) {
          result.country = item.long_name;
          result.countryCode = item.short_name;
          return result;
        }
        if (item.types.includes('locality')) {
          return result.city = item.long_name;
        }
      } else {
        if (item.types.includes('street_number')) {
          return result.streetNumber = item.long_name;
        }
        if (item.types.includes('route')) {
          return result.address1 = item.short_name;
        }
        if (item.types.includes('postal_code')) {
          return result.zip = item.long_name;
        }
      }

      return result[item.types[0]] = item.long_name;
    });
    if (result.countryCode === 'US') {
      result.province = null;
    } else {
      result.state = null;
    }
    if (result.country === 'United States') {
      result.country = 'US';
    }
    result.address1 = `${result.streetNumber ? `${result.streetNumber} ` : ''}${result.address1 || ''}`;
    return {
      ...result,
      formattedAddress
    };
  }
  return {};
};
