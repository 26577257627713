import React from 'react';
import HttpsIcon from '@mui/icons-material/Https';
import { useStyles } from '../styles';

export const Lock = () => {
  const classes = useStyles();
  return (
    <div className={classes.lock_wrapper}>
      <HttpsIcon />
    </div>
  );
};
