export enum Permissions {
  SuperUser = 0,
  Orders = 9,
  Quotes = 11,
  MonthlyOrders = 12,
  JobsClassifieds = 13,
  Products = 14,
  ChargifyTransactions = 15,
  EventsLiveEventsLegacy = 16,
  BadgeTemplates = 17,
  ReviewQueue = 18,
  Brands = 19,
  UpdateQueue = 20,
  ProductTypes = 21,
  Companies = 22,
  Videos = 23,
  Photo = 24,
  BestOfAwards = 25,
  BevNETStaff = 26,
  Grids = 27,
  Maps = 28,
  AdUploads = 29,
  Circulation = 30,
  Newsletters = 31,
  Guides = 32,
  Messaging = 33,
  Users = 34,
  CensorPhrases = 35,
  SiteText = 36,
  PaymentExport = 37,
  IndustrySectors = 38,
  Promos = 39,
  Offerings = 40,
  Magazine = 41,
  Jobs = 42,
  Marketplace = 43,
  Eblasts = 44,
  Twitter = 45,
  Advertisers = 46,
  UploadSite = 47,
  FileSharing = 48,
  Education = 49,
  SalesUser = 50,
  Marketing = 51,
  Brewbound = 52,
  Nosh = 53,
  NewsSubmissions = 54,
  EventsGlobal = 55,
  EventsTickets = 56,
  EventsSite = 57,
  EventsRegDesk = 58,
  EventsAttendees = 59,
  EventsSponsors = 60,
  EventsSpeakers = 61,
  EventsEmail = 62,
  EventsReports = 63,
  EventsQA = 64,
  NoshReadOnly = 65,
  BrandsReadOnly = 66,
  BrewboundReadOnly = 67,
  AdvertisingShowHeaders = 68,
  UrlShortener = 69,
  SiteAlerts = 70,
  Subscriptions = 71,
  BrandSpotlights = 72,
  ContentCalendar = 73,
  UsersReadOnly = 74,
  SpeedDating = 75,
  Awards = 76,
  WebHooks = 77,
  LinkSharing = 78,
  FreeRequestQueue = 79,
  EblastsManageLists = 80,
  SubscriptionsReports = 81,
  Payments = 82,
  ZoomMeetings = 83,
  EblastReadOnly = 84,
  NewslettersReadOnly = 85,
}
