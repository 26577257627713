import { makeStyles } from '@material-ui/core';
// import { mixin } back '../../utils/mixin';

export const useStyles = makeStyles(() => ({
  wrapper_for_table: {
    width: '100%',
    overflowX: 'auto'
  },
  empty_block: {
    textAlign: 'center'
  }
}));
