import React, { ReactChild, ReactChildren, ReactNode, useEffect } from 'react';

interface ITableFooterMobileAmountProps {
  nameCol: string;
  nativeValue: number;
  setAllAmounts: () => void;
  children: ReactNode | ReactChildren | ReactChild | JSX.Element
}
export const TableFooterMobileAmount = ({ setAllAmounts, nativeValue, nameCol, children }:ITableFooterMobileAmountProps) => {
  useEffect(() => {
    setAllAmounts();
  }, [nameCol, nativeValue]);

  return (
     <>
        {children}
     </>
  );
};
