import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CardWrapper, FormAutocomplete, FormField, scrollUp, Btn, useHookForm } from '@monorepo/common';
import {
  ProductDetailsResponse,
  ProductSuBrandsOption } from '../../../services/serviceBrands/serviceBrandsInterface';
import {
  useGetBrandInfoQuery,
  useGetProductDetailsQuery,
  useUpdateProductDetailsMutation,
  useGetProductSubBrandsQuery,
  useGetBrandProductsQuery
} from '../../../services/serviceBrands';
import { showNotification } from '../../../features/sliceNotification';
import { useAccessStatus } from '../../../hook/useAccessStatus';
import { book } from '../../../routers/books';
import { schema } from './schema';
import { customButtonStyles } from '../../../constants/customButtonStyles';

// interface IEditProductInfoProps {}
const EditProductInfo = () => {
  const dispatch = useDispatch();
  const { isLoadingAccessStatus, brandGuid, productGuid, isRequest, noAccessComponent, hideContent } = useAccessStatus();
  const [productName, setProductName] = useState<string>('');

  const { data: productInfo, isLoading: isLoadingProductINfo } = useGetProductDetailsQuery({ brandGuid, productGuid }, {
    skip: !isRequest
  });
  const { data: brandInfo, isLoading } = useGetBrandInfoQuery({ brandGuid }, {
    skip: !isRequest
  });
  const { data: brandProducts, isLoading: productsLoading } = useGetBrandProductsQuery({ brandGuid }, {
    skip: !isRequest
  });
  const { data: options, isLoading: isLoadingSubBrands } = useGetProductSubBrandsQuery({ brandGuid }, {
    skip: !isRequest
  });

  const [updateProductDetails, { isLoading: isUpdatingInfo, isSuccess: isUpdated }] = useUpdateProductDetailsMutation();

  const {
    handleSubmit,
    setFormData,
    register,
    getValues,
    setValue,
    errors
  } = useHookForm<ProductDetailsResponse>({
    schema
  });
  const onSubmit = (data:ProductDetailsResponse) => {
    data.subBrand = data.subBrand || null;
    updateProductDetails({
      brandGuid,
      productGuid,
      body: data
    });
  };

  useEffect(() => {
    if (brandProducts) {
      const res = brandProducts.products.find((item) => item.productGuid === productGuid);
      setProductName(res.productName);
    }
  }, [brandProducts, productGuid]);

  useEffect(() => {
    if (productInfo) {
      setFormData(productInfo);
    }
    if (isUpdated && brandInfo) {
      if (brandInfo.authLevel === 2) {
        scrollUp();
        dispatch(showNotification({
          show: true,
          type: 'success',
          text: `Product "${productName}" was edited!`
        }));
        return;
      }
      scrollUp();
      dispatch(showNotification({
        show: true,
        type: 'info',
        text: 'Thank you for your update.  The BevNET brand listing has been updated.'
      }));
    }
  }, [isUpdated, scrollUp, brandInfo, productInfo]);

  return (
    <>
      <CardWrapper
        isHideChildren={hideContent}
        noAccessComponent={noAccessComponent}
        isLoading={isLoading || productsLoading || isLoadingProductINfo || isLoadingSubBrands || isUpdatingInfo || isLoadingAccessStatus}
        titleHelmet={noAccessComponent ? 'No Access' : `Edit Product: ${productName}`}
        outsideHead={{
          breadcrumbs: [
            {
              title: `${brandInfo?.brandName}`,
              link: book.brands.brandOptions(brandGuid)
            },
            {
              title: 'Products',
              link: book.brands.editProducts(brandGuid)
            },
            {
              title: `Edit Product: ${productName}`
            }
          ]
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField
            type="textarea"
            errors={errors}
            label="Ingredients"
            extraProps={{ ...register('ingredients') }}
          />
          <FormField
            type="textarea"
            errors={errors}
            label="Nutritional Info"
            extraProps={{ ...register('nutritionalInfo') }}
          />
          <FormAutocomplete<ProductSuBrandsOption>
            optionsSettings={{
              optionText: 'name',
              optionValue: 'id'
            }}
            options={(productInfo && options) || []}
            label="Sub Brand"
            errors={errors}
            extraProps={{ ...register('subBrand') }}
            setValue={setValue}
            getValues={getValues}
          />

          <Btn
            type="submit"
            customStyles={customButtonStyles}
          >
            Save
          </Btn>
        </form>
      </CardWrapper>
    </>
  );
};

export default memo(EditProductInfo);
