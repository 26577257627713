import React, { memo } from 'react';
import { Grid } from '@material-ui/core';

import { Btn, CardWrapper } from '@monorepo/common';

import { customButtonStyles } from '../../../constants/customButtonStyles';
import { publicProfileApi } from '../../../services/userProfile/public';
import { book } from '../../../routers/books';
import UserAvatar from './UserAvatar';
import UserSocial from './UserSocial';

import { useStyles } from '../styles';

// interface LeftContentProps {}

const ProfilePicBio = () => {
  const classes = useStyles();
  const { data, isLoading } = publicProfileApi.useGetQuery('');
  const linkedIn = (data && data.linkedIn) || null;
  const publicBio = (data && data.publicBio) || null;

  return (
    <Grid
      item
      md={4}
      sm={12}
    >
      <CardWrapper
        fullWidth
        centerText
        title="Profile Pic/Bio"
        isLoading={isLoading}
      >
        <UserAvatar />

        <UserSocial
          title="LinkedIn"
          link={linkedIn}
          plug="LinkedIn profile not set."
        />
        <UserSocial
          isLink={false}
          title="Public Bio"
          link={publicBio}
          plug="No bio has been entered."
        />

        <Btn
          link={book.userPublicProfile}
          className={classes.btn_edit_public_profile}
          customStyles={customButtonStyles}
        >
          Edit This Information
        </Btn>
      </CardWrapper>
    </Grid>
  );
};

export default memo(ProfilePicBio);
