import React, { memo, useEffect, useState } from 'react';
import { Typography } from '@mui/material';

interface IMaxLengthNotificationProps {
  maxLength: number,
  currentLength: number
}
const MaxLengthNotification = ({ maxLength, currentLength }:IMaxLengthNotificationProps) => {
  const [finalNumber, setFinalNumber] = useState(0);

  useEffect(() => {
    setFinalNumber(maxLength - currentLength);
  }, [maxLength, currentLength]);

  return (
     <Typography
        style={{ margin: 0 }}
        color={finalNumber <= 10 ? 'error' : 'black'}
        variant="body2"
        sx={{
          mt: 1,
          pl: 0.9
        }}
     >
        {finalNumber}
        /
        {maxLength}
     </Typography>
  );
};

export default memo(MaxLengthNotification);
