export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

export const IMAGES = {
  BEVNET_LOGO: '/img/BevNET-Logo.svg',
  NOSH_LOGO: '/img/NOSH-Logo.svg',
  BREWBOUND_LOGO: '/img/Brewbound-Logo.svg',
  TASTERADIO_HORIZONTAL_BLACK_LOGO: '/img/TasteRadio-Horizontal-Black-Logo.svg',
  TASTERADIO_HORIZONTAL_LOGO: '/img/TasteRadio-Horizontal-Logo.svg',
  TASTERADIO_HORIZONTAL_ORANGE_LOGO:
        '/img/TasteRadio-Horizontal-Orange-Logo.svg',
  TASTERADIO_STACKED_BLACK_LOGO: '/img/TasteRadio-Stacked-Black-Logo.svg',
  TASTERADIO_STACKED_LOGO: '/img/TasteRadio-Stacked-Logo.svg',
  TASTERADIO_STACKED_ORANGE_LOGO: '/img/TasteRadio-Stacked-Orange-Logo.svg',
};

export enum SubTypes {
  BEVNET_NOSH = 1,
  BREWBOUND = 2,
}

export const SUB_TYPES_ROUTE: { subType: SubTypes; value: string }[] = [
  { subType: SubTypes.BEVNET_NOSH, value: 'bevnet' },
  { subType: SubTypes.BREWBOUND, value: 'brewbound' },
];
