import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import endpoints from '../../api/endpoints';
import {
  IConfirmEmailRequest,
  IConfirmInvoiceRequest,
  IConfirmInvoiceResponse,
  ISaveTempDataRequest,
  IPaymentTypes,
  IRequestCreateInvoice, IVerifyCodeRequest, PaymentIntentRequest,
  ResponseInvoiceDetails,
  ResponseQuotesDetails
} from './responseInterfaces';

export const invoiceApi = createApi({
  reducerPath: 'invoices',
  tagTypes: ['Invoices'],
  baseQuery: fetchBaseQuery({
    baseUrl: endpoints.homeUrl,
  }),
  endpoints: (build) => ({
    getInvoiceDetails: build.query<ResponseInvoiceDetails, null>({
      query: () => ({
        url: endpoints.invoices.invoiceDetails,
      }),
    }),
    getInvoiceDetailsFromTempData: build.query<ResponseInvoiceDetails, string>({
      query: (guid) => ({
        url: endpoints.invoices.invoiceDetailsFromTempData(guid),
      }),
    }),
    getQuotesDetails: build.query<ResponseQuotesDetails, { id: string }>({
      query: ({ id }) => ({
        url: endpoints.invoices.quotesDetails(id),
      }),
      providesTags: () => ['Invoices']
    }),

    getPaymentTypes: build.query<IPaymentTypes, null>({
      query: () => ({
        url: endpoints.invoices.paymentTypes,
      }),
    }),

    postCreateDetail: build.mutation<number, IRequestCreateInvoice>({
      query: (body) => ({
        url: endpoints.invoices.createDetail,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Invoices']
    }),

    putUpdateDetail: build.mutation<number, { body: IRequestCreateInvoice, id: string }>({
      query: ({ id, body }) => ({
        url: endpoints.invoices.updateDetail(id),
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Invoices']
    }),
    removeDetail: build.mutation<number, { id: string }>({
      query: ({ id }) => ({
        url: endpoints.invoices.deleteDetail(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Invoices']
    }),

    confirmInvoice: build.mutation<IConfirmInvoiceResponse, IConfirmInvoiceRequest>({
      query: (body) => ({
        url: endpoints.invoices.confirmInvoice,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Invoices']
    }),

    saveTempData: build.mutation<any, ISaveTempDataRequest>({
      query: (body) => ({
        url: endpoints.invoices.saveTempData,
        method: 'POST',
        body
      }),
    }),

    paymentIntent: build.mutation<{ clientSecret: string }, PaymentIntentRequest>({
      query: (body) => ({
        url: endpoints.invoices.paymentIntent,
        method: 'POST',
        body
      }),
    }),

    confirmEmail: build.mutation<any, IConfirmEmailRequest>({
      query: (body: IConfirmEmailRequest) => ({
        url: endpoints.invoices.confirmEmail,
        method: 'POST',
        body
      })
    }),

    verifyCode: build.mutation<any, IVerifyCodeRequest>({
      query: (body: IVerifyCodeRequest) => ({
        url: endpoints.invoices.verifyCode,
        method: 'PUT',
        body
      })
    })
  }),
});
export const {
  useLazyGetQuotesDetailsQuery,
  useGetInvoiceDetailsQuery,
  useGetInvoiceDetailsFromTempDataQuery,
  useGetPaymentTypesQuery,

  usePutUpdateDetailMutation,
  useRemoveDetailMutation,
  usePostCreateDetailMutation,

  // final invoice request
  useSaveTempDataMutation,
  useConfirmInvoiceMutation,
  usePaymentIntentMutation,

  useConfirmEmailMutation,
  useVerifyCodeMutation
} = invoiceApi;
