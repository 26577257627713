import React, { memo } from 'react';
import { List } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { CardWrapper } from '@monorepo/common';

import BrandLink from './components/BrandLink';
import { IBrandOptionsList } from './interface';
import BrandInfo from './components/BrandInfo';
import { brandOptionList } from './brandOptionList';
import { useAccessStatus } from '../../../hook/useAccessStatus';
import { useGetBrandInfoQuery } from '../../../services/serviceBrands';

// interface IBrandProps {}
const BrandOptions = () => {
  const { accessLevelNumber, isLoadingAccessStatus, brandGuid, isRequest, noAccessComponent, hideContent, slug } = useAccessStatus();

  const { data, isLoading } = useGetBrandInfoQuery({ brandGuid }, {
    skip: !isRequest,
  });

  return (
    <CardWrapper
      isHideChildren={hideContent}
      noAccessComponent={noAccessComponent}
      isLoading={isLoading || isLoadingAccessStatus}
      helmet={noAccessComponent ? 'No Access' : `Edit brand ${data?.brandName}`}
      title={noAccessComponent ? 'No Access' : `Edit the BevNET Listing for ${data?.brandName}`}
    >
      {accessLevelNumber && accessLevelNumber === 2 ? (
        <>
          <BrandInfo slug={slug} />
          <List>
            {brandOptionList(brandGuid, slug).map(({ isHref, link, icon, subText, title }:IBrandOptionsList, i) => (
              <BrandLink
                id={i}
                isHref={isHref}
                key={title}
                link={link}
                title={title}
                icon={icon}
                subText={subText}
              />
            ))}
          </List>

          {data?.supplierEGuid && data?.supplierEGuid && (
          <BrandLink
            isHref
            id={brandOptionList.length + 1}
            link={`https://app.bevnet.com/supplierfinder/${data.supplierEGuid}/details`}
            title={`Edit Company Listing: ${data.supplierName}`}
            icon={(<EditIcon />)}
            subText="Click to edit the company listing."
          />
          )}
        </>
      ) : null}

    </CardWrapper>
  );
};

export default memo(BrandOptions);
