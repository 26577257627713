import React from 'react';
import { Divider } from '@material-ui/core';
import { HelmetProvider } from 'react-helmet-async';

import Loader from '../../components/Loader';
import ErrorBoundary from '../ErrorBoundary';
import { ICardWrapperProps } from './interfaceCardWrapper';
import CardWrapperTitle from './components/CardWrapperTitle';
import CardWrapperTopPanel from './components/CardWrapperTopPanel';
import CardWrapperOutsideHead from './components/CardWrapperOutsideHead';
import CardWrapperModalWindow from './components/CardWrapperModalWindow';
import CardWrapperInternalWrapper from './components/CardWrapperInternalWrapper';

const CardWrapper = ({
  title,
  helmet,
  children,
  maxWidth,
  outsideHead,
  titleHelmet,
  subtitle,
  align = 'center',
  scrollOptions,
  isNoPadding = false,
  fullWidth = false,
  isLoading = false,
  noOverflow = false,
  centerText = false,
  titleCenter = false,
  lateralPadding = false,
  isHideChildren = false,
  noAccessComponent = null,
  hiddenContentWhenIsLoading = false,
  classNameFotTitle,
  childrenTitleRight,
  modalWindowOptions,
  classnameForWrapper,
  onClick,
  classNameForInternalBlock,
  dataCy
}: ICardWrapperProps) => {
  const internalStyles = maxWidth && { style: { maxWidth } };

  return (
     <>
        <HelmetProvider>
           <CardWrapperModalWindow modalWindowOptions={modalWindowOptions} />
           <CardWrapperTitle title={helmet || titleHelmet} />
           <Loader isShow={isLoading} />

           <CardWrapperOutsideHead
              align={align}
              maxWidth={maxWidth}
              internalStyles={internalStyles}
              outsideHead={outsideHead}
           />

           <CardWrapperInternalWrapper
              dataCy={dataCy}
              align={align}
              onClick={onClick}
              isPaddingTop={!title || !titleHelmet}
              fullWidth={fullWidth}
              noOverflow={noOverflow}
              centerText={centerText}
              isNoPadding={isNoPadding}
              scrollOptions={scrollOptions}
              internalStyles={internalStyles}
              lateralPadding={lateralPadding}
              noAccessComponent={noAccessComponent}
              classnameForWrapper={classnameForWrapper}
              classNameForInternalBlock={classNameForInternalBlock}
              isHideChildren={isHideChildren || hiddenContentWhenIsLoading}
           >
              <CardWrapperTopPanel
                 titleCenter={titleCenter}
                 title={title || titleHelmet}
                 subtitle={subtitle}
                 classNameFotTitle={classNameFotTitle}
                 childrenTitleRight={childrenTitleRight}
              />
              <ErrorBoundary>
                 {childrenTitleRight && <Divider />}
                 {children}
              </ErrorBoundary>
           </CardWrapperInternalWrapper>
        </HelmetProvider>
     </>
  );
};

export default CardWrapper;
