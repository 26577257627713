import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { checkEmailInitialState, ICheckEmailReducer } from './checkEmailInitialState';

export const slice = createSlice({
  name: 'userEmail',
  initialState: checkEmailInitialState,
  reducers: {
    statusEmail: (state:ICheckEmailReducer, action:PayloadAction<boolean>): void => {
      state.isEmailExist = action.payload;
    },
    statusConfirmEmail: (state:ICheckEmailReducer, action:PayloadAction<boolean>): void => {
      state.isEmailConfirmed = action.payload;
    },
  },
});

export const { statusEmail, statusConfirmEmail } = slice.actions;
export default slice.reducer;
