import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Card, Step, StepContent } from '@mui/material';
import clsx from 'clsx';

import Wrapper from './components/Wrapper';
import { scrollUp } from '../../utils/scrollUp';
import StepperHeadLabel from './components/StepperHeadLabel';
import StepsLineButtons from './components/StepsLineButtons';
import { IStepperRightToolbar, IStepsLineButtonsProps, IUseStepperProps } from './interface';
import Loader from '../../components/Loader';

import { useStyles } from './styles';

interface ISteps {
  label: string,
  content: ReactNode
}

export const useStepper = ({
  helmet,
  isLoading,
  styleProps,
  leftInfoPanel,
  finalComponent,
  backStepSettings,
  none_data = false,
  customActiveStep = 0,
  isDevelopMode = false,
  orientation = 'horizontal',
  hiddenContentWhenIsLoading = false,
  leftInfoPanelPhonePosition = 'bottom',
  isHideLeftInfoPanelAfterComplete = false,
  isCardWrapperForEveryStep = false,
  isClickOnTheMarkerStepper = true

}:IUseStepperProps) => {
  const classes = useStyles();

  const [iconColor, setIconColor] = useState<string | null>(null);

  const [steps, setSteps] = useState<ISteps[]>([]);
  const [activeStep, setActiveStep] = useState<number>(customActiveStep);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [numbersFilledStep, setNumbersFilledStep] = useState<number[]>([]);

  const [stepperRightToolbar, setStepperRightToolbar] = useState<IStepperRightToolbar | null>(null);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollUp();
  };

  const handleComplete = () => {
    setIsComplete(true);
    setNumbersFilledStep([]);
  };
  const handlerChooseStep = useCallback((i: number) => {
    if (!isDevelopMode) {
      if (numbersFilledStep.includes(i)) {
        setActiveStep(i);
      }
      return;
    }
    setActiveStep(i);
  }, [numbersFilledStep, setActiveStep, isDevelopMode]);

  const putSteps = (listSteps:ISteps[]) => {
    setSteps(listSteps);
  };

  const putStepperRightToolbar = (elem:IStepperRightToolbar | null) => {
    setStepperRightToolbar(elem);
  };

  const resetActiveStep = () => {
    setActiveStep(0);
  };

  const dropStepper = useCallback(() => {
    setActiveStep(0);
    setSteps([]);
  }, []);

  useEffect(() => {
    if (!numbersFilledStep.includes(activeStep)) {
      setNumbersFilledStep((item) => [
        ...item,
        activeStep
      ]);
    }
  }, [activeStep, numbersFilledStep]);

  const renderStepper = () => !hiddenContentWhenIsLoading && (
     <Wrapper
        isHideLeftInfoPanelAfterComplete={isHideLeftInfoPanelAfterComplete && isComplete}
        leftInfoPanelPhonePosition={leftInfoPanelPhonePosition}
        stepperRightToolbar={stepperRightToolbar}
        backStepSettings={backStepSettings}
        finalComponent={finalComponent}
        leftInfoPanel={leftInfoPanel}
        orientation={orientation}
        activeStep={activeStep}
        styleProps={styleProps}
        none_data={none_data}
        isLoading={isLoading}
        complete={isComplete}
        helmet={helmet}
     >
        {steps.map((step, index) => (
           <Step key={step.label}>
              <StepperHeadLabel
                 setActiveStep={() => isClickOnTheMarkerStepper && handlerChooseStep(index)}
                 {...(iconColor) && { stepIconColor: iconColor }}
                 label={step.label}
              />

              <StepContent
                 className={clsx({
                   [classes.active_step]: activeStep === index
                 })}
              >
                 {isCardWrapperForEveryStep ? (<Card>{step.content}</Card>)
                   : step.content}

              </StepContent>
           </Step>
        ))}
     </Wrapper>
  );

  const StepsButtons = (props:IStepsLineButtonsProps) => <StepsLineButtons {...props} />;

  return ({
    renderStepper: renderStepper() || <Loader isShow />,
    onComplete: handleComplete,
    putStepperRightToolbar,
    onNext: handleNext,
    onBack: handleBack,
    resetActiveStep,
    setIconColor,
    StepsButtons,
    dropStepper,
    putSteps
  });
};

export default useStepper;
