import React, { ReactNode, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import Header from '../../components/Header';
import { ChildrenProps } from '../../interfaces';
import { useAppSelector } from '../../store/hooks';
import GeneralOverlay from './components/GeneralOverlay';
import { toggleMobileMenu } from '../../features/ui/sliceMobileMenu';

import { useStyles } from './styles';
import { book } from '../../routers/books';

interface IGeneralLayout extends ChildrenProps {
  projectNavigation: ReactNode;
  isAuth?: boolean;
}

const Layout = ({ children, projectNavigation, isAuth }: IGeneralLayout) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { isMobileMenu } = useAppSelector(({ ui }) => ({
    isMobileMenu: ui.isMobileMenu,
  }));

  useEffect(() => {
    dispatch(toggleMobileMenu(false));
  }, [pathname]);

  let clearPath = pathname;
  if (clearPath.endsWith('/') && clearPath !== '/') {
    clearPath = clearPath.slice(0, -1);
  }

  if (!isAuth && clearPath !== book.invoices.invoice
    && clearPath !== book.invoices.confirmEmail
    && clearPath !== book.invoices.invoiceCompleted) {
    return null;
  }

  return (
    <div className={classes.root}>
      {/* left menu */}
      { isAuth && projectNavigation }
      <main className={clsx(classes.content)}>
        <CssBaseline />
        <Header />
        <div
          className={clsx(classes.work_zone, {
            [classes.menuOpen]: isMobileMenu,
          })}
        >
          {children}
        </div>
        {isMobileMenu && <GeneralOverlay />}
      </main>
    </div>
  );
};

export default Layout;
