export const cookieNames = {
  bevnet_token: 'bevnet_auth_token',
  access_token: 'access_token',
  refresh_token: 'refresh_token',
  tempDataId: 'tempDataSubscribe',

  tempJob: 'tempJob',
  tempPrepaidData: 'tempPrepaidData',
  tempRegisterData: 'tempRegisterData',
  tempReactivateJob: 'tempReactivateJob',

  invoice_token: 'invoice_token'
};
