import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { scrollUp, Loader, readConfig } from '@monorepo/common';
import { showNotification } from '../../../../features/sliceNotification';
import { usePaymentIntentMutation } from '../../../../services/serviceInvoice';
import Step2, { IStep2Props } from './index';

const Step2StripeWrapper = ({ onBack, Nav, dataForStep1, onNext, billingAmount, setDataForStep2, dataForStep2 }: IStep2Props) => {
  const [stripePromise] = useState(() => loadStripe(readConfig().stripeKey));
  const [elementsOptions, setElementsOptions] = useState<{ clientSecret: string }>(null);
  const dispatch = useDispatch();

  const [paymentIntent, { isLoading: isLoadingClientSecret }] = usePaymentIntentMutation();

  useEffect(() => {
    const fetchClientSecret = async () => {
      const clientSecretResponse = await paymentIntent({
        amount: billingAmount
      });

      if (!clientSecretResponse) {
        scrollUp();
        dispatch(showNotification({
          show: true,
          type: 'error',
          text: "Error: Payment Intent doesn't work"
        }));
        return;
      }
      // success  => next request
      const { data: { clientSecret: clSecret } } = clientSecretResponse as { data: { clientSecret: string } };
      setElementsOptions({ clientSecret: clSecret });
    };

    fetchClientSecret();
  }, []);

  if (!elementsOptions) {
    return null;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={elementsOptions}
    >
      <Loader isShow={isLoadingClientSecret} />
      <Step2
        onBack={onBack}
        Nav={Nav}
        dataForStep1={dataForStep1}
        onNext={onNext}
        billingAmount={billingAmount}
        setDataForStep2={setDataForStep2}
        dataForStep2={dataForStep2}
      />
    </Elements>
  );
};

export default Step2StripeWrapper;
