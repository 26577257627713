import { makeStyles } from '@material-ui/core';
// import { mixin } from '../../utils/mixin';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto !important',
    padding: '5px 5px 5px ',
    '& a': {
      color: theme.palette.primary.light
    }
  }
}));
