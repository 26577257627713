import React, { ChangeEvent } from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';

import { IControllerName, IInternalFieldProps } from '../formFieldInterface';
import MaxLengthNotification from './MaxLengthNotification';
import { formatterLabel } from '../../FormFieldUtils';
import { insertIcon } from '../insertIcon';

export const TextFieldVariant = ({
  isRequired,
  placeholder,
  label,
  messageFromExtraError,
  errors,
  type,
  lengthField,
  maxLength,
  autoComplete,
  valueField,
  name,
  textAreaRow = 6,

  classNameForField,
  customInputProps,
  inputLabelProps,
  endAdornment,
  startAdornment,

  extraProps,
  isDisabled = false,

  eventFocusField,
  eventBlurField,
  eventChangeField,
  eventInputField,

}:IInternalFieldProps) => {
  if (extraProps) {
    return (
       <Controller
          name={extraProps.name as IControllerName}
          control={extraProps.control}
          defaultValue=""
          render={({ field }) => (
             <TextField
                disabled={isDisabled}
                value={field.value || valueField || ''}
                ref={field.ref}
                onBlur={({ target: { value } }) => {
                  eventBlurField(value);
                }}
                {...(name) && { name }}
                onFocus={(e) => {
                  eventFocusField(e as React.FormEvent<HTMLInputElement>);
                }}
                onInput={eventInputField}
                onChange={(e) => {
                  field.onChange(e);
                  eventChangeField(e as ChangeEvent<HTMLInputElement>);
                }}
                autoComplete={!autoComplete ? 'no' : ''}
                className={clsx(classNameForField)}
                placeholder={placeholder && (placeholder.charAt(0).toUpperCase() + placeholder.slice(1))}
                rows={type === 'textarea' ? textAreaRow : 1}
                multiline={type === 'textarea' && true}
                type={type}
                error={(extraProps && !!errors[extraProps.name]) || (!!messageFromExtraError)}
                fullWidth
                label={formatterLabel({ label, isRequired })}
                variant="outlined"
                helperText={(extraProps && errors[extraProps.name] && errors[extraProps.name].message) || (messageFromExtraError)}
                InputProps={{
                  startAdornment: insertIcon(startAdornment),
                  endAdornment: insertIcon(
                     <>
                        {endAdornment}
                        {maxLength && (
                        <MaxLengthNotification
                           maxLength={maxLength}
                           currentLength={field.value?.length || valueField?.length || 0}
                        />
                        )}
                     </>
                  ),
                  inputProps: {
                    ...(!maxLength && (customInputProps || {})) || { maxLength },
                    'data-cy': extraProps.name
                  },
                }}
                InputLabelProps={inputLabelProps}
             />
          )}
       />
    );
  }
  return (
     <TextField
        value={valueField}
        onBlur={({ target: { value } }) => {
          eventBlurField(value);
        }}
        {...(name) && { name }}
        onFocus={(e) => {
          eventFocusField(e as React.FormEvent<HTMLInputElement>);
        }}
        onInput={eventInputField}
        onChange={(e) => {
          eventChangeField(e as ChangeEvent<HTMLInputElement>);
        }}
        autoComplete={!autoComplete ? 'no' : ''}
        className={clsx(classNameForField)}
        placeholder={placeholder && (placeholder.charAt(0).toUpperCase() + placeholder.slice(1))}
        rows={type === 'textarea' ? textAreaRow : 1}
        multiline={type === 'textarea' && true}
        type={type}
        error={(!!messageFromExtraError)}
        fullWidth
        label={formatterLabel({ label, isRequired })}
        variant="outlined"
        helperText={(messageFromExtraError)}
        InputProps={{
          startAdornment: insertIcon(startAdornment),
          endAdornment: insertIcon(
             <>
                {endAdornment}
                {maxLength && (
                <MaxLengthNotification
                   maxLength={maxLength}
                   currentLength={lengthField}
                />
                )}
             </>
          ),
          inputProps: (!maxLength && (customInputProps || {})) || { maxLength },
        }}
     />
  );
};
