import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Btn } from '@monorepo/common';
import { Api } from '../../api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DiscordVerify = () => {
  const [showSubscribeLink, setShowSubscribeLink] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);

  const query = useQuery();

  async function verifyDiscord() {
    try {
      const jwt = query.get('jwt') ?? '';
      const result = await Api.discord.verifyDiscord(jwt);
      setCompleted(true);
      setShowSubscribeLink(result.showSubscribe);
    } catch (e) {
      // console.log(e.response.data);
    }
  }

  useEffect(() => {
    verifyDiscord();
  }, []);

  return (
    <>
      <p>verifying Discord account</p>
      {completed && (
      <>
        <p>Complete! You can close this window.</p>
        <p>Please note that it may take a few minutes to become verified in discord</p>
        <p>&nbsp;</p>
      </>
      )}
      {showSubscribeLink && (
      <Btn
        link="https://app.bevnet.com/subscribe"
        external
      >
        Subscribe
      </Btn>
      )}
    </>
  );
};

export default DiscordVerify;
