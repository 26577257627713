import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import cookies from 'js-cookie';

import { cookieNames, baseUrls, GlobalInterfaces, pageRedirect } from '@monorepo/common';

import { showNotification } from '../features/sliceNotification';

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrls.api,
  prepareHeaders: (headers) => {
    headers.set('Authorization', `Bearer ${cookies.get(cookieNames.bevnet_token)}`);
    return headers;
  }
});
interface IOldErrorModel {
  data?: {
    error?: string
  },
  status?: number
}
const errorStatus: number[] = [400, 401, 403];

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (cookies.get(cookieNames.bevnet_token)) {
    if (result.error && (!errorStatus.includes(result.error.status as number))) {
      if ('data' in result.error) {
        const err = result.error as IOldErrorModel;
        if (err.data?.error) {
          api.dispatch(showNotification({
            type: 'error',
            show: true,
            text: err.data?.error
          }));
        }
      }
    }

    if (result.error && result.error.status === 400) {
      const err = result.error as GlobalInterfaces.IErrorProps;
      let errText: string = '';
      if (err.data.errors) {
        Object.keys(err.data.errors).forEach((item) => {
          // @ts-ignore
          errText += `${err.data.errors[item]} \n`;
        });

        api.dispatch(showNotification({
          type: 'error',
          show: true,
          text: errText
        }));
      }
    }

    if (result.error && ('originalStatus' in result.error)) {
      const err = result.error as GlobalInterfaces.IErrorQueryProps;
      if (err?.originalStatus === 500) {
        api.dispatch(showNotification({
          type: 'error',
          show: true,
          text: 'Internal server Error!'
        }));
      }
      if (err?.originalStatus === 400) {
        api.dispatch(showNotification({
          type: 'error',
          show: true,
          text: err.data
        }));
      }
    }

    if (result.error && ('originalStatus' in result.error)) {
      const err = result.error as GlobalInterfaces.IErrorQueryProps;
      if (err?.originalStatus === 500) {
        api.dispatch(showNotification({
          type: 'error',
          show: true,
          text: 'Internal server Error!'
        }));
      }
      if (err?.originalStatus === 400) {
        api.dispatch(showNotification({
          type: 'error',
          show: true,
          text: err.data
        }));
      }
    }

    if (result.error && result.error.status === 401) {
      api.dispatch(showNotification({
        type: 'error',
        show: true,
        text: "Sorry, you don't  authorized"
      }));
      pageRedirect({ token: cookies.get(cookieNames.bevnet_token) });
    } else if (result.error && result.error.status === 403) {
      api.dispatch(showNotification({
        type: 'error',
        show: true,
        text: 'Sorry, you do not have  permission'
      }));
      pageRedirect({ token: cookies.get(cookieNames.bevnet_token) });
    }
  } else {
    pageRedirect({ token: cookies.get(cookieNames.bevnet_token), delay: 0 });
  }

  return result;
};
