import React, { memo } from 'react';
import { Table, TableBody, Grid } from '@material-ui/core';

import { Btn, CardWrapper } from '@monorepo/common';

import UserInfoLine from './UserInfoLine';
import { book } from '../../../routers/books';
import { customButtonStyles } from '../../../constants/customButtonStyles';
import { useGetPersonalInfoQuery } from '../../../services/userProfile/personal';

// interface CardContentProps {}

const PersonalInformation = () => {
  // this array need for template testEventsList Personal Information
  const banList: string[] = [
    'uguid',
    'headshot',
    'publicBio',
    'linkedIn'
  ];
  const { data, isLoading: isLoadingData } = useGetPersonalInfoQuery('');

  return (
    <Grid
      item
      md={8}
      sm={12}
    >
      <CardWrapper
        fullWidth
        title="Personal Information"
        isLoading={isLoadingData}
        childrenTitleRight={(
          <Btn
            link={book.userUpdate}
            customStyles={customButtonStyles}
          >
            Edit This Information
          </Btn>
)}
      >
        <Table>
          <TableBody>
            {data && data.uguid && Object.keys(data).map((item: string) => {
              // @ts-ignore
              const value = data[item];
              if (!banList.includes(item)) {
                return (
                  <UserInfoLine
                    key={item}
                    name={item}
                    value={value}
                  />
                );
              }

              return null;
            })}
          </TableBody>
        </Table>
      </CardWrapper>
    </Grid>
  );
};

export default memo(PersonalInformation);
