import React, { memo, useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ImageUploading, { ImageListType } from 'react-images-uploading';

import DefaultPlug from './components/DefaultPlug';
import FileImage from './components/FileImage';
import { IFileProps, IFormFieldUploadProps } from './interface';
import { AreaSubtitle } from './components/AreaSubtitle';
import ImgSelectFile from './components/undraw_add_file2_gvbb.svg';

const defaultData = {
  mainTitle: '',
  mainSubtitleTitle: <AreaSubtitle />,
  image: ImgSelectFile
};

const FormFieldUpload = ({
  onChange,
  onRemove,
  defaultValue,
  formats = [],
  removeLabel,
  needConfirm,
  confirmText,
  multiple = false,
  classNameForWrap,
  classNameForImage,
  maxFilesUpload = 1,
  uploadAreaSettings,
  isRemoveUploadedFile = false,
  maxSizeBytes = 5242880
}:IFormFieldUploadProps) => {
  const [images, setImages] = React.useState<IFileProps[]>([]);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const change = useCallback((imageList: ImageListType) => {
    setImages(imageList as never[]);
  }, [images]);

  const onFileRemove = useCallback((status:boolean) => {
    if (onRemove) {
      onRemove(status);
    }
    setIsChanged(true);
    const timeout = setTimeout(() => {
      setIsChanged(false);
      clearTimeout(timeout);
    }, 1);
  }, [onRemove]);

  useEffect(() => {
    if (defaultValue) {
      setImages([
        {
          dataURL: defaultValue
        }
      ]);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (images.length && isRemoveUploadedFile) {
      setImages([]);
    }
  }, [images, isRemoveUploadedFile]);

  useEffect(() => {
    if (!multiple && images.length) {
      if (onChange && images[0].file && !isChanged) {
        setIsChanged(true);
        onChange(images[0].file);
      }
    }
  }, [images, multiple, onChange, isChanged]);

  return (
     <>
        <ImageUploading
           multiple={multiple}
           value={images}
           onChange={change}
           maxNumber={maxFilesUpload}
           acceptType={formats}
           maxFileSize={maxSizeBytes}
           onError={(err, lastFile) => {
             if (!multiple && err?.maxNumber && lastFile) {
               setImages(lastFile as IFileProps[]);
             }
           }}
        >
           {({
             imageList,
             onImageUpload,
             onImageRemove,
             dragProps,
             errors
           }) => (
              <>
                 {imageList.map((image, index) => (
                    <FileImage
                       index={index}
                       src={image.dataURL}
                       onFileRemove={onFileRemove}
                       className={classNameForImage}
                       removeLabel={removeLabel}
                       confirmText={confirmText}
                       needConfirm={needConfirm}
                       onImageRemove={onImageRemove}
                       key={`${index.toString()}`}
                    />
                 ))}

                 <DefaultPlug
                    uploadAreaSettings={{
                      ...defaultData,
                      ...uploadAreaSettings
                    }}
                    maxNumber={maxFilesUpload}
                    className={classNameForWrap}
                    errors={errors}
                    dropsProps={dragProps}
                    onImageUpload={onImageUpload}
                    maxFileSize={maxSizeBytes}
                 />
              </>
           )}
        </ImageUploading>
     </>
  );
};

export default memo(FormFieldUpload);
