import React, { memo, useEffect, useState } from 'react';

import { useStepper } from '@monorepo/common';

import Step1 from './steps/Steps1';
import Step2StripeWrapper from './steps/Steps2/Step2StripeWrapper';

import DescriptionInvoices from './components/DescriptionInvoices';
import { IDataForStep1Props } from './interfaceSteps';
import { InvoiceStep2FormProps } from '../../services/serviceInvoice/responseInterfaces';

const Invoices = () => {
  // data steps
  const [billingAmount, setBillingAmount] = useState<number>(0);
  const [dataForStep1, setDataForStep1] = useState<IDataForStep1Props>({ guid: null });
  const [dataForStep2, setDataForStep2] = useState<InvoiceStep2FormProps | null>(null);

  const { putSteps, onNext, onBack, onComplete, renderStepper, StepsButtons } = useStepper({
    orientation: 'vertical',
    helmet: <title>Invoices</title>,
    leftInfoPanel: <DescriptionInvoices />
  });

  useEffect(() => {
    putSteps([
      {
        label: 'Enter Invoices to Pay',
        content: (
          <Step1
            onNext={onNext}
            Nav={StepsButtons}
            setDataForStep1={setDataForStep1}
            setBillingAmount={setBillingAmount}
          />
        )
      },
      {
        label: 'Payment Information',
        content: (
          <Step2StripeWrapper
            onBack={onBack}
            Nav={StepsButtons}
            onNext={onComplete}
            dataForStep2={dataForStep2}
            dataForStep1={dataForStep1}
            billingAmount={billingAmount}
            setDataForStep2={setDataForStep2}
          />
        )
      }
    ]);
  }, [dataForStep1, billingAmount, dataForStep2]);

  return renderStepper;
};

export default memo(Invoices);
