import { Btn, BtnMenu, CardWrapper, Grid } from '@monorepo/common';
import { Typography } from '@mui/material';
import moment from 'moment';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { NewsSites } from '../../../constants/newsSites';
import { IJobListingItem } from '../../../interfaces';
import { book } from '../../../routers/books';
import { jobsApi } from '../../../services/jobs/index';
import JobListingItem from '../Common/components/JobListingItem';
import { useStyles } from './styles';
import { onEditAndCopyJobListingHandler, onReactivateJobListingHandler } from '../Common/common';

export const JobsListing = () => {
  const classes = useStyles();
  const history = useHistory();

  const onPostHandler = (site: NewsSites) => {
    let siteUrl = '';

    if (site === NewsSites.Brewbound) {
      siteUrl = 'brewbound';
    } else if (site === NewsSites.Nosh) {
      siteUrl = 'nosh';
    }

    window.open(`https://jobs.bevnet.com/postjoblisting/${siteUrl}`, '_blank');
  };

  const onViewApplicants = (adEguid: string) => {
    history.push(book.jobs.jobsApplicants(adEguid), { from: 'jobListing' });
  };

  return (
    <CardWrapper
      outsideHead={{
        title: 'Job Listings',
        childrenTitleRight: (
          <BtnMenu
            items={[
              {
                menuText: 'Bevnet',
                action: () => onPostHandler(NewsSites.Bevnet),
              },
              {
                menuText: 'Brewbound',
                action: () => onPostHandler(NewsSites.Brewbound),
              },
              {
                menuText: 'Nosh',
                action: () => onPostHandler(NewsSites.Nosh),
              },
            ]}
          >
            Post New Job Listing
          </BtnMenu>
        ),
      }}
      helmet="Job Listings"
      maxWidth="90rem"
    >
      <Typography
        color="textPrimary"
        variant="h6"
        className={classes.subtitle}
      >
        Active Listings
      </Typography>
      <Grid<IJobListingItem>
        keyExtractor={(col) => col.id as string}
        apiProps={{
          api: jobsApi,
          apiMethodName: 'useLazyFetchActiveJobsListingQuery',
        }}
        defaultGridProps={{
          defaultSortBy: 'dated',
          defaultSortDirection: 'desc',
          defaultPageSize: 10,
        }}
        searchableProps={{
          showSearchField: false,
        }}
        columns={[
          {
            title: 'Job Listing',
            field: 'title',
            template: (col) => (
              <JobListingItem
                key={col.id}
                description={col.shortDescription}
                image={col.image}
                title={col.title}
                isActive
                path={col.path}
              />
            ),
          },
          {
            title: 'Location',
            field: 'location',
          },
          {
            title: 'Expires',
            field: 'dated',
            template: (col) => (col.dated ? moment(col.dated).format('MM/DD/YYYY') : '-'),
          },
          {
            title: 'Total Applicants',
            field: 'applicantCount',
            align: 'center',
          },
          {
            title: 'empty',
            field: 'lastCol',
            template: (col) => (
              <div className={classes.actions}>
                <Btn
                  type="button"
                  click={() => onViewApplicants(col.eguid)}
                >
                  Applicants
                </Btn>
                <Btn
                  type="button"
                  click={() => onEditAndCopyJobListingHandler(col.siteId, col.eguid)}
                >
                  Edit
                </Btn>
                <Btn
                  type="button"
                  click={() => onEditAndCopyJobListingHandler(col.siteId, col.eguid)}
                >
                  Copy and Create New Listing
                </Btn>
              </div>
            ),
          },
        ]}
      />
      <Typography
        color="textPrimary"
        variant="h6"
        className={classes.subtitle}
      >
        Inactive Job Listings
      </Typography>
      <Grid<IJobListingItem>
        keyExtractor={(col) => col.id as string}
        apiProps={{
          api: jobsApi,
          apiMethodName: 'useLazyFetchInactiveJobsListingQuery',
        }}
        defaultGridProps={{
          defaultSortBy: 'dated',
          defaultSortDirection: 'desc',
          defaultPageSize: 10,
        }}
        searchableProps={{
          showSearchField: false,
        }}
        columns={[
          {
            title: 'Job Listing',
            field: 'title',
            template: (col) => (
              <JobListingItem
                key={col.id}
                description={col.shortDescription}
                image={col.image}
                title={col.title}
                eguid={col.eguid}
                isActive={false}
              />
            ),
          },
          {
            title: 'Location',
            field: 'location',
          },
          {
            title: 'Expires',
            field: 'dated',
            template: (col) => (col.dated ? moment(col.dated).format('MM/DD/YYYY') : '-'),
          },
          {
            title: 'Total Applicants',
            field: 'applicantCount',
            align: 'center',
          },
          {
            title: 'empty',
            field: 'lastCol',
            template: (col) => (
              <div className={classes.actions}>
                <Btn
                  type="button"
                  click={() => onViewApplicants(col.eguid)}
                >
                  Applicants
                </Btn>
                <Btn
                  type="button"
                  click={() => onReactivateJobListingHandler(col.siteId, col.eguid)}
                >
                  Reactivate
                </Btn>
                <Btn
                  type="button"
                  click={() => onEditAndCopyJobListingHandler(col.siteId, col.eguid)}
                >
                  Copy and Create New Listing
                </Btn>
              </div>
            ),
          },
        ]}
      />
    </CardWrapper>
  );
};

export default memo(JobsListing);
