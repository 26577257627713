import { createApi } from '@reduxjs/toolkit/dist/query/react';
import endpoints from '../../api/endpoints';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import { IRequest, IPressReleasesResponse } from '../../interfaces';
import {
  IGetUpgradePriceRequest,
  IGetUpgradePriceResponse,
  IPaymentIntentRequest,
  IPaymentIntentResponse,
  IPressReleaseRequest,
  IPressReleaseResponse,
  IUpgradeSuccessRequest,
  IUpgradeSuccessResponse
} from './interfaces';

export const pressReleasesApi = createApi({
  reducerPath: 'pressReleases',
  tagTypes: ['pressReleases'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    fetchPressReleases: build.query<
    IPressReleasesResponse,
    IRequest
    >({
      query: ({ pageSize, page, SortBy, SortDirection, searchText }) => ({
        url: endpoints.pressReleases.accountPressReleases(),
        params: {
          pageSize,
          page,
          SortBy,
          SortDirection,
          searchText,
        },
      }),
    }),
    getPressRelease: build.query<
    IPressReleaseResponse,
    IPressReleaseRequest
    >({
      query: ({ guid }) => ({
        url: endpoints.pressReleases.getPressRelease(guid)
      }),
    }),
    getUpgradePrice: build.query<
    IGetUpgradePriceResponse,
    IGetUpgradePriceRequest
    >({
      query: ({ listingTypeId, companyTypeId }) => ({
        url: endpoints.pressReleases.getUpgradePrice(listingTypeId, companyTypeId)
      }),
    }),
    paymentIntent: build.query<
    IPaymentIntentResponse,
    IPaymentIntentRequest
    >({
      query: ({ listingTypeId, companyTypeId, upgrade }) => ({
        url: endpoints.pressReleases.upgradePaymentIntent(listingTypeId, companyTypeId),
        params: {
          upgrade
        },
      }),
    }),
    upgradeSuccess: build.mutation<IUpgradeSuccessResponse, IUpgradeSuccessRequest>({
      query: (body) => ({
        url: endpoints.pressReleases.upgradeFromAccount,
        method: 'POST',
        body
      }),
    }),
  }),
});

export const {
  useLazyFetchPressReleasesQuery,
  usePaymentIntentQuery,
  useGetPressReleaseQuery,
  useUpgradeSuccessMutation,
  useGetUpgradePriceQuery
} = pressReleasesApi;
