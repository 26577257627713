import React, { memo } from 'react';
import { Avatar } from '@material-ui/core';
import { publicProfileApi } from '../../../services/userProfile/public';
import { useGetPersonalInfoQuery } from '../../../services/userProfile/personal';
import { useStyles } from '../styles';

// interface UserAvatarProps {}
const UserAvatar = () => {
  const classes = useStyles();
  const { data: userTitle } = useGetPersonalInfoQuery('');
  const firstName: string | null = (userTitle && userTitle.firstName) || null;
  const { data: userData } = publicProfileApi.useGetQuery('');
  const photo: string | null = (userData && userData.headshot) || null;

  const forSrc = photo ? { src: photo } : {};
  return (
    <div className={classes.wrapper_for_avatar}>
      <Avatar
        {...forSrc}
        className={classes.avatar}
      >
        {!photo && (firstName ? firstName.charAt(0).toUpperCase() : '-')}
      </Avatar>
    </div>
  );
};

export default memo(UserAvatar);
