import React, { ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

interface IBasicLayoutPageTitleProps {
  helmet?: string | ReactNode
}
export const BasicLayoutPageTitle = ({ helmet }:IBasicLayoutPageTitleProps) => {
  if (helmet) {
    if (typeof helmet === 'string') {
      return (
         <HelmetProvider>
            {helmet && (
            <Helmet>
               <title>
                  {helmet}
                  {' '}
                  | BevNET.com
               </title>
            </Helmet>
            )}
         </HelmetProvider>
      );
    }
    return (
       <HelmetProvider>
          {helmet && (
          <Helmet>
             {helmet}
          </Helmet>
          )}
       </HelmetProvider>
    );
  }
  return null;
};
