import React, { ChangeEvent, useEffect, useState } from 'react';

import { insertIcon } from './insertIcon';
import { IFormFieldProps } from './formFieldInterface';
import { FieldWrapper } from './components/FieldWrapper';
import { TextFieldVariant } from './components/TextFieldVariant';
import { MaskTextFieldVariant } from './components/MaskTextFieldVariant';

export const FormField = ({
  fieldMask,
  isRequired,
  extraProps,
  refProps = {},
  type = 'text',
  originalName,
  label,
  maxLength,
  autoComplete = true,
  errors = false,
  visible = true,
  startAdornment,
  endAdornment,
  fieldComment,
  placeholder,
  classNameForField,
  classNameForWrapper,
  isDisabled,
  inputLabelProps,

  textAreaRow,

  eventFocus,
  eventBlur,
  eventChange,
  eventInput,
  extraErrorMessage,

  name,
  position = 'relative',
  customInputProps,
  valueField,
  children,
}: IFormFieldProps) => {
  const [messageFromExtraError, setMessageFromExtraError] = useState<string | null>(null);
  const [lengthField, setLengthField] = useState<number>(0);

  // -------------------  events
  const eventChangeField = (el: ChangeEvent<HTMLInputElement>) => {
    if (eventChange) {
      eventChange(el.target.value, el);
    }
  };
  const eventBlurField = (val: string) => {
    if (eventBlur) eventBlur(val);
  };
  const eventInputField = (el: ChangeEvent<HTMLInputElement>) => {
    if (maxLength) {
      setLengthField(el.target.value.length);
    }
    if (eventInput) {
      eventInput(el.target.value, el);
    }
  };
  const eventFocusField = (el: React.FormEvent<HTMLInputElement>) => {
    if (eventFocus) {
      eventFocus({ value: el.currentTarget.value });
    }
  };

  useEffect(() => {
    if (startAdornment) {
      insertIcon(startAdornment);
    }
  }, [startAdornment]);

  useEffect(() => {
    const onKey: string[] = Object.keys(errors);
    if (onKey.length && originalName && errors[originalName]) {
      setMessageFromExtraError(errors[originalName].message);
      return;
    }
    setMessageFromExtraError(null);
  }, [errors, extraProps, originalName]);

  useEffect(() => {
    if (extraErrorMessage) {
      setMessageFromExtraError(extraErrorMessage);
      return;
    }
    setMessageFromExtraError('');
  }, [extraErrorMessage]);

  if (visible) {
    return (
       <FieldWrapper
          type={type}
          label={label}
          visible={visible}
          position={position}
          refProps={refProps}
          fieldComment={fieldComment}
          bottomChildren={!fieldMask && children}
          classNameForWrapper={classNameForWrapper}
       >
          {!fieldMask ? (
          // component "FormField" with wrapper "Controller" and alone
             <TextFieldVariant
                name={name}
                type={type}
                label={label}
                errors={errors}
                maxLength={maxLength}
                isDisabled={isDisabled}
                valueField={valueField}
                isRequired={isRequired}
                extraProps={extraProps}
                textAreaRow={textAreaRow}
                lengthField={lengthField}
                placeholder={placeholder}
                endAdornment={endAdornment}
                autoComplete={autoComplete}
                startAdornment={startAdornment}
                eventBlurField={eventBlurField}
                eventFocusField={eventFocusField}
                eventInputField={eventInputField}
                eventChangeField={eventChangeField}
                customInputProps={customInputProps}
                inputLabelProps={inputLabelProps}
                extraErrorMessage={extraErrorMessage}
                classNameForField={classNameForField}
                messageFromExtraError={messageFromExtraError}
             />
          ) : (
          // "React-input-mask" component with wrapper "Controller" and alone
             <MaskTextFieldVariant
                name={name}
                type={type}
                label={label}
                errors={errors}
                maxLength={maxLength}
                isDisabled={isDisabled}
                isRequired={isRequired}
                extraProps={extraProps}
                valueField={valueField}
                lengthField={lengthField}
                placeholder={placeholder}
                textAreaRow={textAreaRow}
                endAdornment={endAdornment}
                autoComplete={autoComplete}
                fieldMask={fieldMask as string}
                startAdornment={startAdornment}
                eventBlurField={eventBlurField}
                eventFocusField={eventFocusField}
                eventInputField={eventInputField}
                eventChangeField={eventChangeField}
                customInputProps={customInputProps}
                classNameForField={classNameForField}
                messageFromExtraError={messageFromExtraError}
             />
          )}
       </FieldWrapper>
    );
  }
  return null;
};
