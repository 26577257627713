import React, { ReactChild, ReactChildren, ReactNode } from 'react';
import clsx from 'clsx';

import { IChildrenProps } from '../../../interfaces';
import { IFieldStylePosition, IFieldTypes } from '../formFieldInterface';
import { FieldComment } from '../../FormFieldUtils';

import { useStyles } from '../styles';

interface IFieldWrapperProps extends IChildrenProps, IFieldTypes, IFieldStylePosition {
  visible?: boolean;
  label?: string;
  refProps?: any;
  classNameForWrapper?: string,
  fieldComment?: string | null;
  bottomChildren?: ReactNode | ReactChildren | ReactChild
}
export const FieldWrapper = ({ children, type, position, refProps, visible, classNameForWrapper, label, bottomChildren, fieldComment }:IFieldWrapperProps) => {
  const classes = useStyles();
  if (!visible) {
    return null;
  }
  return (
     <div
        {...refProps}
        className={clsx(classes.field_wrapper, classNameForWrapper, {
          [classes.hidden]: type === 'hidden',
          [classes.relative]: position && position === 'relative',
          [classes.absolute]: position && position === 'absolute',
          [classes.label_exist]: !!label,
        })}
     >
        {children}
        <FieldComment fieldComment={fieldComment} />
        {bottomChildren}
     </div>
  );
};
