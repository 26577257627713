import React from 'react';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import ErrorBoundary from '../../../containers/ErrorBoundary';

import { useStyles } from '../styles';
import { IModalWindowProps } from '../modalWindowInterface';

interface IModalWindowBodyProps extends IModalWindowProps {}
const ModalWindowBody = ({ closeModal, errorMode, title, text, children, styleProps }:IModalWindowBodyProps) => {
  const classes = useStyles();
  return (
     <div
        className={clsx(classes.modal, {
          [classes.error]: errorMode
        })}
        style={{
          ...(styleProps?.modal) && { borderRadius: styleProps.modal.borderRadius },
          ...(styleProps?.modal) && { background: styleProps.modal.background },
          ...(styleProps?.modal) && { maxHeight: styleProps.modal.maxHeight },
          ...(styleProps?.modal) && { maxWidth: `${styleProps.modal.maxWidth}rem` }
        }}
     >
        <CloseIcon
           className={classes.close}
           onClick={closeModal}
           role="presentation"
        />

        <ErrorBoundary>
           {title && (
           <Typography
              variant="h5"
              marginBottom={2.5}
              style={{
                ...(styleProps?.title) && { ...styleProps.title }
              }}
           >
              {title}
           </Typography>
           )}
           {text && <p className={classes.message}>{text}</p>}
           {children && children}
        </ErrorBoundary>
     </div>
  );
};

export default ModalWindowBody;
