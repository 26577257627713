import React, { memo, useEffect } from 'react';

import { Btn, CardWrapper, FormField, useHookForm } from '@monorepo/common';

import { useDispatch } from 'react-redux';
import { useSendHelpFormMutation } from '../../services/helpForm';
import { schema } from './schema';
import { IHelpFormData } from '../../services/helpForm/interfacesForHelpForm';
import { showNotification } from '../../features/sliceNotification';
import { customButtonStyles } from '../../constants/customButtonStyles';

const Help = () => {
  const dispatch = useDispatch();
  const [sendHelpRequest, { isLoading, isSuccess }] = useSendHelpFormMutation();

  const {
    errors,
    register,
    handleSubmit
  } = useHookForm<IHelpFormData>({
    schema
  });

  const onSubmit = (dataForm:IHelpFormData) => {
    sendHelpRequest({
      body: dataForm
    });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(showNotification({
        show: true,
        type: 'success',
        text: 'Your request was submitted.'
      }));
    }
  }, [isSuccess]);

  return (
    <CardWrapper
      isLoading={isLoading}
      titleHelmet="Contact Form"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          extraProps={{ ...register('name') }}
          errors={errors}
          label="Full Name*"
        />
        <FormField
          extraProps={{ ...register('email') }}
          errors={errors}
          label="Email*"
        />
        <FormField
          extraProps={{ ...register('company') }}
          errors={errors}
          label="Company*"
        />
        <FormField
          extraProps={{ ...register('phoneNumber') }}
          errors={errors}
          label="Phone Number*"
        />
        <FormField
          extraProps={{ ...register('descriptionRequest') }}
          errors={errors}
          label="Description of Request*"
        />
        <Btn
          type="submit"
          customStyles={customButtonStyles}
        >
          Submit request
        </Btn>
      </form>
    </CardWrapper>
  );
};

export default memo(Help);
