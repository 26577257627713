import { useState, useCallback } from 'react';
import { Card, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Loader, useHookForm, FormField } from '@monorepo/common';
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
  LinkAuthenticationElement,
} from '@stripe/react-stripe-js';
import { showNotification } from '../../../features/sliceNotification';
import { useStyles } from './styles';
import { schema } from './schema';

interface IPaymentFormProps {
  pressReleaseGuid: string;
}

interface IPaymnetForm {
  billingCompany: string;
}

const PaymentForm = ({ pressReleaseGuid } : IPaymentFormProps) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    errors,
    register
  } = useHookForm<IPaymnetForm>({ schema });

  const onSubmit = useCallback(async (data : IPaymnetForm) => {
    setIsLoading(true);
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://account.bevnet.com/user/press-releases/upgrade/${pressReleaseGuid}/success?company=${data.billingCompany}`,
      }
    });

    if (result.error) {
      dispatch(showNotification({
        show: true,
        type: 'error',
        text: result.error.message
      }));
    }
    setIsLoading(false);
  }, [stripe, elements, dispatch]);

  return (
    <Card className={classes.wrapper_card}>
      <Loader isShow={isLoading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <LinkAuthenticationElement />
        <FormField
          errors={errors}
          inputLabelProps={{ shrink: true }}
          placeholder="Billing company"
          label="Billing company"
          extraProps={{ ...register('billingCompany') }}
          classNameForWrapper={classes.form_input}
        />
        <AddressElement options={{ mode: 'billing', fields: { phone: 'always' } }} />
        <br />
        <PaymentElement />
        <Button
          className={classes.submit_btn}
          variant="contained"
          disabled={!stripe}
          type="submit"
        >
          Submit
        </Button>
      </form>
    </Card>
  );
};

export default PaymentForm;
