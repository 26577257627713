import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InterfaceUI {
  isMobileMenu: boolean,
  showLeftSide?: boolean
}

const initialState: InterfaceUI = {
  isMobileMenu: false,
  showLeftSide: true
};

export const slice = createSlice({
  name: 'ui/mobileMenu',
  initialState,
  reducers: {
    toggleLeftSide: (state: InterfaceUI, action: PayloadAction<boolean>): void => {
      state.showLeftSide = action.payload;
    },
    toggleMobileMenu: (state: InterfaceUI, action: PayloadAction<boolean>): void => {
      state.isMobileMenu = action.payload;
    },
  },
});

export const {
  toggleMobileMenu,
  toggleLeftSide
} = slice.actions;
export default slice.reducer;
